import { createContext } from 'react'
import {
  PSSFormConfigSetup,
  PSSFormPreviewsSetup,
  PSSFormCallbacks
} from '../types/form-configuration'

export const RootContext = createContext<
  (PSSFormConfigSetup | { configSetup: { config: {} } }) &
    PSSFormPreviewsSetup &
    PSSFormCallbacks
>({
  configSetup: { config: {} },
  previewsSetup: {},
  callbacks: {}
})
