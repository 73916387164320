import { FormGroup } from '.'
import { Attributes } from '../types'

export class FormRowsHandler {
  constructor() {
    this.array = this.array.bind(this)
    this.clean = this.clean.bind(this)
    this.filterRowValues = this.filterRowValues.bind(this)
    this.addMissingRows = this.addMissingRows.bind(this)
  }

  filterRowValues = ({
    groups,
    fields,
    ...args
  }: {
    groups: FormGroup[]
    fields: Attributes
    [key: string]: unknown
  }) => {
    const groupsMapped = groups.map((group: FormGroup) => ({
      ...group,
      rows: group.rows.map((row: string[]) =>
        row.filter((rowElement: string) => fields[rowElement] !== undefined)
      )
    }))
    return {
      groups: groupsMapped,
      fields,
      ...args
    }
  }

  array = ({
    groups,
    fields,
    ...args
  }: {
    groups: FormGroup[]
    fields: Attributes
    [key: string]: unknown
  }) => {
    const groupsMapped = groups.map((group) => ({
      ...group,
      rows: group.rows.map((row) => (Array.isArray(row) ? row : [row]))
    }))
    return {
      groups: groupsMapped,
      fields,
      ...args
    }
  }

  clean = ({
    groups,
    ...args
  }: {
    groups: FormGroup[]
    [key: string]: unknown
  }) => {
    const groupsMapped = groups.map((group) => ({
      ...group,
      rows: group.rows.filter((row) => row.length > 0)
    }))
    return {
      groups: groupsMapped,
      ...args
    }
  }

  addMissingRows = ({
    groups,
    fields,
    ...args
  }: {
    groups: FormGroup[]
    fields: Attributes
    [key: string]: unknown
  }) => {
    const allUsedValues: string[] = groups.reduce(
      (allValues: string[], group: FormGroup) => [
        ...allValues,
        ...group.rows.reduce(
          (mappedRows: string[], rows: string[]) => [...mappedRows, ...rows],
          []
        )
      ],
      []
    )
    const groupsMapped = groups.map((group) => {
      const missingRows = Object.keys(fields).filter(
        (fieldKey: string) =>
          fields[fieldKey].group === group.name &&
          !allUsedValues.includes(fieldKey)
      )
      return {
        ...group,
        rows: [...group.rows, ...missingRows]
      }
    })
    return {
      groups: groupsMapped,
      fields,
      ...args
    }
  }
}
