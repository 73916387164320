import React, { useCallback } from 'react'
import cxBinder from 'classnames/bind'
import { ButtonMode, Button } from '../../../atoms'
import styles from '../ColumnsPopup.module.scss'

const cx = cxBinder.bind(styles)

export const ColumnsPopupFooter = ({
  onSave,
  onCancel,
  isLoading,
  setIsLoading,
  labels: { cancel = 'Cancel', apply = 'Apply' }
}) => {
  const onClick = useCallback(() => {
    setIsLoading(true)
    setTimeout(onSave)
  }, [onSave, setIsLoading])

  return (
    <div className={cx('footer')}>
      <Button
        mode={ButtonMode.secondary}
        onClick={onCancel}
        e2e-target="button"
        e2e-target-name="cancel"
      >
        {cancel}
      </Button>
      <Button
        onClick={onClick}
        isLoading={isLoading}
        e2e-target="button"
        e2e-target-name="apply"
      >
        {apply}
      </Button>
    </div>
  )
}
