import React, { useState, useCallback } from 'react'
import cxBinder from 'classnames/bind'

import { Popup, PopupSizeProp, usePopup } from '../../atoms/'

import styles from './ColumnsPopup.module.scss'

import { ColumnsPopupFooter } from './components/ColumnsPopupFooter'
import { ColumnsPopupHeader } from './components/ColumnsPopupHeader'
import { ColumnsPopupProps } from './common'
import { SortableList } from './components/SortableList'
import { usePopupHandlers } from './helpers/popup-handlers'

const cx = cxBinder.bind(styles)

const ColumnsPopup = (props: ColumnsPopupProps) => {
  const {
    trigger,
    config,
    onChange,
    startOpened,
    width = '232px',
    labels = {},
    pathname,
    e2eTarget = 'columns-filtering-group',
    e2eTargetName
  } = props

  const {
    closePopup,
    toggleSelect,
    onSave,
    onSortEnd,
    anchorRef,
    anchorEl,
    isLoading,
    setIsLoading,
    sortableList,
    updateConfig
  } = usePopupHandlers({
    startOpened,
    onChange,
    config,
    pathname
  })

  return (
    <div e2e-target={e2eTarget} e2e-target-name={e2eTargetName}>
      <span
        ref={anchorRef}
        onClick={toggleSelect}
        tabIndex={0}
        e2e-target="button"
        e2e-target-name="toggle-columns-filtering-popup"
      >
        {trigger}
      </span>

      <Popup
        anchorEl={anchorEl}
        placement="bottom-end"
        width={width}
        popupSize={PopupSizeProp.l}
        onClickOutside={closePopup}
        e2e-target-name="filtering-popup"
        header={
          <ColumnsPopupHeader
            config={config}
            updateConfig={updateConfig}
            labels={labels}
          />
        }
        footer={
          <ColumnsPopupFooter
            onSave={onSave}
            onCancel={closePopup}
            labels={labels}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        }
      >
        <div className={cx('list-wrapper')}>
          <SortableList
            config={config}
            list={sortableList}
            onChange={updateConfig}
            onSortEnd={onSortEnd}
            useDragHandle={true}
            helperClass={cx('dragging-item')}
          />
        </div>
      </Popup>
    </div>
  )
}

export { ColumnsPopup as default, ColumnsPopup }
