import React from 'react'
import dot from 'dot-object'
import { computed, observable } from 'mobx'
import { TableStore } from '../table-store'
import { SingleColumnStore } from '../columns/single-column-store'
import { SingleRowStore } from './single-row-store'
import { SingleRowData } from './row-definitions'

export class RowCellStore {
  @observable private _rowData

  constructor(
    private _rootStore: TableStore,
    private _row: SingleRowStore,
    private _columnConfig: SingleColumnStore,
    _rowData: SingleRowData = {}
  ) {
    this._rowData = _rowData
  }

  @computed get rawValue() {
    const value =
      this._rowData &&
      this._columnConfig.uuid &&
      dot.pick(this._columnConfig.uuid, this._rowData)
    return value ? value : ''
  }

  @computed get shouldUseCellWrapper() {
    return Boolean(
      this._columnConfig.hideWithTooltip === true || this._columnConfig.maxWidth
    )
  }

  @computed get headline() {
    return this._columnConfig.label
  }

  @computed.struct get value() {
    if (this._rootStore.isLoading) return null

    const value = this.rawValue

    const renderValue = this._columnConfig.cellRenderer
      ? this._columnConfig.cellRenderer(value, this._rowData)
      : value

    return renderValue
  }

  @computed get isSorted() {
    return Boolean(
      !this._rootStore.isLoading &&
        this._columnConfig.isSortable &&
        this._columnConfig.uuid === this._rootStore.sort.sortField
    )
  }

  @computed get isDimmed() {
    return Boolean(!this.isSorted && this._row.isDimmed)
  }

  @computed get isSelected() {
    return this._row.isSelected
  }

  @computed get showError() {
    return this._row.showError
  }
}
