import {
  Input,
  Switch,
  Textarea,
  ToggleSelect
} from '@packhelp/platform-dsl/atoms'
import {
  Select,
  NumberInput,
  MultiToggleSelect
} from '@packhelp/platform-dsl/molecules'
import { FunctionComponent } from 'react'
import { ProductSpecUploader } from '../components/prod-spec-uploader/ProductSpecUploader'

export const widgets: { [key: string]: FunctionComponent } = {
  classicSelect: Select as FunctionComponent,
  textarea: Textarea as FunctionComponent,
  select: ToggleSelect as FunctionComponent,
  number: NumberInput as FunctionComponent,
  text: Input as FunctionComponent,
  boolean: Switch as FunctionComponent,
  multiselect: MultiToggleSelect as FunctionComponent,
  file: ProductSpecUploader as FunctionComponent
}
