import React, { useMemo } from 'react'
import cxBinder from 'classnames/bind'
import { Select, SelectSize } from '../Select'
import styles from './ItemsOnPage.module.scss'

const cx = cxBinder.bind(styles)

export enum PerPageQuantity {
  Q30 = 30,
  Q60 = 60,
  Q120 = 120,
  Q180 = 180,
  Q240 = 240,
  Q300 = 300
}

export interface ItemOnPageProps {
  quantity?: PerPageQuantity
  caption?: null | string | JSX.Element
  onSelect?: (value: number) => void
  e2eTarget?: string
  e2eTargetName?: string
}

const ItemsOnPage = ({
  quantity = PerPageQuantity.Q30,
  caption = null,
  onSelect,
  e2eTarget = 'items-on-page',
  e2eTargetName
}: ItemOnPageProps) => {
  const ItemsOnPageOptionsRendered = useMemo(
    () =>
      Object.keys(PerPageQuantity)
        .filter((key) => typeof PerPageQuantity[key] === 'number')
        .map((key) => ({
          value: PerPageQuantity[key],
          label: <div className={cx('label')}>{PerPageQuantity[key]}</div>
        })),
    []
  )
  return (
    <div
      className={cx('items-on-page')}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {caption && (
        <span className={cx('caption')} e2e-target="label">
          {caption}
        </span>
      )}
      <div className={cx('select')}>
        <Select
          name={'items-on-page'}
          onSelect={onSelect}
          size={SelectSize.minimal}
          value={quantity}
          data={ItemsOnPageOptionsRendered}
          autoWidth={true}
          itemRenderer={(label) => (
            <span className={cx('selected-quantity')}>{label}</span>
          )}
          e2e-target="select"
          e2e-target-name="items-on-page"
        />
      </div>
    </div>
  )
}

export { ItemsOnPage, ItemsOnPage as default }
