import React, { useState, ChangeEvent, useRef, useEffect } from 'react'
import cxBinder from 'classnames/bind'

import { Input, InputProps, Icon, IconSize, TIcon } from '../../atoms'
import { simulateInputChange } from '../../atoms/Input/helpers'
import styles from './NumberInput.module.scss'

const cx = cxBinder.bind(styles)

export interface NumberInputProps extends InputProps {
  step?: number
  value?: number
}

const NumberInput = (props: NumberInputProps) => {
  const { min, max, step = 1, onChange, value, disabled, size, ...rest } = props
  const [numberValue, setNumberValue] = useState<number | undefined>(value)
  const numberInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setNumberValue(value)
    return () => {
      setNumberValue(undefined)
    }
  }, [value])

  const afterSeparatorLength = (value: number) =>
    value.toString().split('.')[1]?.length || 0

  const calculate = (value: number, operator: 'sum' | 'dif') => {
    const round = Math.max(
      afterSeparatorLength(value),
      afterSeparatorLength(step)
    )

    const operationResult = operator === 'sum' ? value + step : value - step

    return parseFloat(operationResult.toFixed(round))
  }

  const increment = () => {
    const value = !numberValue ? step : calculate(numberValue, 'sum')
    setNumberValue(value)
    numberInputRef.current && simulateInputChange(numberInputRef.current, value)
  }

  const decrement = () => {
    const value = !numberValue ? -step : calculate(numberValue, 'dif')
    setNumberValue(value)
    numberInputRef.current && simulateInputChange(numberInputRef.current, value)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber

    if (isNaN(value)) {
      setNumberValue(undefined)
    } else {
      setNumberValue(value)
    }

    onChange && onChange(e)
  }

  return (
    <div
      className={cx('wrapper', `wrapper--${size}`, {
        'wrapper--disabled': disabled
      })}
    >
      <button
        type="button"
        className={styles.wrapper__minus}
        tabIndex={-1}
        onClick={decrement}
        disabled={disabled}
      >
        <Icon icon={TIcon.Minus} size={IconSize.xs} />
      </button>
      <Input
        {...props}
        {...rest}
        type="number"
        innerRef={numberInputRef}
        onChange={handleChange}
        value={numberValue}
        step={step}
        size={size}
      />
      <button
        type="button"
        className={styles.wrapper__plus}
        tabIndex={-1}
        onClick={increment}
        disabled={disabled}
      >
        <Icon icon={TIcon.Plus} size={IconSize.xs} />
      </button>
    </div>
  )
}

export { NumberInput, NumberInput as default }
