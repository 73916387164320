import { KeyboardEvent, MouseEvent, useEffect } from 'react'
import { ValueProps } from '../../types'
import { ActiveListItemRef } from '../refs/refs'

interface AccessibillityProps {
  activeListItemRef: ActiveListItemRef
  popupOpened: HTMLElement | boolean
  closePopup: () => void
  handleValueChange: (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => void
}

export const useAccessibillity = ({
  popupOpened,
  closePopup,
  handleValueChange,
  activeListItemRef
}: AccessibillityProps) => {
  const disableArrows = (e) => {
    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    popupOpened
      ? window.addEventListener('keydown', disableArrows)
      : window.removeEventListener('keydown', disableArrows)

    return () => {
      window.removeEventListener('keydown', disableArrows)
    }
  }, [popupOpened])

  const handleKeyDown = (
    event: KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      handleValueChange(event, value)
      event.preventDefault()
      event.stopPropagation()
      closePopup()
    }

    let active: HTMLDivElement | undefined | null = event.currentTarget
    if (active instanceof HTMLInputElement) {
      active = null
    }
    if (event.key === 'ArrowDown') {
      if (active && active.nextSibling) {
        ;(active.nextSibling as HTMLDivElement).focus()
      } else {
        return activeListItemRef.current?.focus()
      }
    } else if (event.key === 'ArrowUp' && active && active.previousSibling) {
      return (active.previousSibling as HTMLDivElement).focus()
    }
  }

  return {
    handleKeyDown
  }
}
