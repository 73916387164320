
// ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
//        AUTOGENERATED FILE
// ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗


import React from 'react'

export const BoxIcon = React.lazy(() => import('./BoxIcon'))
export const ImgPlaceholderIcon = React.lazy(() => import('./ImgPlaceholderIcon'))
export const ActionsIcon = React.lazy(() => import('./ActionsIcon'))
export const AddColumnsIcon = React.lazy(() => import('./AddColumnsIcon'))
export const AddUserIcon = React.lazy(() => import('./AddUserIcon'))
export const ArrowsType1DownIcon = React.lazy(() => import('./ArrowsType1DownIcon'))
export const ArrowsType1LeftIcon = React.lazy(() => import('./ArrowsType1LeftIcon'))
export const ArrowsType1RightIcon = React.lazy(() => import('./ArrowsType1RightIcon'))
export const ArrowsType1UpIcon = React.lazy(() => import('./ArrowsType1UpIcon'))
export const ArrowsType2CollapseIcon = React.lazy(() => import('./ArrowsType2CollapseIcon'))
export const ArrowsType2ExpandIcon = React.lazy(() => import('./ArrowsType2ExpandIcon'))
export const ArrowsType2LeftIcon = React.lazy(() => import('./ArrowsType2LeftIcon'))
export const ArrowsType2RightIcon = React.lazy(() => import('./ArrowsType2RightIcon'))
export const ArrowsType3DownIcon = React.lazy(() => import('./ArrowsType3DownIcon'))
export const ArrowsType3LeftIcon = React.lazy(() => import('./ArrowsType3LeftIcon'))
export const ArrowsType3RightIcon = React.lazy(() => import('./ArrowsType3RightIcon'))
export const ArrowsType3UpIcon = React.lazy(() => import('./ArrowsType3UpIcon'))
export const ArrowsType4DownIcon = React.lazy(() => import('./ArrowsType4DownIcon'))
export const ArrowsType4LeftIcon = React.lazy(() => import('./ArrowsType4LeftIcon'))
export const ArrowsType4RightIcon = React.lazy(() => import('./ArrowsType4RightIcon'))
export const ArrowsType4UpIcon = React.lazy(() => import('./ArrowsType4UpIcon'))
export const AttachIcon = React.lazy(() => import('./AttachIcon'))
export const BinIcon = React.lazy(() => import('./BinIcon'))
export const BlocksIcon = React.lazy(() => import('./BlocksIcon'))
export const CalendarIcon = React.lazy(() => import('./CalendarIcon'))
export const CheckIcon = React.lazy(() => import('./CheckIcon'))
export const ClockIcon = React.lazy(() => import('./ClockIcon'))
export const CloseBigIcon = React.lazy(() => import('./CloseBigIcon'))
export const CloseIcon = React.lazy(() => import('./CloseIcon'))
export const CollapsIcon = React.lazy(() => import('./CollapsIcon'))
export const ColumnsIcon = React.lazy(() => import('./ColumnsIcon'))
export const CommentIcon = React.lazy(() => import('./CommentIcon'))
export const DisableIcon = React.lazy(() => import('./DisableIcon'))
export const DockListIcon = React.lazy(() => import('./DockListIcon'))
export const DockSettingsIcon = React.lazy(() => import('./DockSettingsIcon'))
export const DockShareIcon = React.lazy(() => import('./DockShareIcon'))
export const DockIcon = React.lazy(() => import('./DockIcon'))
export const DollarIcon = React.lazy(() => import('./DollarIcon'))
export const DownloadIcon = React.lazy(() => import('./DownloadIcon'))
export const DragIcon = React.lazy(() => import('./DragIcon'))
export const DuplicateIcon = React.lazy(() => import('./DuplicateIcon'))
export const EditIcon = React.lazy(() => import('./EditIcon'))
export const EmailNotSentIcon = React.lazy(() => import('./EmailNotSentIcon'))
export const EmailSentIcon = React.lazy(() => import('./EmailSentIcon'))
export const ErrorIcon = React.lazy(() => import('./ErrorIcon'))
export const ExpandIcon = React.lazy(() => import('./ExpandIcon'))
export const EyeHideIcon = React.lazy(() => import('./EyeHideIcon'))
export const EyeShowIcon = React.lazy(() => import('./EyeShowIcon'))
export const FilterIcon = React.lazy(() => import('./FilterIcon'))
export const FlagIcon = React.lazy(() => import('./FlagIcon'))
export const FlagsAdIcon = React.lazy(() => import('./FlagsAdIcon'))
export const FlagsAeIcon = React.lazy(() => import('./FlagsAeIcon'))
export const FlagsAfIcon = React.lazy(() => import('./FlagsAfIcon'))
export const FlagsAgIcon = React.lazy(() => import('./FlagsAgIcon'))
export const FlagsAiIcon = React.lazy(() => import('./FlagsAiIcon'))
export const FlagsAlIcon = React.lazy(() => import('./FlagsAlIcon'))
export const FlagsAmIcon = React.lazy(() => import('./FlagsAmIcon'))
export const FlagsAoIcon = React.lazy(() => import('./FlagsAoIcon'))
export const FlagsAqIcon = React.lazy(() => import('./FlagsAqIcon'))
export const FlagsArIcon = React.lazy(() => import('./FlagsArIcon'))
export const FlagsAsIcon = React.lazy(() => import('./FlagsAsIcon'))
export const FlagsAtIcon = React.lazy(() => import('./FlagsAtIcon'))
export const FlagsAuIcon = React.lazy(() => import('./FlagsAuIcon'))
export const FlagsAwIcon = React.lazy(() => import('./FlagsAwIcon'))
export const FlagsAxIcon = React.lazy(() => import('./FlagsAxIcon'))
export const FlagsAzIcon = React.lazy(() => import('./FlagsAzIcon'))
export const FlagsBaIcon = React.lazy(() => import('./FlagsBaIcon'))
export const FlagsBbIcon = React.lazy(() => import('./FlagsBbIcon'))
export const FlagsBdIcon = React.lazy(() => import('./FlagsBdIcon'))
export const FlagsBeIcon = React.lazy(() => import('./FlagsBeIcon'))
export const FlagsBfIcon = React.lazy(() => import('./FlagsBfIcon'))
export const FlagsBgIcon = React.lazy(() => import('./FlagsBgIcon'))
export const FlagsBhIcon = React.lazy(() => import('./FlagsBhIcon'))
export const FlagsBiIcon = React.lazy(() => import('./FlagsBiIcon'))
export const FlagsBjIcon = React.lazy(() => import('./FlagsBjIcon'))
export const FlagsBlIcon = React.lazy(() => import('./FlagsBlIcon'))
export const FlagsBmIcon = React.lazy(() => import('./FlagsBmIcon'))
export const FlagsBnIcon = React.lazy(() => import('./FlagsBnIcon'))
export const FlagsBoIcon = React.lazy(() => import('./FlagsBoIcon'))
export const FlagsBqIcon = React.lazy(() => import('./FlagsBqIcon'))
export const FlagsBrIcon = React.lazy(() => import('./FlagsBrIcon'))
export const FlagsBsIcon = React.lazy(() => import('./FlagsBsIcon'))
export const FlagsBtIcon = React.lazy(() => import('./FlagsBtIcon'))
export const FlagsBvIcon = React.lazy(() => import('./FlagsBvIcon'))
export const FlagsBwIcon = React.lazy(() => import('./FlagsBwIcon'))
export const FlagsByIcon = React.lazy(() => import('./FlagsByIcon'))
export const FlagsBzIcon = React.lazy(() => import('./FlagsBzIcon'))
export const FlagsCaIcon = React.lazy(() => import('./FlagsCaIcon'))
export const FlagsCcIcon = React.lazy(() => import('./FlagsCcIcon'))
export const FlagsCdIcon = React.lazy(() => import('./FlagsCdIcon'))
export const FlagsCfIcon = React.lazy(() => import('./FlagsCfIcon'))
export const FlagsCgIcon = React.lazy(() => import('./FlagsCgIcon'))
export const FlagsChIcon = React.lazy(() => import('./FlagsChIcon'))
export const FlagsCiIcon = React.lazy(() => import('./FlagsCiIcon'))
export const FlagsCkIcon = React.lazy(() => import('./FlagsCkIcon'))
export const FlagsClIcon = React.lazy(() => import('./FlagsClIcon'))
export const FlagsCmIcon = React.lazy(() => import('./FlagsCmIcon'))
export const FlagsCnIcon = React.lazy(() => import('./FlagsCnIcon'))
export const FlagsCoIcon = React.lazy(() => import('./FlagsCoIcon'))
export const FlagsCrIcon = React.lazy(() => import('./FlagsCrIcon'))
export const FlagsCuIcon = React.lazy(() => import('./FlagsCuIcon'))
export const FlagsCvIcon = React.lazy(() => import('./FlagsCvIcon'))
export const FlagsCwIcon = React.lazy(() => import('./FlagsCwIcon'))
export const FlagsCxIcon = React.lazy(() => import('./FlagsCxIcon'))
export const FlagsCyIcon = React.lazy(() => import('./FlagsCyIcon'))
export const FlagsCzIcon = React.lazy(() => import('./FlagsCzIcon'))
export const FlagsDeIcon = React.lazy(() => import('./FlagsDeIcon'))
export const FlagsDjIcon = React.lazy(() => import('./FlagsDjIcon'))
export const FlagsDkIcon = React.lazy(() => import('./FlagsDkIcon'))
export const FlagsDmIcon = React.lazy(() => import('./FlagsDmIcon'))
export const FlagsDoIcon = React.lazy(() => import('./FlagsDoIcon'))
export const FlagsDzIcon = React.lazy(() => import('./FlagsDzIcon'))
export const FlagsEcIcon = React.lazy(() => import('./FlagsEcIcon'))
export const FlagsEeIcon = React.lazy(() => import('./FlagsEeIcon'))
export const FlagsEgIcon = React.lazy(() => import('./FlagsEgIcon'))
export const FlagsEhIcon = React.lazy(() => import('./FlagsEhIcon'))
export const FlagsErIcon = React.lazy(() => import('./FlagsErIcon'))
export const FlagsEsCaIcon = React.lazy(() => import('./FlagsEsCaIcon'))
export const FlagsEsIcon = React.lazy(() => import('./FlagsEsIcon'))
export const FlagsEtIcon = React.lazy(() => import('./FlagsEtIcon'))
export const FlagsEuIcon = React.lazy(() => import('./FlagsEuIcon'))
export const FlagsFiIcon = React.lazy(() => import('./FlagsFiIcon'))
export const FlagsFjIcon = React.lazy(() => import('./FlagsFjIcon'))
export const FlagsFkIcon = React.lazy(() => import('./FlagsFkIcon'))
export const FlagsFmIcon = React.lazy(() => import('./FlagsFmIcon'))
export const FlagsFoIcon = React.lazy(() => import('./FlagsFoIcon'))
export const FlagsFrIcon = React.lazy(() => import('./FlagsFrIcon'))
export const FlagsGaIcon = React.lazy(() => import('./FlagsGaIcon'))
export const FlagsGbEngIcon = React.lazy(() => import('./FlagsGbEngIcon'))
export const FlagsGbNirIcon = React.lazy(() => import('./FlagsGbNirIcon'))
export const FlagsGbSctIcon = React.lazy(() => import('./FlagsGbSctIcon'))
export const FlagsGbWlsIcon = React.lazy(() => import('./FlagsGbWlsIcon'))
export const FlagsGbIcon = React.lazy(() => import('./FlagsGbIcon'))
export const FlagsGdIcon = React.lazy(() => import('./FlagsGdIcon'))
export const FlagsGeIcon = React.lazy(() => import('./FlagsGeIcon'))
export const FlagsGfIcon = React.lazy(() => import('./FlagsGfIcon'))
export const FlagsGgIcon = React.lazy(() => import('./FlagsGgIcon'))
export const FlagsGhIcon = React.lazy(() => import('./FlagsGhIcon'))
export const FlagsGiIcon = React.lazy(() => import('./FlagsGiIcon'))
export const FlagsGlIcon = React.lazy(() => import('./FlagsGlIcon'))
export const FlagsGmIcon = React.lazy(() => import('./FlagsGmIcon'))
export const FlagsGnIcon = React.lazy(() => import('./FlagsGnIcon'))
export const FlagsGpIcon = React.lazy(() => import('./FlagsGpIcon'))
export const FlagsGqIcon = React.lazy(() => import('./FlagsGqIcon'))
export const FlagsGrIcon = React.lazy(() => import('./FlagsGrIcon'))
export const FlagsGsIcon = React.lazy(() => import('./FlagsGsIcon'))
export const FlagsGtIcon = React.lazy(() => import('./FlagsGtIcon'))
export const FlagsGuIcon = React.lazy(() => import('./FlagsGuIcon'))
export const FlagsGwIcon = React.lazy(() => import('./FlagsGwIcon'))
export const FlagsGyIcon = React.lazy(() => import('./FlagsGyIcon'))
export const FlagsHkIcon = React.lazy(() => import('./FlagsHkIcon'))
export const FlagsHmIcon = React.lazy(() => import('./FlagsHmIcon'))
export const FlagsHnIcon = React.lazy(() => import('./FlagsHnIcon'))
export const FlagsHrIcon = React.lazy(() => import('./FlagsHrIcon'))
export const FlagsHtIcon = React.lazy(() => import('./FlagsHtIcon'))
export const FlagsHuIcon = React.lazy(() => import('./FlagsHuIcon'))
export const FlagsIdIcon = React.lazy(() => import('./FlagsIdIcon'))
export const FlagsIeIcon = React.lazy(() => import('./FlagsIeIcon'))
export const FlagsIlIcon = React.lazy(() => import('./FlagsIlIcon'))
export const FlagsImIcon = React.lazy(() => import('./FlagsImIcon'))
export const FlagsInIcon = React.lazy(() => import('./FlagsInIcon'))
export const FlagsIoIcon = React.lazy(() => import('./FlagsIoIcon'))
export const FlagsIqIcon = React.lazy(() => import('./FlagsIqIcon'))
export const FlagsIrIcon = React.lazy(() => import('./FlagsIrIcon'))
export const FlagsIsIcon = React.lazy(() => import('./FlagsIsIcon'))
export const FlagsItIcon = React.lazy(() => import('./FlagsItIcon'))
export const FlagsJeIcon = React.lazy(() => import('./FlagsJeIcon'))
export const FlagsJmIcon = React.lazy(() => import('./FlagsJmIcon'))
export const FlagsJoIcon = React.lazy(() => import('./FlagsJoIcon'))
export const FlagsJpIcon = React.lazy(() => import('./FlagsJpIcon'))
export const FlagsKeIcon = React.lazy(() => import('./FlagsKeIcon'))
export const FlagsKgIcon = React.lazy(() => import('./FlagsKgIcon'))
export const FlagsKhIcon = React.lazy(() => import('./FlagsKhIcon'))
export const FlagsKiIcon = React.lazy(() => import('./FlagsKiIcon'))
export const FlagsKmIcon = React.lazy(() => import('./FlagsKmIcon'))
export const FlagsKnIcon = React.lazy(() => import('./FlagsKnIcon'))
export const FlagsKpIcon = React.lazy(() => import('./FlagsKpIcon'))
export const FlagsKrIcon = React.lazy(() => import('./FlagsKrIcon'))
export const FlagsKwIcon = React.lazy(() => import('./FlagsKwIcon'))
export const FlagsKyIcon = React.lazy(() => import('./FlagsKyIcon'))
export const FlagsKzIcon = React.lazy(() => import('./FlagsKzIcon'))
export const FlagsLaIcon = React.lazy(() => import('./FlagsLaIcon'))
export const FlagsLbIcon = React.lazy(() => import('./FlagsLbIcon'))
export const FlagsLcIcon = React.lazy(() => import('./FlagsLcIcon'))
export const FlagsLiIcon = React.lazy(() => import('./FlagsLiIcon'))
export const FlagsLkIcon = React.lazy(() => import('./FlagsLkIcon'))
export const FlagsLrIcon = React.lazy(() => import('./FlagsLrIcon'))
export const FlagsLsIcon = React.lazy(() => import('./FlagsLsIcon'))
export const FlagsLtIcon = React.lazy(() => import('./FlagsLtIcon'))
export const FlagsLuIcon = React.lazy(() => import('./FlagsLuIcon'))
export const FlagsLvIcon = React.lazy(() => import('./FlagsLvIcon'))
export const FlagsLyIcon = React.lazy(() => import('./FlagsLyIcon'))
export const FlagsMaIcon = React.lazy(() => import('./FlagsMaIcon'))
export const FlagsMcIcon = React.lazy(() => import('./FlagsMcIcon'))
export const FlagsMdIcon = React.lazy(() => import('./FlagsMdIcon'))
export const FlagsMeIcon = React.lazy(() => import('./FlagsMeIcon'))
export const FlagsMfIcon = React.lazy(() => import('./FlagsMfIcon'))
export const FlagsMgIcon = React.lazy(() => import('./FlagsMgIcon'))
export const FlagsMhIcon = React.lazy(() => import('./FlagsMhIcon'))
export const FlagsMkIcon = React.lazy(() => import('./FlagsMkIcon'))
export const FlagsMlIcon = React.lazy(() => import('./FlagsMlIcon'))
export const FlagsMmIcon = React.lazy(() => import('./FlagsMmIcon'))
export const FlagsMnIcon = React.lazy(() => import('./FlagsMnIcon'))
export const FlagsMoIcon = React.lazy(() => import('./FlagsMoIcon'))
export const FlagsMpIcon = React.lazy(() => import('./FlagsMpIcon'))
export const FlagsMqIcon = React.lazy(() => import('./FlagsMqIcon'))
export const FlagsMrIcon = React.lazy(() => import('./FlagsMrIcon'))
export const FlagsMsIcon = React.lazy(() => import('./FlagsMsIcon'))
export const FlagsMtIcon = React.lazy(() => import('./FlagsMtIcon'))
export const FlagsMuIcon = React.lazy(() => import('./FlagsMuIcon'))
export const FlagsMvIcon = React.lazy(() => import('./FlagsMvIcon'))
export const FlagsMwIcon = React.lazy(() => import('./FlagsMwIcon'))
export const FlagsMxIcon = React.lazy(() => import('./FlagsMxIcon'))
export const FlagsMyIcon = React.lazy(() => import('./FlagsMyIcon'))
export const FlagsMzIcon = React.lazy(() => import('./FlagsMzIcon'))
export const FlagsNaIcon = React.lazy(() => import('./FlagsNaIcon'))
export const FlagsNcIcon = React.lazy(() => import('./FlagsNcIcon'))
export const FlagsNeIcon = React.lazy(() => import('./FlagsNeIcon'))
export const FlagsNfIcon = React.lazy(() => import('./FlagsNfIcon'))
export const FlagsNgIcon = React.lazy(() => import('./FlagsNgIcon'))
export const FlagsNiIcon = React.lazy(() => import('./FlagsNiIcon'))
export const FlagsNlIcon = React.lazy(() => import('./FlagsNlIcon'))
export const FlagsNoIcon = React.lazy(() => import('./FlagsNoIcon'))
export const FlagsNpIcon = React.lazy(() => import('./FlagsNpIcon'))
export const FlagsNrIcon = React.lazy(() => import('./FlagsNrIcon'))
export const FlagsNuIcon = React.lazy(() => import('./FlagsNuIcon'))
export const FlagsNzIcon = React.lazy(() => import('./FlagsNzIcon'))
export const FlagsOmIcon = React.lazy(() => import('./FlagsOmIcon'))
export const FlagsPaIcon = React.lazy(() => import('./FlagsPaIcon'))
export const FlagsPeIcon = React.lazy(() => import('./FlagsPeIcon'))
export const FlagsPfIcon = React.lazy(() => import('./FlagsPfIcon'))
export const FlagsPgIcon = React.lazy(() => import('./FlagsPgIcon'))
export const FlagsPhIcon = React.lazy(() => import('./FlagsPhIcon'))
export const FlagsPkIcon = React.lazy(() => import('./FlagsPkIcon'))
export const FlagsPlIcon = React.lazy(() => import('./FlagsPlIcon'))
export const FlagsPmIcon = React.lazy(() => import('./FlagsPmIcon'))
export const FlagsPnIcon = React.lazy(() => import('./FlagsPnIcon'))
export const FlagsPrIcon = React.lazy(() => import('./FlagsPrIcon'))
export const FlagsPsIcon = React.lazy(() => import('./FlagsPsIcon'))
export const FlagsPtIcon = React.lazy(() => import('./FlagsPtIcon'))
export const FlagsPwIcon = React.lazy(() => import('./FlagsPwIcon'))
export const FlagsPyIcon = React.lazy(() => import('./FlagsPyIcon'))
export const FlagsQaIcon = React.lazy(() => import('./FlagsQaIcon'))
export const FlagsReIcon = React.lazy(() => import('./FlagsReIcon'))
export const FlagsRoIcon = React.lazy(() => import('./FlagsRoIcon'))
export const FlagsRsIcon = React.lazy(() => import('./FlagsRsIcon'))
export const FlagsRuIcon = React.lazy(() => import('./FlagsRuIcon'))
export const FlagsRwIcon = React.lazy(() => import('./FlagsRwIcon'))
export const FlagsSaIcon = React.lazy(() => import('./FlagsSaIcon'))
export const FlagsSbIcon = React.lazy(() => import('./FlagsSbIcon'))
export const FlagsScIcon = React.lazy(() => import('./FlagsScIcon'))
export const FlagsSdIcon = React.lazy(() => import('./FlagsSdIcon'))
export const FlagsSeIcon = React.lazy(() => import('./FlagsSeIcon'))
export const FlagsSgIcon = React.lazy(() => import('./FlagsSgIcon'))
export const FlagsShIcon = React.lazy(() => import('./FlagsShIcon'))
export const FlagsSiIcon = React.lazy(() => import('./FlagsSiIcon'))
export const FlagsSjIcon = React.lazy(() => import('./FlagsSjIcon'))
export const FlagsSkIcon = React.lazy(() => import('./FlagsSkIcon'))
export const FlagsSlIcon = React.lazy(() => import('./FlagsSlIcon'))
export const FlagsSmIcon = React.lazy(() => import('./FlagsSmIcon'))
export const FlagsSnIcon = React.lazy(() => import('./FlagsSnIcon'))
export const FlagsSoIcon = React.lazy(() => import('./FlagsSoIcon'))
export const FlagsSrIcon = React.lazy(() => import('./FlagsSrIcon'))
export const FlagsSsIcon = React.lazy(() => import('./FlagsSsIcon'))
export const FlagsStIcon = React.lazy(() => import('./FlagsStIcon'))
export const FlagsSvIcon = React.lazy(() => import('./FlagsSvIcon'))
export const FlagsSxIcon = React.lazy(() => import('./FlagsSxIcon'))
export const FlagsSyIcon = React.lazy(() => import('./FlagsSyIcon'))
export const FlagsSzIcon = React.lazy(() => import('./FlagsSzIcon'))
export const FlagsTcIcon = React.lazy(() => import('./FlagsTcIcon'))
export const FlagsTdIcon = React.lazy(() => import('./FlagsTdIcon'))
export const FlagsTfIcon = React.lazy(() => import('./FlagsTfIcon'))
export const FlagsTgIcon = React.lazy(() => import('./FlagsTgIcon'))
export const FlagsThIcon = React.lazy(() => import('./FlagsThIcon'))
export const FlagsTjIcon = React.lazy(() => import('./FlagsTjIcon'))
export const FlagsTkIcon = React.lazy(() => import('./FlagsTkIcon'))
export const FlagsTlIcon = React.lazy(() => import('./FlagsTlIcon'))
export const FlagsTmIcon = React.lazy(() => import('./FlagsTmIcon'))
export const FlagsTnIcon = React.lazy(() => import('./FlagsTnIcon'))
export const FlagsToIcon = React.lazy(() => import('./FlagsToIcon'))
export const FlagsTrIcon = React.lazy(() => import('./FlagsTrIcon'))
export const FlagsTtIcon = React.lazy(() => import('./FlagsTtIcon'))
export const FlagsTvIcon = React.lazy(() => import('./FlagsTvIcon'))
export const FlagsTwIcon = React.lazy(() => import('./FlagsTwIcon'))
export const FlagsTzIcon = React.lazy(() => import('./FlagsTzIcon'))
export const FlagsUaIcon = React.lazy(() => import('./FlagsUaIcon'))
export const FlagsUgIcon = React.lazy(() => import('./FlagsUgIcon'))
export const FlagsUmIcon = React.lazy(() => import('./FlagsUmIcon'))
export const FlagsUnIcon = React.lazy(() => import('./FlagsUnIcon'))
export const FlagsUsIcon = React.lazy(() => import('./FlagsUsIcon'))
export const FlagsUyIcon = React.lazy(() => import('./FlagsUyIcon'))
export const FlagsUzIcon = React.lazy(() => import('./FlagsUzIcon'))
export const FlagsVaIcon = React.lazy(() => import('./FlagsVaIcon'))
export const FlagsVcIcon = React.lazy(() => import('./FlagsVcIcon'))
export const FlagsVeIcon = React.lazy(() => import('./FlagsVeIcon'))
export const FlagsVgIcon = React.lazy(() => import('./FlagsVgIcon'))
export const FlagsViIcon = React.lazy(() => import('./FlagsViIcon'))
export const FlagsVnIcon = React.lazy(() => import('./FlagsVnIcon'))
export const FlagsVuIcon = React.lazy(() => import('./FlagsVuIcon'))
export const FlagsWfIcon = React.lazy(() => import('./FlagsWfIcon'))
export const FlagsWsIcon = React.lazy(() => import('./FlagsWsIcon'))
export const FlagsXkIcon = React.lazy(() => import('./FlagsXkIcon'))
export const FlagsYeIcon = React.lazy(() => import('./FlagsYeIcon'))
export const FlagsYtIcon = React.lazy(() => import('./FlagsYtIcon'))
export const FlagsZaIcon = React.lazy(() => import('./FlagsZaIcon'))
export const FlagsZmIcon = React.lazy(() => import('./FlagsZmIcon'))
export const FlagsZwIcon = React.lazy(() => import('./FlagsZwIcon'))
export const HeartSolidIcon = React.lazy(() => import('./HeartSolidIcon'))
export const HelpIcon = React.lazy(() => import('./HelpIcon'))
export const ImpositionIcon = React.lazy(() => import('./ImpositionIcon'))
export const InfinityIcon = React.lazy(() => import('./InfinityIcon'))
export const InfoIcon = React.lazy(() => import('./InfoIcon'))
export const LargeCheckIcon = React.lazy(() => import('./LargeCheckIcon'))
export const LargeCloseIcon = React.lazy(() => import('./LargeCloseIcon'))
export const LargeMinusIcon = React.lazy(() => import('./LargeMinusIcon'))
export const LargePlusIcon = React.lazy(() => import('./LargePlusIcon'))
export const LightingIcon = React.lazy(() => import('./LightingIcon'))
export const LineItem1Icon = React.lazy(() => import('./LineItem1Icon'))
export const LinkIcon = React.lazy(() => import('./LinkIcon'))
export const ListIcon = React.lazy(() => import('./ListIcon'))
export const LockIcon = React.lazy(() => import('./LockIcon'))
export const LogOutIcon = React.lazy(() => import('./LogOutIcon'))
export const LoupeIcon = React.lazy(() => import('./LoupeIcon'))
export const MinusIcon = React.lazy(() => import('./MinusIcon'))
export const OkIcon = React.lazy(() => import('./OkIcon'))
export const OpenExtendedIcon = React.lazy(() => import('./OpenExtendedIcon'))
export const Order1Icon = React.lazy(() => import('./Order1Icon'))
export const OrderShipIcon = React.lazy(() => import('./OrderShipIcon'))
export const PeopleIcon = React.lazy(() => import('./PeopleIcon'))
export const PieChartIcon = React.lazy(() => import('./PieChartIcon'))
export const PinIcon = React.lazy(() => import('./PinIcon'))
export const PlusIcon = React.lazy(() => import('./PlusIcon'))
export const PromoIcon = React.lazy(() => import('./PromoIcon'))
export const RingIcon = React.lazy(() => import('./RingIcon'))
export const SettingsIcon = React.lazy(() => import('./SettingsIcon'))
export const ShareIcon = React.lazy(() => import('./ShareIcon'))
export const ShipIcon = React.lazy(() => import('./ShipIcon'))
export const SmallExplosionIcon = React.lazy(() => import('./SmallExplosionIcon'))
export const SmallHeartIcon = React.lazy(() => import('./SmallHeartIcon'))
export const SmallOvalIcon = React.lazy(() => import('./SmallOvalIcon'))
export const SmallSpeedIcon = React.lazy(() => import('./SmallSpeedIcon'))
export const SmallWarningIcon = React.lazy(() => import('./SmallWarningIcon'))
export const SortAZIcon = React.lazy(() => import('./SortAZIcon'))
export const SortZAIcon = React.lazy(() => import('./SortZAIcon'))
export const StarIcon = React.lazy(() => import('./StarIcon'))
export const TrashIcon = React.lazy(() => import('./TrashIcon'))
export const UploadFileIcon = React.lazy(() => import('./UploadFileIcon'))
export const UploadIcon = React.lazy(() => import('./UploadIcon'))
export const UsersListIcon = React.lazy(() => import('./UsersListIcon'))
export const UsersIcon = React.lazy(() => import('./UsersIcon'))
export const WarningIcon = React.lazy(() => import('./WarningIcon'))
export const WorkflowIcon = React.lazy(() => import('./WorkflowIcon'))
export const UserIcon = React.lazy(() => import('./UserIcon'))
