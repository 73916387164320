import React, {
  useState,
  MouseEvent,
  FormEvent,
  useCallback,
  useMemo,
  useRef,
  useEffect
} from 'react'
import cxBinder from 'classnames/bind'
import uuid from 'uuid/v4'
import { startCase } from 'lodash'

import {
  TIcon,
  Icon,
  Checkbox,
  Popup,
  PopupSizeProp,
  usePopup
} from '../../atoms'
import { useSearchHandler } from '../Select/hooks'
import styles from './MultiSelect.module.scss'
import { MultiSelectBadge } from './components/multiselect-badge/MultiSelectBadge'

const cx = cxBinder.bind(styles)

interface ListProps {
  label: string
  value: string
}

interface MultiSelectProps {
  checkedData: string[]
  listData: ListProps[]
  onModal?: (value: boolean) => void
  renderValue?: (
    listData: ListProps[],
    checkedData: string[]
  ) => React.ReactNode | string
  onSelectChange: (value: string) => void
  popupSize?: PopupSizeProp
  e2eTarget?: string
  e2eTargetName?: string
  useStartCase?: boolean
  disabled?: boolean
  withSearch?: boolean
  searchPlaceholder?: string
}

const MultiSelect = ({
  checkedData,
  listData,
  renderValue = (listData, checkedData) =>
    listData
      .filter((option) => checkedData.includes(option.value))
      .map((option) => (
        <MultiSelectBadge {...option} handleRemove={onSelectChange} />
      )),
  onSelectChange,
  onModal,
  popupSize,
  e2eTarget = 'multi-select',
  e2eTargetName,
  useStartCase = true,
  disabled = false,
  withSearch = false,
  searchPlaceholder = ''
}: MultiSelectProps) => {
  const { anchorEl, anchorRef, open, close, toggle, isOpen } = usePopup<
    HTMLDivElement
  >()

  const searchRef = useRef<HTMLInputElement>(null)

  const value = useMemo(() => renderValue(listData, checkedData), [
    checkedData,
    listData,
    renderValue
  ])

  const closeSelect = useCallback(() => {
    close()
    onModal && onModal(false)
  }, [onModal])

  const openMultiSelect = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      open()
      withSearch && searchRef.current && searchRef.current.focus()
      onModal && onModal(true)
    },
    [onModal]
  )

  const onCheckboxChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      e.preventDefault()
      onSelectChange(e.currentTarget.value)
    },
    [onSelectChange]
  )
  const { handleSearch, filteredData, searchValue } = useSearchHandler({
    data: listData,
    popupOpened: isOpen
  })

  const prevCheckedDataLength = useRef<number | undefined>()

  const shouldUpdatePopup = checkedData.length !== prevCheckedDataLength.current

  useEffect(() => {
    prevCheckedDataLength.current = checkedData.length
  }, [filteredData.length, checkedData.length])

  return (
    <div>
      <div
        className={cx('multi-select', {
          'multi-select--disabled': disabled,
          'multi-select--with-search': withSearch
        })}
        ref={anchorRef}
        onClick={!disabled ? openMultiSelect : undefined}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        {value}
        {withSearch && (
          <input
            ref={searchRef}
            placeholder={searchPlaceholder}
            value={searchValue}
            onChange={handleSearch}
            size={searchValue.length || searchPlaceholder.length || 1}
          />
        )}
        <Icon
          icon={TIcon.ArrowsType1Down}
          className={cx('multi-select__icon', {
            'multi-select__icon--open': isOpen
          })}
          e2e-target="icon"
          onClick={toggle}
          e2e-target-name={!isOpen ? 'select-open' : 'select-close'}
        />
      </div>

      <Popup
        anchorEl={anchorEl}
        placement="bottom-start"
        onClickOutside={closeSelect}
        parentWidth
        popupSize={popupSize}
        e2e-target="popup"
        e2e-target-name="multi-select-list"
        fixPosition={shouldUpdatePopup}
      >
        {filteredData.map(({ label, value }) => (
          <label
            key={uuid()}
            className={cx('multi-select__label')}
            e2e-target="select-option-group"
            e2e-target-name={value}
          >
            <Checkbox
              checked={checkedData.includes(value)}
              onChange={onCheckboxChange}
              value={value}
              e2eTarget="checkbox"
            />
            <span className={cx('multi-select__label-text')} e2e-target="label">
              {useStartCase ? startCase(label) : label}
            </span>
          </label>
        ))}
      </Popup>
    </div>
  )
}

export { MultiSelect, MultiSelect as default }
