import {
  useContext,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
  ForwardedRef,
  useCallback
} from 'react'
import isEmpty from 'lodash/isEmpty'
import { useFormik } from 'formik'
import debounce from 'lodash/debounce'
import { SpecificationService } from '@packhelp/platform-pss-api'
import { validate } from '@packhelp/platform-pss-helper'
import { FormBuilder } from '../../helpers/form-builder'

import { isAxios422Error } from '../../helpers/is-axios-422-error'
import { RootContext } from '../../contexts'
import {
  PSSFormConfigSetupWithUrl,
  PSSFormPropsWithConfig
} from '../../types/form-configuration'
import {
  Attributes,
  ProductSpecificationAttributes,
  PSSFormTriggers
} from '../../types'
import {
  useClearEmptyValues,
  useCurrentSpecification,
  useFormErrors,
  useFormEvents,
  useMobileBreakPoint,
  useTouchedOnSubmit,
  useTypeNameChanged
} from './hooks'

export const useProductSpecForm = (
  props: PSSFormPropsWithConfig,
  outsideRef: ForwardedRef<PSSFormTriggers>
) => {
  const {
    errors,
    config,
    specification,
    disabledFields = [],
    readonly = false,
    serverValidation = false,
    prefillRequired = true
  } = props

  const { configSetup, callbacks } = useContext(RootContext)
  const specificationService: SpecificationService = useMemo(
    () =>
      new SpecificationService((configSetup as PSSFormConfigSetupWithUrl)?.url),
    [configSetup]
  )
  const { isMobile, ref } = useMobileBreakPoint()
  const [fields, setFields] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const initialTouched = useMemo(() => {
    if (!specification?.attributes) {
      return {}
    }

    return Object.keys(specification?.attributes).reduce(
      (touched, attrKey) => ({
        ...touched,
        [attrKey]: true
      }),
      {}
    )
  }, [specification?.attributes])

  const handleSubmit = async () => {
    if (callbacks?.onSubmit) {
      if (serverValidation) {
        try {
          await specificationService.validate({
            specification: currentSpecification,
            context: config.metadata.context,
            language: config.metadata.language
          })
        } catch (error) {
          if (isAxios422Error(error)) {
            handleServerErrors(error?.response?.data)
          } else {
            if (callbacks?.onError) {
              callbacks?.onError(error)
            } else {
              console.error('error :>> ', error)
            }
          }
          return
        }
      }

      await callbacks?.onSubmit(currentSpecification)
    }
  }

  const scrollToError = () => {
    setTimeout(() => {
      if (ref.current !== null) {
        const firstErrorField = ((ref.current as unknown) as HTMLElement).querySelector(
          '[data-has-error]'
        )

        firstErrorField?.scrollIntoView({
          behavior: 'smooth'
        })
      }
    })
  }

  const handleValidate = (values: ProductSpecificationAttributes) => {
    const validationResult = validate(values, fields, config.validations)

    if (callbacks.onValidation) {
      callbacks.onValidation(isEmpty(validationResult), validationResult)
    }

    if (isSubmitting) {
      scrollToError()
    }
    return validationResult
  }

  const formik = useFormik<ProductSpecificationAttributes>({
    initialValues: specification?.attributes || {},
    initialErrors: errors || {},
    initialTouched: initialTouched,
    onSubmit: handleSubmit,
    validate: handleValidate,
    validateOnChange: false
  })

  const debouncedHandleValidate = useCallback(
    debounce((values: ProductSpecificationAttributes) => {
      formik.validateForm(values)
    }, 200),
    []
  )

  const currentSpecification = useCurrentSpecification({
    initialSpecification: specification,
    configInfo: {
      version: config.metadata?.version,
      subsetVersion: config.metadata?.subset_version,
      subsetName: config.metadata?.subset_name
    },
    attributes: formik.values as Attributes
  })

  useTouchedOnSubmit(formik, fields)
  useTypeNameChanged(formik)
  useClearEmptyValues(formik)
  useFormEvents(formik)
  const handleServerErrors = useFormErrors(formik, errors)

  const [submitEnabled, setSubmitEnabled] = useState(
    !!currentSpecification.attributes?.type__name
  )

  useEffect(() => {
    setSubmitEnabled(!!currentSpecification.attributes?.type__name)
  }, [currentSpecification])

  useEffect(() => {
    debouncedHandleValidate(formik.values)
  }, [JSON.stringify(fields), JSON.stringify(formik.values)])

  useEffect(() => {
    setIsSubmitting(formik.isSubmitting)
  }, [formik.isSubmitting])

  const { groups, fields: formikFields } = new FormBuilder(
    config,
    formik,
    disabledFields,
    prefillRequired,
    readonly
  )

  useEffect(() => {
    setFields(formikFields)
  }, [JSON.stringify(formikFields)])

  useEffect(() => {
    if (callbacks?.onChange) {
      callbacks?.onChange(currentSpecification)
    }
  }, [callbacks?.onChange, currentSpecification])

  useImperativeHandle(
    outsideRef,
    () => ({
      validate: async () => {
        const errorFields = await formik.validateForm()
        if (Object.keys(errorFields).length === 0) {
          return true
        }
        const touchedFields = Object.keys(errorFields).reduce(
          (touched, attrKey) => ({
            ...touched,
            [attrKey]: true
          }),
          {}
        )
        await formik.setTouched(touchedFields, true)
        scrollToError()
        return false
      },
      submit: () => {
        formik.handleSubmit()
      }
    }),
    []
  )

  return {
    breakpointRef: ref,
    breakpointReached: isMobile,
    onSubmit: callbacks?.onSubmit && formik.handleSubmit,
    groups,
    submitEnabled,
    setValue: formik.setFieldValue,
    isSubmitting: formik.isSubmitting,
    currentAttributes: currentSpecification.attributes,
    typeName: currentSpecification.attributes?.type__name
  }
}
