import React from 'react'
import { Dropzone, FileProps } from '@packhelp/platform-dsl/components'
import { useProductSpecUploader } from './use-product-spec-uploader'

export interface ProductSpecUploaderProps {
  value?: FileProps[]
  error?: boolean
  disabled?: boolean
  onChange: (file?: FileProps[]) => void
}

export const ProductSpecUploader = (props: ProductSpecUploaderProps) => {
  const {
    onDrop,
    labels,
    onRemove,
    isLoading,
    error,
    files,
    maxSize
  } = useProductSpecUploader(props)

  return (
    <Dropzone
      onDrop={onDrop}
      labels={labels}
      onRemove={onRemove}
      files={files}
      error={error}
      isLoading={isLoading}
      disabled={props.disabled}
      maxSize={maxSize}
      multiple
      showFileList
    />
  )
}
