import React, { FocusEventHandler, useEffect, useMemo, useState } from 'react'
import cxBinder from 'classnames/bind'

import styles from './MultiToggleSelect.module.scss'
import { Checkbox } from '../../atoms'
import { MultiToggleSelectCustomOption } from './MultiToggleSelectCustomOption'

const cx = cxBinder.bind(styles)

export type MultiToggleDataType<T> = {
  label: string
  value: T
}

export interface MultiToggleSelectProps<T> {
  data: MultiToggleDataType<T>[]
  onChange: (values: T[]) => void
  onBlur?: FocusEventHandler<HTMLButtonElement>
  name?: string
  value?: T[]
  disabled?: boolean
  error?: boolean
  customOption?: boolean
  customOptionLabel?: string
}

type ToggleData<T> = {
  [key: string]: {
    label: string
    value: T
    checked: boolean
    isCustom: boolean
  }
}

const MultiToggleSelect = <T extends unknown>(
  props: MultiToggleSelectProps<T>
) => {
  const {
    data,
    value = [],
    name,
    onChange,
    onBlur,
    disabled,
    customOption,
    customOptionLabel,
    error
  } = props

  const multiSelectValue = useMemo(
    () => (Array.isArray(value) ? value : [value]),
    [value]
  )

  const initialValues = useMemo<ToggleData<T>>(() => {
    let index = 0
    const toggleData = data.reduce(
      (acc, cur) => ({
        ...acc,
        [index++]: {
          ...cur,
          checked: multiSelectValue.includes(cur.value),
          isCustom: false
        }
      }),
      {}
    )

    if (!customOption) {
      return toggleData
    }

    const customOptionValues = multiSelectValue
      .filter((value) => !data.some((item) => item.value === value))
      .reduce(
        (acc, curr) => ({
          ...acc,
          [index++]: {
            label: customOptionLabel,
            value: curr,
            checked: true,
            isCustom: true
          }
        }),
        {}
      )

    if (multiSelectValue.some((item) => item === ''))
      return {
        ...toggleData,
        ...customOptionValues
      }

    return {
      ...toggleData,
      ...customOptionValues,
      [index++]: {
        label: customOptionLabel,
        value: '',
        checked: false,
        isCustom: true
      }
    }
  }, [JSON.stringify(data), JSON.stringify(multiSelectValue)])

  const [toggleData, setToggleData] = useState<ToggleData<T>>(initialValues)

  useEffect(() => {
    setToggleData(initialValues)
  }, [initialValues])

  const emitChange = (toggleData: ToggleData<T>) => {
    if (disabled) {
      return
    }

    onChange(
      Object.keys(toggleData)
        .filter((key) => toggleData[key].checked)
        .map((key) => toggleData[key].value)
    )
  }

  const toggleCheck = (key: string) => {
    const newValues = {
      ...toggleData,
      [key]: {
        ...toggleData[key],
        checked: !toggleData[key].checked
      }
    }
    setToggleData(newValues)
    emitChange(newValues)
  }

  const handleCustomOptionValueChange = (key: string, value: T | string) => {
    const newToggleData = {
      ...toggleData,
      [key]: {
        ...toggleData[key],
        checked: true,
        value: value as T
      }
    }
    setToggleData(newToggleData)
    emitChange(newToggleData)
  }

  const isEmpty = useMemo(
    () => !Object.keys(toggleData).some((key) => toggleData[key].checked),
    [toggleData]
  )

  return (
    <div className={cx('wrapper', { error, empty: isEmpty })}>
      {Object.keys(toggleData).map((key) => (
        <React.Fragment key={key}>
          {toggleData[key].isCustom ? (
            <MultiToggleSelectCustomOption
              disabled={disabled}
              customOption={customOption}
              customOptionLabel={toggleData[key].label}
              customValue={toggleData[key].value as string}
              setCustomValue={(value) =>
                handleCustomOptionValueChange(key, value)
              }
              isChecked={toggleData[key].checked}
              toggleCheck={() => toggleCheck(key)}
            />
          ) : (
            <>
              <button
                type="button"
                className={cx('wrapper__item', {
                  'wrapper__item--selected': toggleData[key].checked
                })}
                onClick={() => toggleCheck(key)}
                name={name}
                onBlur={onBlur}
                disabled={disabled}
              >
                <Checkbox
                  hidden
                  checked={toggleData[key].checked}
                  value={toggleData[key].value as string}
                  readOnly
                  disabled={disabled}
                  error={error}
                />
                <span>{toggleData[key].label}</span>
              </button>
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export { MultiToggleSelect, MultiToggleSelect as defualt }
