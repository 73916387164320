import { groupsOrder as groupsOrderConfig } from '../config/groups'
import { ProductSpecificationConfig } from '../types'

export interface FormGroup {
  label: string
  name: string
  rows: string[][]
}

export class FormGroupsHandler {
  constructor() {
    this.array = this.array.bind(this)
    this.sort = this.sort.bind(this)
    this.clean = this.clean.bind(this)
  }

  array({
    config,
    ...args
  }: {
    config: ProductSpecificationConfig
    [key: string]: unknown
  }) {
    const groups: FormGroup[] = Object.keys(config.groups).map(
      (groupKey: string) => {
        return {
          ...config.groups[groupKey],
          rows: (
            groupsOrderConfig[groupKey] || []
          ).map((row: string | string[]) => (Array.isArray(row) ? row : [row]))
        }
      }
    )

    return {
      groups,
      ...args
    }
  }

  clean = ({
    groups,
    ...args
  }: {
    groups: FormGroup[]
    [key: string]: unknown
  }) => {
    const groupsMapped = groups.filter((group) => group.rows.length > 0)
    return {
      groups: groupsMapped,
      ...args
    }
  }

  sort({ groups, ...args }: { groups: FormGroup[]; [key: string]: unknown }) {
    const groupsInConfig = groups.filter((group) =>
      Object.keys(groupsOrderConfig).includes(group.name)
    )
    const groupsNotInConfig = groups.filter(
      (group) => !Object.keys(groupsOrderConfig).includes(group.name)
    )

    const groupsInConfigSorted = groupsInConfig.sort((next, prev) => {
      const groupsOrderKeys = Object.keys(groupsOrderConfig)
      return groupsOrderKeys.indexOf(next.name) <
        groupsOrderKeys.indexOf(prev.name)
        ? -1
        : 1
    })

    return {
      groups: [...groupsInConfigSorted, ...groupsNotInConfig],
      ...args
    }
  }
}
