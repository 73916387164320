import React, { useMemo } from 'react'
import cxBinder from 'classnames/bind'
import { MappedField } from '../..'
import { SingleInput } from '../single-input/SingleInput'
import { useMobileBreakPoint } from '../../hooks/product-spec-form/hooks'
import styles from './Row.module.scss'

const cx = cxBinder.bind(styles)

export const Row = ({ fields }: { fields: MappedField[] }) => {
  const { isMobile, ref } = useMobileBreakPoint()

  const gridTemplateColumns = useMemo(() => {
    return fields.reduce(
      (grid: string, field) =>
        `${grid} minmax(auto, ${
          field.ui.takeAvailableSpace ? '100%' : 'max-content'
        })`,
      ''
    )
  }, [fields])

  return (
    <div
      ref={ref}
      style={{ gridTemplateColumns }}
      className={cx('row', {
        'row--grid': !isMobile
      })}
    >
      {fields.map((field: MappedField, i: number) => {
        return (
          <SingleInput
            key={`${field.props.name}-${i}`}
            label={field.ui.label}
            component={field.component}
            {...field.props}
          />
        )
      })}
    </div>
  )
}
