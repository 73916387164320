import { observable, computed } from 'mobx'
import { SingleRowData } from '..'
import { TableStore } from '../table-store'
import { SingleColumnStore, SingleColumnBaseProps } from './single-column-store'

export interface TableColumnsConfig<R extends SingleRowData = SingleRowData>
  extends Array<SingleColumnBaseProps<R>> {}

export class ColumnsStore<R extends SingleRowData = SingleRowData> {
  @observable.shallow public columns: SingleColumnStore<R>[] = []

  constructor(
    private _rootStore: TableStore,
    columnsConfig: TableColumnsConfig<R> = []
  ) {
    this.update(columnsConfig)
  }

  public update(columnsConfig?: TableColumnsConfig<R>): ColumnsStore<R> {
    this.columns =
      (columnsConfig &&
        columnsConfig.map(
          (columnsConfig: SingleColumnBaseProps<R>) =>
            new SingleColumnStore<R>(this._rootStore, columnsConfig)
        )) ||
      []

    return this
  }

  @computed.struct get configsObject(): {
    [key: string]: SingleColumnStore
  } {
    return Object.values(this.columns).reduce(
      (configsObject, config: SingleColumnStore<R>) => ({
        ...configsObject,
        [config.uuid]: config
      }),
      {}
    )
  }

  @computed.struct get leftSide(): SingleColumnStore<R>[] {
    return this.columns.filter(
      (column: SingleColumnStore<R>) => column.side === 'left'
    )
  }

  @computed.struct get rightSide(): SingleColumnStore<R>[] {
    return this.columns.filter(
      (column: SingleColumnStore<R>) => column.side === 'right'
    )
  }

  @computed get sortedColumn() {
    return this.columns.find((column) => column.isSorted)
  }
}
