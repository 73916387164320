import { useMemo, useCallback, useEffect } from 'react'
import hotkeys from 'hotkeys-js'
import { useTableContext } from './table-store'

export const useAccessibility = () => {
  const table = useTableContext()

  const selectAllKeys = useMemo(
    () => 'control+a,command+a,control+shift+a,command+shift+a',
    []
  )

  const handleSelectAll = useCallback(
    (event, handler) => {
      event.preventDefault()
      table.bulk.selectAll()
      if (handler.key.includes('shift')) {
        table.bulk.deselectAll()
      }
    },
    [table]
  )

  useEffect(() => {
    hotkeys(selectAllKeys, (event, handler) => {
      handleSelectAll(event, handler)
    })

    return () => {
      hotkeys.unbind(selectAllKeys)
    }
  }, [selectAllKeys, handleSelectAll])
}
