import React, { useMemo, ReactNode, memo } from 'react'
import cxBinder from 'classnames/bind'

import style from '../../Table.module.scss'

const cx = cxBinder.bind(style)

export interface CellBaseProps {
  children?: ReactNode
  isSorted?: boolean
  isSticky?: boolean
  isSeparator?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  widthStyle?: {
    width?: string | number
    maxWidth?: string | number
    minWidth?: string | number
  }
}

export const CellBase = memo(
  ({
    children,
    isSorted,
    isSticky,
    isSeparator,
    e2eTarget = 'cell',
    e2eTargetName,
    widthStyle
  }: CellBaseProps) => {
    const classes = useMemo(
      () =>
        cx('table__cell', {
          'table__cell--sticky': isSticky,
          'table__cell--separator': isSeparator
        }),
      [isSticky]
    )
    const classesInner = useMemo(
      () =>
        cx('table__cell-inner', {
          'table__cell--sorted': isSorted
        }),
      [isSorted]
    )

    return (
      <div
        className={classes}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        <div className={classesInner} style={widthStyle}>
          {children}
        </div>
      </div>
    )
  }
)

export default CellBase
