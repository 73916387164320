import { FocusEvent } from 'react'
import { PopupSizeProp } from '@packhelp/platform-dsl/atoms'
import { MinWidth } from '@packhelp/platform-dsl/atoms/ToggleSelect'

import { SelectSize } from '@packhelp/platform-dsl/molecules'

import { ValueType } from '../enums/'
import { Attribute, PSSAttributeFile, PSSFormik } from '../types'
import { classicSelectFields } from '../config/classic-select-fields'
import { FieldProps } from '../types/form-fields'

import { checkIfRequired } from './form-validation'

export class FormFieldMapper {
  private getErrorMessage(
    field: string,
    formik: PSSFormik
  ): undefined | string {
    const isError = Boolean(formik.errors[field])
    const isTouched = Boolean(
      formik.touched[field] || formik.initialErrors[field]
    )

    if (!isError || !isTouched) {
      return undefined
    }

    return Array.isArray(formik.errors[field])
      ? ((formik.errors[field] as unknown) as string[])?.join(', ')
      : formik.errors[field]
  }

  map = (
    key: string,
    attribute: Attribute,
    formik: PSSFormik,
    disabledFields: string[],
    readonly = false
  ): FieldProps => {
    const errorMessage = this.getErrorMessage(key, formik)
    const isRequired = checkIfRequired(attribute)
    const disabled = readonly || disabledFields.includes(key)

    if (classicSelectFields.includes(key)) {
      const data = attribute.options || []
      const selectProps: FieldProps = {
        name: key,
        id: key,
        data,
        onSelect: (value, e) => {
          formik.handleChange(e)

          //workaround for custom values - allows to decide if we should clear it or not
          if (!data.find((option) => option.value === value)) {
            formik.setFieldTouched(key, true)
          } else {
            formik.setFieldTouched(key, false)
          }
        },
        value: formik.values[key],
        size: SelectSize.large,
        popupSize: PopupSizeProp.m,
        errorMessage,
        isRequired,
        withSearch: true && !attribute.enable_custom_value,
        enableCustom: attribute.enable_custom_value,
        disabled
      }
      return selectProps
    }

    switch (attribute.value_type) {
      case ValueType.Select: {
        const data = attribute.options || []
        const toggleSelectProps: FieldProps = {
          name: key,
          onBlur: formik.handleBlur,
          minWidth: MinWidth.s,
          onChange: (value: string) => {
            //workaround for custom values - allows to decide if we should clear it or not
            if (!data.find((option) => option.value === value)) {
              formik.setFieldTouched(key, true)
            } else {
              formik.setFieldTouched(key, false)
            }

            formik.setFieldValue(key, value)
          },
          data,
          value: formik.values[key],
          customOption: attribute.enable_custom_value,
          customOptionLabel: 'Custom option',
          errorMessage,
          isRequired,
          disabled,
          type: ValueType.Select
        }
        return toggleSelectProps
      }
      case ValueType.Multiselect: {
        const dataMulti = attribute.options || []
        const multiSelectProps: FieldProps = {
          name: key,
          data: dataMulti,
          onChange: (values: string[]) => {
            formik.setFieldTouched(key, true)
            formik.setFieldValue(key, values)
          },
          onBlur: formik.handleBlur,
          value: formik.values[key],
          errorMessage,
          customOption: attribute.enable_custom_value,
          customOptionLabel: 'Custom option',
          isRequired,
          disabled,
          type: ValueType.Multiselect
        }
        return multiSelectProps
      }
      case ValueType.Boolean: {
        const switchProps: FieldProps = {
          onChange: (value: boolean) => {
            formik.setFieldValue(key, value)
          },
          checked: formik.values[key],
          name: key,
          id: key,
          errorMessage,
          isRequired,
          type: ValueType.Boolean,
          disabled
        }
        return switchProps
      }
      case ValueType.File: {
        const fileProps: FieldProps = {
          name: key,
          onChange: (files: PSSAttributeFile[]) => {
            formik.setFieldValue(key, files)
          },
          value: formik.values[key],
          errorMessage,
          disabled
        }
        return fileProps
      }

      case ValueType.Number: {
        const numberInpurProps: FieldProps = {
          name: key,
          id: key,
          placeholder: attribute.default_unit,
          onChange: formik.handleChange,
          value: formik.values[key],
          onBlur: formik.handleBlur,
          type: ValueType.Number,
          errorMessage,
          isRequired,
          disabled
        }
        return numberInpurProps
      }
      default: {
        const inputProps: FieldProps = {
          lowPerformanceField: true,
          name: key,
          id: key,
          onBlur: (e: FocusEvent<HTMLInputElement>) => {
            formik.handleChange(e)
            return formik.handleBlur(e)
          },
          value: formik.values[key],
          errorMessage,
          isRequired,
          disabled
        }
        return inputProps
      }
    }
  }
}
