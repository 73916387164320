import React, { useEffect, useState, useCallback } from 'react'
import { autorun, reaction } from 'mobx'
import { Cell } from '../elements/Cell/Cell'
import { useTableContext } from './table-store'
import { useRowContext } from './row-store'

export const useRowHandler = () => {
  const table = useTableContext()
  const row = useRowContext()

  const mappedCells = (side: 'leftSide' | 'rightSide' = 'leftSide') => {
    return table.columns[side]
      ? table.columns[side].map((cell) => (
          <Cell key={cell.key} configUUID={cell.uuid} />
        ))
      : []
  }

  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isHeaderContext, setIsHeaderContext] = useState<boolean>(false)
  const [rowKey, setRowKey] = useState<string>('')
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [hoverable, setHoverable] = useState<boolean>(false)
  const [clickable, setClickable] = useState<boolean>(false)
  const [isDimmed, setIsDimmed] = useState<boolean>(false)
  const [e2eTarget, setE2eTarget] = useState<string | null>(null)
  const [leftCells, setLeftSells] = useState<JSX.Element[] | null>(null)
  const [rightCells, setRightCells] = useState<JSX.Element[] | null>(null)

  useEffect(() => autorun(() => setIsTableLoading(table.isLoading)), [])

  useEffect(() => autorun(() => setRowKey(row.key)), [])

  useEffect(
    () => autorun(() => setIsHeaderContext(row.context === 'header')),
    []
  )

  useEffect(
    () => autorun(() => setIsSelected(!isHeaderContext && !!row.isSelected)),
    [isHeaderContext]
  )

  useEffect(
    () => autorun(() => setShowError(!isHeaderContext && !!row.showError)),
    [isHeaderContext]
  )

  useEffect(
    () => autorun(() => setIsDimmed(!isHeaderContext && !!row.isDimmed)),
    [isHeaderContext]
  )

  useEffect(
    () => autorun(() => setHoverable(!isHeaderContext && row.hoverable)),
    [isHeaderContext]
  )

  useEffect(
    () => autorun(() => setClickable(!isHeaderContext && row.clickable)),
    [isHeaderContext]
  )

  useEffect(
    () =>
      autorun(() =>
        setE2eTarget(isHeaderContext ? 'table-header-row' : 'table-row')
      ),
    [isHeaderContext]
  )

  useEffect(
    () =>
      reaction(
        () =>
          table.columns.leftSide.reduce(
            (stamp, cell) => `${stamp},${cell.key}`,
            ''
          ),
        () => {
          setLeftSells(mappedCells('leftSide'))
        },
        { fireImmediately: true }
      ),
    []
  )

  useEffect(
    () =>
      reaction(
        () =>
          table.columns.rightSide.reduce(
            (stamp, cell) => `${stamp},${cell.key}`,
            ''
          ),
        () => {
          setRightCells(mappedCells('rightSide'))
        },
        { fireImmediately: true }
      ),
    []
  )

  const onClick = useCallback(() => clickable && row.onClick(row.rowData), [
    clickable,
    row
  ])

  return {
    isTableLoading,
    rowKey,
    isHeaderContext,
    isSelected,
    showError,
    isDimmed,
    e2eTarget,
    leftCells,
    rightCells,
    hoverable,
    clickable,
    onClick
  }
}
