import { observable } from 'mobx'
import { ColumnsStore, TableColumnsConfig } from './columns'
import { SortStore, SortByConfig, SortFn } from './sort-store'
import { RowsStore, RowData, TableRowsConfig, SingleRowData } from './rows'
import { BulkStore, BulkSelectionFunction } from './bulk-store'
import { TableTimeoutsRegistry } from './table-timeouts-registry'
import WindowStore from './window-store'

export interface TableConfig<R extends SingleRowData = SingleRowData> {
  idAccessor: string
  columns?: TableColumnsConfig<R>
  rows?: TableRowsConfig
  sortedBy?: SortByConfig
  onBulkSelect?: BulkSelectionFunction
  search?: string
}
export interface TableProps {
  isLoading?: boolean
  withSpacing?: boolean
  config: TableConfig
  data: RowData
  onSort?: SortFn
}

export class TableStore {
  @observable public isLoading = false
  @observable public withSpacing = false

  public columns: ColumnsStore
  public rows: RowsStore
  public sort: SortStore
  public bulk: BulkStore<unknown>
  public timeoutsRegistry: TableTimeoutsRegistry
  public windowParams: WindowStore

  public accessor = ''

  constructor(
    tableStoreConfig: TableProps = {
      config: {
        idAccessor: ''
      },
      data: []
    }
  ) {
    this.timeoutsRegistry = new TableTimeoutsRegistry()
    this.windowParams = new WindowStore(
      this,
      document.getElementById('root') || undefined
    )

    this.sort = new SortStore(
      tableStoreConfig.config && tableStoreConfig.config.sortedBy,
      tableStoreConfig.onSort
    )

    this.columns = new ColumnsStore(
      this,
      tableStoreConfig.config && tableStoreConfig.config.columns
    )

    this.rows = new RowsStore(
      this,
      tableStoreConfig.data,
      tableStoreConfig.config.rows
    )

    this.bulk = new BulkStore(this, tableStoreConfig.config.onBulkSelect)

    this.accessor = tableStoreConfig.config.idAccessor
  }

  public update(tableStoreConfig: TableProps): TableStore {
    // to show loading before any other updates
    if (tableStoreConfig.isLoading === true && this.isLoading === false) {
      this.isLoading = true
      this.bulk.clearBulk()
    }
    if (typeof tableStoreConfig.withSpacing !== 'undefined') {
      this.withSpacing = tableStoreConfig.withSpacing
    }
    this.timeoutsRegistry.clearAll()
    this.timeoutsRegistry.setTimeout(
      () => {
        if (tableStoreConfig.isLoading !== true) {
          if (this.accessor !== tableStoreConfig.config.idAccessor) {
            this.accessor = tableStoreConfig.config.idAccessor
          }

          this.sort.update(
            tableStoreConfig.config && tableStoreConfig.config.sortedBy,
            tableStoreConfig.onSort
          )

          this.rows.update(tableStoreConfig.data)

          this.columns.update(
            tableStoreConfig.config && tableStoreConfig.config.columns
          )

          this.bulk.update(tableStoreConfig.config.onBulkSelect)
        }
        //to hide loading after any other updates
        if (tableStoreConfig.isLoading === false && this.isLoading === true) {
          this.isLoading = false
        }
      },
      0,
      'setup-table'
    )

    return this
  }
}
