import React, { forwardRef, ForwardedRef } from 'react'
import { Loading, LoadingSize } from '@packhelp/platform-dsl/atoms'

import { ProductSpecificationForm } from './components/product-specification-form/ProductSpecificationForm'
import { useConfig } from './hooks/use-config/use-config'
import { RootContext } from './contexts'
import styles from './PSSForm.module.scss'
import { PSSFormProps, PSSFormTriggers } from './types/form-configuration'

const PSSFormWrapped = forwardRef(
  (props: PSSFormProps, ref: ForwardedRef<PSSFormTriggers>) => {
    const { config } = useConfig(props)

    if (!config) {
      return (
        <div className={styles['spec--loading']}>
          <Loading size={LoadingSize.xl} />
        </div>
      )
    }

    return <ProductSpecificationForm config={config} {...props} fRef={ref} />
  }
)

export const PSSForm = forwardRef(
  (props: PSSFormProps, ref: ForwardedRef<PSSFormTriggers>) => {
    return (
      <RootContext.Provider
        value={{
          configSetup: props.configSetup,
          previewsSetup: props.previewsSetup,
          callbacks: props.callbacks
        }}
      >
        <PSSFormWrapped {...props} ref={ref} />
      </RootContext.Provider>
    )
  }
)
