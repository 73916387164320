export const getAbbreviation = (
  text?: string | null
): string | null | undefined => {
  if (!text) return null

  return text
    .trim()
    .split(' ')
    .reduce(
      (
        previousValue: string,
        currentValue: string,
        currentIndex: number,
        array: string[]
      ) => {
        const firstLetter: string = currentValue.substring(
          0,
          array.length === 1 ? 2 : 1
        )
        if (currentIndex === 0 || currentIndex === array.length - 1) {
          return `${previousValue}${firstLetter}`
        }
        return previousValue
      },
      ''
    )
}

export const getHSLAColorFromString = (
  text?: string | null
): string | undefined => {
  if (!text) {
    return undefined
  }

  let hash = 0
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash)
  }
  const h = hash % 360
  const s = (hash % 20) + 70
  const l = (hash % 20) + 45

  return `hsla(${h}, ${s}%, ${l}%, 0.8)`
}
