import { useEffect, useMemo, useState } from 'react'
import { Attributes, ProductSpecification } from '../../../../types'

export interface CurrentSpecification {
  configInfo?: {
    version?: string
    subsetVersion?: string
    subsetName?: string
  }
  initialSpecification?: ProductSpecification
  attributes: Attributes
}

export const useCurrentSpecification = ({
  initialSpecification,
  configInfo: { version, subsetName, subsetVersion } = {},
  attributes
}: CurrentSpecification): ProductSpecification => {
  const initialSpec = useMemo<ProductSpecification>(
    () => ({
      metadata: {
        created_at: new Date().toISOString(),
        configuration_version: (version ||
          initialSpecification?.metadata?.configuration_version) as string,
        subset_version: subsetVersion,
        subset_name: subsetName,
        specification_structure_version: '1.0.0',
        source: `PSS Form`
      },
      attributes: attributes
    }),
    [attributes, initialSpecification, version, subsetVersion, subsetName]
  )

  const [currentSpecification, setCurrentSpecification] = useState<
    ProductSpecification
  >(initialSpec)

  useEffect(() => {
    setCurrentSpecification(initialSpec)
  }, [attributes, initialSpecification, version, subsetVersion, subsetName])

  return currentSpecification
}
