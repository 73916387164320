import React, { FC } from 'react'
import cxBinder from 'classnames/bind'

import Icon from '../Icon/Icon'
import { TIcon } from '../Icon/IconTypes'
import { CountryFlags } from './types'

import styles from './Flag.module.scss'

const cx = cxBinder.bind(styles)
interface FlagProps {
  flag: CountryFlags
  e2eTarget?: string
  e2eTargetName?: string
}

const Flag: FC<FlagProps> = ({ flag, e2eTarget = 'flag', e2eTargetName }) =>
  flag ? (
    <div
      className={cx('flag-wrapper')}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <Icon className={cx('flag')} icon={TIcon[flag]} />
    </div>
  ) : null

export { Flag, Flag as default }
