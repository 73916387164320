import React, {
  Context,
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect
} from 'react'
import { TableProps, TableStore } from '../stores/table-store'

export const tableRootContext: Context<TableStore | null> = createContext<TableStore | null>(
  null
)

export const TableProvider = ({
  children,
  tableConfig
}: {
  children?: ReactNode
  tableConfig: TableProps
}) => {
  const [store, setStore] = useState<TableStore | null>(null)
  useEffect(() => {
    if (store) {
      store.update(tableConfig)
    } else {
      setStore(new TableStore(tableConfig))
    }
  }, [store, tableConfig])

  if (!store) return null

  return (
    <tableRootContext.Provider value={store}>
      {children}
    </tableRootContext.Provider>
  )
}

export const useTableContext = () => {
  const context = useContext(tableRootContext)
  if (!context) {
    throw new Error('useTableContext must be used within a TableProvider.')
  }
  return context
}
