import { useEffect, useMemo, useState } from 'react'
import { useTableContext } from './table-store'
import { useAccessibility } from './accessibility'
import { useScroll } from '../../../hooks/scroll/scroll'
import { autorun } from 'mobx'

export const useTableHandler = () => {
  const table = useTableContext()
  useAccessibility()

  const [isTableLoading, setIsTableLoading] = useState(false)
  const [tableHasLeftPadding, setTableHasLeftPadding] = useState(false)
  const [tableHasRightPadding, setTableHasRightPadding] = useState(false)
  const [tableHasMargin, setTableHasMargin] = useState(false)

  const { onScrollChange, elementRef, scrollPosition, scrollSize } = useScroll()

  useEffect(() => autorun(() => setIsTableLoading(table.isLoading)), [])

  useEffect(
    () =>
      autorun(() =>
        setTableHasLeftPadding(table.withSpacing && !table.bulk.isEnabled)
      ),
    []
  )

  useEffect(() => autorun(() => setTableHasRightPadding(table.withSpacing)), [])

  useEffect(
    () =>
      autorun(() =>
        setTableHasMargin(table.withSpacing && table.bulk.isEnabled)
      ),
    []
  )

  useEffect(() => {
    return () => {
      table.windowParams.destructor()
    }
  }, [table.windowParams])

  const shadowRight = useMemo(
    () => scrollSize > 0 && scrollPosition < scrollSize,
    [scrollSize, scrollPosition]
  )

  const shadowLeft = useMemo(() => scrollPosition > 0, [scrollPosition])

  return {
    shadowLeft,
    shadowRight,
    isTableLoading,
    onScrollChange,
    scrollRef: elementRef,
    tableHasLeftPadding,
    tableHasRightPadding,
    tableHasMargin
  }
}
