import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  RefObject,
  useEffect,
  useState
} from 'react'
import { simulateInputChange } from '../../../../atoms/Input/helpers'
import { ValueProps } from '../../types'

interface ValueHandlerProps {
  inputRef: RefObject<HTMLInputElement | null>
  wrapperRef: RefObject<HTMLDivElement | null>
  value?: ValueProps
  closePopup: () => void
  onSelect?: (value, event: ChangeEvent<HTMLInputElement>) => void
  onValueChange?: (value) => void
}

export const useValueHandler = ({
  inputRef,
  onSelect,
  closePopup,
  value,
  wrapperRef,
  onValueChange
}) => {
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [value])
  useEffect(() => {
    onValueChange && onValueChange(selectedValue)
  }, [selectedValue, onValueChange])

  const input = inputRef && inputRef.current

  const handleValueChange = (
    event:
      | MouseEvent<HTMLDivElement | SVGElement>
      | KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectedValue(value)

    if (input) {
      const e = simulateInputChange(input, value as string)
      onSelect && onSelect(e.target.value, e)
    }

    closePopup()
    wrapperRef.current?.focus()
  }

  const handleClear = (event: MouseEvent<SVGElement>) =>
    handleValueChange(event, '')

  return {
    selectedValue,
    handleValueChange,
    handleClear
  }
}
