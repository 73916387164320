import React, { MouseEvent, useMemo } from 'react'

import cxBinder from 'classnames/bind'

import Icon from '../../atoms/Icon/Icon'
import { TIcon } from '../../atoms/Icon/IconTypes'
import { ConditionalTooltip } from '../../atoms/Tooltip/ConditionalTooltip'

import { useScroll } from '../../hooks/scroll/scroll'

import styles from './FilterBadge.module.scss'

const cx = cxBinder.bind(styles)

interface FilterBadgeProps {
  onClose?: (e: MouseEvent<SVGElement>) => void
  onClick: (e: MouseEvent<HTMLDivElement>) => void
  filterType: React.ReactNode
  filterValue: React.ReactNode
  filterOperator?: React.ReactNode
  tooltipText?: string[] | string
  forceTooltip?: boolean
  isActive?: boolean
  error?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const FilterBadge = ({
  isActive,
  onClick,
  onClose,
  filterValue,
  filterType,
  filterOperator,
  tooltipText,
  error = false,
  forceTooltip,
  e2eTarget = 'filter-badge',
  e2eTargetName
}: FilterBadgeProps) => {
  const { scrollSize, elementRef } = useScroll()

  const shouldShowTooltip = scrollSize || forceTooltip

  const tooltipTextRenderer = useMemo(() => {
    if (Array.isArray(tooltipText)) {
      return tooltipText.map((value, i) => <div key={i}>{value}</div>)
    }
    return tooltipText
  }, [tooltipText])

  return (
    <div
      className={cx('filter-badge', {
        'filter-badge--active': isActive,
        'filter-badge--error': error
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <ConditionalTooltip
        condition={!!shouldShowTooltip}
        tooltipText={tooltipTextRenderer}
      >
        <div className={cx('badge-wrapper')} e2e-target="badge-group">
          <div
            onClick={onClick}
            className={cx('text-wrapper', {
              'text-wrapper--shadow': onClose,
              'text-wrapper--error': error
            })}
            e2e-target="badge-text-group"
          >
            <span
              className={cx('value', { 'value--bold': filterOperator })}
              e2e-target="badge-text"
              e2e-target-name="type"
            >
              {filterType}
            </span>
            <span
              className={cx({ value: filterOperator })}
              e2e-target="badge-text"
              e2e-target-name="operator"
            >
              {filterOperator}
            </span>

            <div
              ref={elementRef}
              className={cx('value', 'value--ellipsis', {
                'value--bold': !error
              })}
              e2e-target="badge-text"
              e2e-target-name="value"
            >
              {filterValue}
            </div>
          </div>
          {onClose && (
            <Icon
              icon={TIcon.Close}
              onClick={onClose}
              className={cx('icon-close')}
              e2e-target="badge-icon"
              e2e-target-name="close"
            />
          )}
        </div>
      </ConditionalTooltip>
    </div>
  )
}

export { FilterBadge, FilterBadge as default }
