import React from 'react'
import { useCallback } from 'react'
import { Icon, TIcon } from '../../../../atoms/Icon'
import styles from './MultiSelectBadge.module.scss'

export interface MultiSelectBadgeProps {
  handleRemove: (value: string) => void
  value: string
  label: string
}

export const MultiSelectBadge = ({
  handleRemove,
  value,
  label
}: MultiSelectBadgeProps): JSX.Element => {
  const handleClick = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      event.stopPropagation()
      event.preventDefault()
      handleRemove(value)
    },
    [handleRemove, value]
  )

  return (
    <div key={value} className={styles.multiselect_badge}>
      <span>{label}</span>
      <Icon
        icon={TIcon.Close}
        onClick={handleClick}
        e2eTargetName={`remove-option-${value}`}
      />
    </div>
  )
}
