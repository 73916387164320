import React, { useMemo } from 'react'
import {
  startOfMonth,
  startOfToday,
  startOfYear,
  format,
  addMonths
} from 'date-fns'
import { Calendar, RenderProps } from 'dayzed'

import cxBinder from 'classnames/bind'
import { Icon, TIcon } from '../../Icon'

import styles from './DatepickerCalendar.module.scss'

const cx = cxBinder.bind(styles)

export interface CalendarNavigationProps {
  localeObject: Locale
  calendar: Calendar
  dayzed: RenderProps
}

export const CalendarNavigation = (props: CalendarNavigationProps) => {
  const {
    localeObject,
    calendar,
    dayzed: { calendars, getBackProps, getForwardProps }
  } = props

  const monthNames = useMemo(
    () =>
      [...Array(12).keys()].map((i) =>
        format(
          addMonths(startOfMonth(startOfYear(startOfToday())), i),
          'MMMM',
          {
            locale: localeObject
          }
        )
      ),
    [localeObject]
  )

  return (
    <section className={cx('navigation')}>
      <span
        {...getBackProps({ calendars })}
        onClick={(e) => {
          const actions = getBackProps({ calendars })
          actions.onClick(e)
          e.preventDefault()
          e.stopPropagation()
        }}
        className={styles['arrow-back']}
      >
        <Icon icon={TIcon.ArrowsType3Left} />
      </span>
      {monthNames[calendar.month]} {calendar.year}
      <span
        {...getForwardProps({ calendars })}
        onClick={(e) => {
          const actions = getForwardProps({ calendars })
          actions.onClick(e)
          e.preventDefault()
          e.stopPropagation()
        }}
        className={styles['arrow-forward']}
      >
        <Icon icon={TIcon.ArrowsType3Right} />
      </span>
    </section>
  )
}
