import { useMemo, useState, useEffect } from 'react'
import { usePopper } from 'react-popper'
import { PopperProps } from '../types'
import { usePopperModifiers } from './popper-modifiers'

export const usePopperProps = ({
  anchorEl,
  popperRef,
  placement,
  modifiers,
  width,
  parentWidth
}: PopperProps) => {
  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>()
  const popperModifiers = usePopperModifiers({ modifiers, parentWidth, width })
  const popper = usePopper(anchorEl, popperEl, {
    placement,
    modifiers: popperModifiers
  })

  const popperProps = useMemo(
    () => ({
      ref: setPopperEl,
      style: popper.styles.popper,
      ...popper.attributes.popper
    }),
    [popper]
  )

  useEffect(() => {
    popperRef.current = popper
  }, [popper, popperRef])

  return { popperProps, popperEl }
}
