import { useState, useCallback } from 'react'

export function useLongPress(callback: Function, ms = 300) {
  const [intervalId, setIntervalId] = useState<number | undefined>()

  const toggleAction = useCallback(() => {
    const id = setInterval(callback, ms)
    setIntervalId(id)
  }, [callback, ms])

  const start = useCallback(() => {
    toggleAction()
  }, [toggleAction])

  const stop = useCallback(() => {
    clearInterval(intervalId)
  }, [intervalId])

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop
  }
}
