import React, { FC, ReactNode, useMemo, memo, RefObject } from 'react'
import cxBinder from 'classnames/bind'

import Tooltip from '../../../../atoms/Tooltip/Tooltip'
import { useShouldTrim } from '../../hooks/should-trim'
import styles from '../../Table.module.scss'

const cx = cxBinder.bind(styles)

interface TextWrapperProps {
  headline?: string | ReactNode
  children: React.ReactNode
  e2eTarget?: string
  e2eTargetName?: string
}

const CellTextWrapper: FC<TextWrapperProps> = memo(
  ({ headline, children, e2eTarget = 'cell-text-wrapper', e2eTargetName }) => {
    const { shouldTrim, ref } = useShouldTrim()

    const memoizeValue = useMemo(
      () =>
        shouldTrim ? (
          <Tooltip
            headline={headline}
            triggerElement={
              <div
                ref={ref as RefObject<HTMLDivElement>}
                className={cx('text-wrapper__trim', {
                  'text-wrapper__trim--hide': true
                })}
              >
                {children}
              </div>
            }
          >
            <div className={cx('text-wrapper__tooltip-content')}>
              {children}
            </div>
          </Tooltip>
        ) : (
          <div
            ref={ref as RefObject<HTMLDivElement>}
            className={cx('text-wrapper__trim')}
          >
            {children}
          </div>
        ),
      [shouldTrim, ref, headline, children]
    )

    return (
      <div
        className={cx('text-wrapper')}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        {memoizeValue}
      </div>
    )
  }
)

export { CellTextWrapper, CellTextWrapper as default }
