import React, { useMemo } from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { SortableContainerProps, ColumnsPopupConfig } from '../common'
import { SortableItem } from './SortableItem'

export const SortableList = SortableContainer(
  ({ list, onChange }: SortableContainerProps) => {
    const sortableList = useMemo(
      () =>
        list.map(({ column }: ColumnsPopupConfig, index: number) => (
          <SortableItem
            key={column}
            item={column}
            index={index}
            config={list}
            onChange={onChange}
          />
        )),
      [list, onChange]
    )

    return <div>{sortableList}</div>
  }
)
