import { useCallback, useMemo, useState, ChangeEvent, useEffect } from 'react'
import { includes } from 'lodash'
import { SelectData } from '../../types'

export const useSearchHandler = <Data extends SelectData>({
  data,
  onChange,
  popupOpened,
  onSearchChange,
  searchControlled
}: {
  data: Data[]
  onChange?: (value: string) => unknown
  popupOpened: boolean
  onSearchChange?: (event: ChangeEvent<HTMLInputElement>) => unknown
  searchControlled?: boolean
}) => {
  const [searchValue, setSearchValue] = useState('')

  const filteredData = useMemo(() => {
    if (!searchValue || searchControlled) {
      return data
    }

    return data.filter(
      (listItem) =>
        includes(
          `${listItem.label}`.toLowerCase(),
          searchValue.toLowerCase()
        ) ||
        includes(
          `${listItem.value}`.trim().toLowerCase(),
          searchValue.toLowerCase()
        )
    )
  }, [data, searchValue])

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event?.target?.value)
      onSearchChange && onSearchChange(event)
    },
    [setSearchValue]
  )

  useEffect(() => {
    onChange && onChange(searchValue)
  }, [searchValue])

  useEffect(() => {
    if (!popupOpened) {
      setSearchValue('')
    }
  }, [popupOpened])

  return {
    handleSearch,
    filteredData,
    searchValue
  }
}
