import React, { RefObject, MouseEvent, KeyboardEvent, ReactNode } from 'react'
import cxBinder from 'classnames/bind'

import { OptionRenderer } from '../option-renderer/OptionRenderer'
import { ActiveListItemRef } from '../../hooks/refs/refs'
import { ValueProps } from '../../types'
import styles from './SelectOption.module.scss'

const cx = cxBinder.bind(styles)

interface SelectOptionProps {
  label: ReactNode
  refObj: ActiveListItemRef | null
  value: ValueProps
  selected?: boolean
  onClick?: (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => void
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>, value: ValueProps) => void
  optionRenderer?: (label: ReactNode, value: ValueProps) => ReactNode
}

export const SelectOption = ({
  label,
  onClick,
  onKeyDown,
  optionRenderer,
  refObj,
  selected = false,
  value
}: SelectOptionProps) => {
  return (
    <div
      ref={refObj}
      onKeyDown={(event) => onKeyDown && onKeyDown(event, value)}
      tabIndex={0}
      onClick={(event: MouseEvent<HTMLDivElement>) =>
        onClick && onClick(event, value)
      }
      className={cx('select-option', {
        'select-option--active': selected
      })}
      e2e-target="label"
    >
      <OptionRenderer
        value={value}
        label={label}
        optionRenderer={optionRenderer}
      />
    </div>
  )
}
