import React, { FC, useState, useEffect, memo } from 'react'
import { reaction } from 'mobx'
import { useRowContext } from '../../hooks/row-store'
import { HeaderCell } from './HeaderCell'
import { RowCell } from './RowCell'

export const Cell: FC<{ configUUID: string }> = memo(({ configUUID }) => {
  const row = useRowContext()

  const [isHeader, setIsHeader] = useState(row.context === 'header')

  useEffect(
    () =>
      reaction(
        () => row.context,
        (context) => setIsHeader(context === 'header')
      ),
    []
  )

  if (isHeader) return <HeaderCell configUUID={configUUID} />

  return <RowCell configUUID={configUUID} />
})
