import { useEffect } from 'react'
import { PSSFormik } from '../../../../types'

export const useTypeNameChanged = (formik: PSSFormik) => {
  useEffect(() => {
    if (formik?.values?.type__name !== formik?.initialValues?.type__name) {
      formik.setTouched({})
      formik.setValues({
        type__name: formik?.values?.type__name
      })
    }
  }, [formik?.values?.type__name, formik?.initialValues])
}
