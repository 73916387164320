import React, { FC, ReactNode, MouseEvent } from 'react'
import cxBinder from 'classnames/bind'
import { Icon, TIcon } from '../../../../atoms'
import { SelectData, ValueProps, SelectSize } from '../../types'
import styles from './SelectLabel.module.scss'
const cx = cxBinder.bind(styles)

interface SelectLabelInnerProps {
  data?: SelectData[]
  placeholder?: ReactNode | string
  selectedValue?: ValueProps
  itemRenderer?: (label: ReactNode, value: ValueProps) => ReactNode
}
const SelectLabelInner: FC<SelectLabelInnerProps> = ({
  children,
  data,
  itemRenderer,
  placeholder,
  selectedValue
}) => {
  if (selectedValue) {
    const item = (!children &&
      data &&
      data.find(({ value }) => value === selectedValue)) || {
      label: selectedValue,
      value: selectedValue
    }
    if (!item) return null
    if (itemRenderer) {
      return <>{itemRenderer(item.label, selectedValue)}</>
    }
    return item && <>{item.label}</>
  }
  if (placeholder) return placeholder as JSX.Element
  return null
}

interface SelectLabelProps extends SelectLabelInnerProps {
  popupOpened: boolean
  disabled?: boolean
  onClear?: (event: MouseEvent<SVGElement>) => void
  error?: boolean
  size?: SelectSize
}

export const SelectLabel: FC<SelectLabelProps> = ({
  children,
  data,
  disabled,
  onClear,
  error,
  itemRenderer,
  placeholder,
  popupOpened,
  selectedValue,
  size,
  ...other
}) => {
  const isClearable =
    onClear && selectedValue !== '' && !disabled && selectedValue !== undefined

  return (
    <div
      className={cx('wrapper', {
        [`wrapper--${size}`]: size,
        active: popupOpened,
        disabled,
        error
      })}
      tabIndex={0}
      {...other}
    >
      <span className={cx('select')} e2e-target="label">
        <SelectLabelInner
          children={children}
          data={data}
          itemRenderer={itemRenderer}
          placeholder={placeholder}
          selectedValue={selectedValue}
        />
      </span>

      {isClearable && (
        <Icon
          icon={TIcon.Close}
          className={cx('clear-icon')}
          onClick={onClear}
          e2e-target="icon"
          e2e-target-name="select-clear"
        />
      )}
      <Icon
        icon={TIcon.ArrowsType1Down}
        className={cx('icon', {
          'icon--open': popupOpened
        })}
        e2e-target="icon"
        e2e-target-name={popupOpened ? 'select-open' : 'select-close'}
      />
    </div>
  )
}
