import React, { memo, ReactElement } from 'react'
import cxBinder from 'classnames/bind'
import { TableProvider } from './hooks/table-store'
import { TableProps } from './stores/table-store'
import { Header, Body, Shadow } from './elements'
import styles from './Table.module.scss'
import { useTableHandler } from './hooks/table-handler'
const cx = cxBinder.bind(styles)

export const TableContent = memo(() => {
  const {
    shadowLeft,
    shadowRight,
    isTableLoading,
    onScrollChange,
    scrollRef,
    tableHasMargin,
    tableHasLeftPadding,
    tableHasRightPadding
  } = useTableHandler()

  return (
    <>
      <div
        className={cx('content')}
        e2e-target="table-content"
        data-table-loaded={!isTableLoading}
      >
        <Shadow shadowLeft={shadowLeft} shadowRight={shadowRight} />
        <div
          ref={scrollRef}
          className={cx('content__scroll', {
            'content__scroll--left-padding': tableHasLeftPadding,
            'content__scroll--right-padding': tableHasRightPadding,
            'content__scroll--left-margin': tableHasMargin
          })}
          e2e-target="table-content-scroll"
          onScroll={onScrollChange}
        >
          <div className={cx('table')}>
            <Header isLoading={isTableLoading} />
            <Body />
          </div>
        </div>
      </div>
    </>
  )
})

export const Table = (tableConfig: TableProps): ReactElement => (
  <TableProvider tableConfig={tableConfig}>
    <TableContent />
  </TableProvider>
)

export default Table
