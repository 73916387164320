import React, { FC, memo } from 'react'
import { RowProvider } from '../hooks/row-store'
import { Row } from './Row'

export const Header: FC<{ isLoading: boolean }> = memo(({ isLoading }) => {
  if (isLoading) return null

  return (
    <RowProvider rowConfig={{ context: 'header' }}>
      <Row />
    </RowProvider>
  )
})

export default Header
