import { applyConstraints } from '@packhelp/platform-pss-helper'
import isEqual from 'lodash/isEqual'
import { Attribute } from '../types'
import {
  FormBuilderParams,
  FormBuilderParamsWithFields,
  FormBuilderParamsWithValues
} from './form-builder'

export class FormConstraintsHandler {
  constructor() {
    this.apply = this.apply.bind(this)
    this.updateFormikValues = this.updateFormikValues.bind(this)
    this.clearUnused = this.clearUnused.bind(this)
  }

  apply(params: FormBuilderParams): FormBuilderParamsWithFields {
    const fields = applyConstraints(
      params.config.attributes,
      params.config.constraints,
      params.formik.values
    )

    return {
      fields,
      ...params
    }
  }

  public clearUnused({
    formik,
    fields,
    ...args
  }: FormBuilderParamsWithFields): FormBuilderParamsWithValues {
    const mappedFormValues = Object.keys(formik.values).reduce(
      (mapped, fieldKey) => {
        const value = Object.prototype.hasOwnProperty.call(fields, fieldKey)
          ? this.fieldFilteredValue(
              formik.values[fieldKey],
              fields[fieldKey],
              formik.touched[fieldKey]
            )
          : undefined
        if (value !== undefined) {
          return {
            ...mapped,
            [fieldKey]: value
          }
        }
        return mapped
      },
      {}
    )

    return { formik, fields, formValues: mappedFormValues, ...args }
  }

  public updateFormikValues({
    formValues,
    formik,
    ...args
  }: FormBuilderParamsWithValues): FormBuilderParamsWithValues {
    if (!isEqual(formValues, formik.values)) {
      formik.setValues(formValues)
    }

    return {
      formValues,
      formik,
      ...args
    }
  }

  private fieldFilteredValue = <T>(
    value: T | Array<T>,
    field: Attribute,
    isTouched?: boolean
  ): T | Array<T> | string | string[] | undefined => {
    if (
      ['select', 'multiselect'].includes(field['value_type']) &&
      (field['enable_custom_value'] !== true || !isTouched)
    ) {
      const options = field['options']?.map((option) => option.value) || []

      //multiselect value check
      if (Array.isArray(value)) {
        const valueMapped = ((value as unknown) as string[]).filter(
          (oneValue: string) =>
            options.includes(oneValue) || field['enable_custom_value'] === true
        )
        return valueMapped
      } else {
        if (!options.includes((value as unknown) as string)) {
          return undefined
        }
      }
    }
    return value
  }
}
