import { computed, observable } from 'mobx'
import uuid from 'uuid/v4'
import { range } from 'lodash'
import { TableStore } from '../table-store'
import { SingleRowStore } from './single-row-store'
import { TableRowsConfig, RowData } from './row-definitions'

export class RowsStore {
  @observable.shallow private _rows: SingleRowStore[] = []
  @observable.shallow private _displayRows: SingleRowStore[] = []
  private _fakeRows: SingleRowStore[] = []
  private event = new Event('onrowrender')

  private _rawRowsData = ''

  constructor(
    private _rootStore: TableStore,
    rowsData: RowData = [],
    private _rowsConfig: TableRowsConfig = {}
  ) {
    this._rows = []
    this._dataToRows(rowsData)

    this._fakeRows = [...range(10)].map(() => this._fakeRow())
  }

  public update(rowsData: RowData = []): RowsStore {
    if (this._rawRowsData !== JSON.stringify(rowsData)) {
      this._dataToRows(rowsData)
    } else if (this._displayRows.length !== this._rows.length) {
      this._prepareDisplayRows()
    }

    return this
  }

  @computed get selectedRows() {
    return this.rows.filter((row) => row.isSelected).map((row) => row.rowData)
  }

  @computed get rows() {
    return this._rows
  }

  @computed get displayRows() {
    if (this._rootStore.isLoading) {
      return this._fakeRows
    }

    const loadingRows = [...range(3)].map(() => this._fakeRow())

    if (this.rows.length - this._displayRows.length > 0) {
      return [...this._displayRows, ...loadingRows]
    }

    return this._displayRows
  }

  private _prepareDisplayRows = () => {
    this._rootStore.timeoutsRegistry.clearTimeout('row-batch')
    let iteration = 1
    const batchSize = 15
    this._displayRows = this._rows.slice(0, batchSize)
    const addRowRecursive = () => {
      const offset = iteration * batchSize
      const rowsBatch = this._rows.slice(offset, offset + batchSize)
      if (rowsBatch.length > 0) {
        this._rootStore.timeoutsRegistry.setTimeout(
          () => {
            this._displayRows = rowsBatch.reduce(
              (rows: SingleRowStore[], singleRowData) => {
                return [...rows, singleRowData]
              },
              this._displayRows
            )
            document.dispatchEvent(this.event)
            iteration++
            addRowRecursive()
          },
          0,
          'row-batch'
        )
      }
    }
    addRowRecursive()
  }

  private _dataToRows(rowsData: RowData) {
    this._rawRowsData = JSON.stringify(rowsData)
    this._rows = []
    this._displayRows = []

    this._rows = rowsData.reduce((rows: SingleRowStore[], singleRowData) => {
      const previousRow = rows.length > 0 ? rows[rows.length - 1] : undefined
      return [
        ...rows,
        new SingleRowStore(
          this._rootStore,
          'data-row',
          singleRowData,
          this._rowsConfig,
          previousRow
        )
      ]
    }, [])

    this._prepareDisplayRows()
    this._rootStore.timeoutsRegistry.setTimeout(
      () => {
        document.dispatchEvent(this.event)
      },
      0,
      'onrowrender'
    )
  }

  private _fakeRow = () => {
    return new SingleRowStore(
      this._rootStore,
      'data-row',
      {
        uuid: uuid()
      },
      {},
      undefined,
      true
    )
  }
}
