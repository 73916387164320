import {
  KeyboardEvent,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { ActiveListItemRef } from '../refs/refs'
import { ValueProps, SelectData } from '../../types'

interface CustomValuesProps {
  activeListItemRef: ActiveListItemRef
  enableCustom: boolean
  handleValueChange: (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => void
  handleKeyDown: (
    event: KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => void
  selectedValue: ValueProps
  data?: SelectData[]
}

export const useCustomValues = ({
  activeListItemRef,
  enableCustom,
  handleKeyDown,
  handleValueChange,
  selectedValue,
  data = []
}: CustomValuesProps) => {
  const customValue = useMemo(
    () =>
      selectedValue && !data.find((option) => option.value === selectedValue)
        ? { label: selectedValue, value: selectedValue }
        : undefined,
    [selectedValue, data]
  )
  const [
    customPropositionValue,
    setCustomPropositionValue
  ] = useState<SelectData | null>(null)

  const addCustomValue = useCallback(
    (
      event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
      value: ValueProps
    ) => {
      const findOption = data.find(
        (option) =>
          `${option.label}`.trim().toLowerCase() === `${value}`.toLowerCase() ||
          `${option.value}`.trim().toLowerCase() === `${value}`.toLowerCase()
      )

      const valueToSet = findOption
        ? findOption.value
        : enableCustom
        ? value
        : null

      if (valueToSet !== null) {
        handleValueChange(event, valueToSet)
      }
    },
    [enableCustom, handleValueChange, data]
  )
  const keyDownCustomValue = useCallback(
    (event: KeyboardEvent<HTMLDivElement>, value: ValueProps) => {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        handleKeyDown(event, '')
      } else if (event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()

        const findOption = data.find(
          (option) =>
            String(option.label)
              .trim()
              .toLowerCase() === `${value}`.toLowerCase() ||
            `${option.value}`.trim().toLowerCase() === `${value}`.toLowerCase()
        )
        const valueToSet = findOption
          ? findOption.value
          : enableCustom
          ? value
          : null

        if (valueToSet !== null) {
          handleKeyDown(event, valueToSet)
        }
      }
    },
    [enableCustom, handleKeyDown, data]
  )

  const dataWithCustoms = useMemo(
    () => (customValue ? [customValue, ...data] : data),
    [customValue, data]
  )

  useEffect(() => {
    if (
      customPropositionValue &&
      (customValue?.value === customPropositionValue?.value ||
        selectedValue === customPropositionValue?.value)
    ) {
      setCustomPropositionValue(null)
    }
  }, [
    customValue,
    customPropositionValue,
    setCustomPropositionValue,
    selectedValue
  ])

  const setCustomProposition = useCallback(
    (value) => {
      if (!enableCustom) {
        return
      }
      const valueTrimmed = value.trim()
      if (valueTrimmed === '' || valueTrimmed === null) {
        setCustomPropositionValue(null)
      } else {
        const foundOption = data?.find(
          (option) =>
            String(option.label).toLowerCase() === valueTrimmed.toLowerCase() ||
            (option.value as string).toLowerCase() ===
              valueTrimmed.toLowerCase()
        )
        if (foundOption) {
          setCustomPropositionValue(null)
        } else {
          setCustomPropositionValue({
            label: valueTrimmed,
            value: valueTrimmed
          })
        }
      }
    },
    [data, enableCustom]
  )

  return {
    dataWithCustoms,
    addCustomValue,
    keyDownCustomValue,
    setCustomProposition,
    customProposition: customPropositionValue
  }
}
