import { Value } from 'classnames'
import { FunctionComponent } from 'react'
import { FormGroup } from '.'
import {
  widgets,
  classicSelectFields,
  textareaFields,
  noPrefillFields
} from '../config'

import { Attribute, Attributes, FieldProps, PSSFormik } from '../types'
import { FormFieldMapper } from './form-field-mapper'
import { checkIfRequired } from './form-validation'
export interface MappedField {
  component: FunctionComponent
  props: FieldProps
  ui: {
    label: string
    takeAvailableSpace: boolean
  }
}
export class FormFields {
  private fieldMapper: FormFieldMapper
  private takeAvailableSpaceTypes: string[] = ['file']
  private takeAvailableSpaceFields: string[] = [
    ...classicSelectFields,
    ...textareaFields
  ]

  constructor() {
    this.fieldMapper = new FormFieldMapper()
  }

  removeImproperValues = ({
    fields,
    formik,
    ...args
  }: {
    fields: Attributes
    formik: PSSFormik
    [key: string]: unknown
  }) => {
    const fieldKeys = Object.keys(fields)
    for (let i = 0; i < fieldKeys.length; i++) {
      const fieldKey = fieldKeys[i]
      const field: Attribute = fields[fieldKey]
      const value: Value = formik.values[fieldKey] as Value
      if (value) {
        if (field.value_type === 'boolean' && typeof value !== 'boolean') {
          formik.setFieldValue(fieldKey, undefined)
        } else if (field.value_type === 'number' && Number.isNaN(value)) {
          formik.setFieldValue(fieldKey, undefined)
        } else if (field.value_type === 'multiselect') {
          const selectValues =
            field.options?.map((option) => option.value) || []

          const noExistantValue =
            Array.isArray(value) && value.find((v) => !selectValues.includes(v))

          if (
            !Array.isArray(value) ||
            (noExistantValue && field.enable_custom_value === false)
          ) {
            formik.setFieldValue(fieldKey, undefined)
          }
        } else if (field.value_type === 'select') {
          const selectValues =
            field.options?.map((option) => option.value) || []
          if (
            !selectValues.includes(value as string) &&
            field.enable_custom_value === false
          ) {
            formik.setFieldValue(fieldKey, undefined)
          }
        }
      }
    }

    return { fields, formik, ...args }
  }

  setInitials = ({
    fields,
    formik,
    prefillRequired,
    ...args
  }: {
    fields: Attributes
    formik: PSSFormik
    prefillRequired: boolean
    [key: string]: unknown
  }) => {
    /**
     * we're setting only one field at a time
     * so that constraints are applied properly
     */
    if (prefillRequired === false) {
      return { fields, formik, prefillRequired, ...args }
    }

    const fieldKeys = Object.keys(fields)
    for (let i = 0; i < fieldKeys.length; i++) {
      const fieldKey = fieldKeys[i]
      const field = fields[fieldKey]

      if (
        formik.touched[fieldKey] ||
        formik.values[fieldKey] !== undefined ||
        (noPrefillFields.includes(fieldKey) &&
          (!field.options || field.options?.length > 1))
      ) {
        continue
      }
      if (field.default_value) {
        formik.setFieldValue(fieldKey, field.default_value)
        return { fields, formik, ...args }
      }
      if (field.value_type === 'boolean') {
        formik.setFieldValue(fieldKey, false)
        return { fields, formik, ...args }
      }
      if (!checkIfRequired(field)) {
        continue
      }
      if (
        field.value_type === 'select' &&
        field.options &&
        field.options.length > 0
      ) {
        let valueToSet = field.options[0].value
        if (
          fieldKey === 'type__name' &&
          field.options.find((o) => o.value === 'corrugated')
        ) {
          valueToSet = 'corrugated'
        }

        formik.setFieldValue(fieldKey, valueToSet)
        return { fields, formik, ...args }
      }
    }

    return { fields, formik, prefillRequired, ...args }
  }

  createFields = ({
    groups,
    fields,
    formik,
    disabledFields,
    readonly = false,
    ...args
  }: {
    groups: FormGroup[]
    fields: Attributes
    formik: PSSFormik
    disabledFields: string[]
    readonly?: boolean
    [key: string]: unknown
  }) => {
    const groupsMapped = groups.map((group: FormGroup) => ({
      ...group,
      rows: group.rows.map((row: string[]) =>
        row.reduce(
          (mappedRow: MappedField[], fieldName: string) => [
            ...mappedRow,
            this.mapField(fieldName, fields, formik, disabledFields, readonly)
          ],
          []
        )
      )
    }))
    return {
      groups: groupsMapped,
      fields,
      formik,
      ...args
    }
  }

  private mapField = (
    fieldName: string,
    fields: Attributes,
    formik: PSSFormik,
    disabledFields: string[],
    readonly: boolean
  ): MappedField => {
    const fieldConfig = fields[fieldName]
    const unit = fieldConfig.default_unit
      ? ` (${fieldConfig.default_unit})`
      : ''

    let component = widgets[fieldConfig.value_type]
    if (classicSelectFields.includes(fieldName)) {
      component = widgets['classicSelect']
    } else if (textareaFields.includes(fieldName)) {
      component = widgets['textarea']
    }
    return {
      component,
      props: this.fieldMapper.map(
        fieldName,
        fieldConfig,
        formik,
        disabledFields,
        readonly
      ),
      ui: {
        label: `${fieldConfig.label}${unit}`,
        takeAvailableSpace:
          this.takeAvailableSpaceTypes.includes(fieldConfig.value_type) ||
          this.takeAvailableSpaceFields.includes(fieldName)
      }
    }
  }
}
