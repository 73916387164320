import { useContext, useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { ConfigService } from '@packhelp/platform-pss-api'
import { CacheService } from '@packhelp/platform-services'
import { RootContext } from '../../contexts'
import {
  PSSFormConfigSetup,
  PSSFormConfigSetupWithObject,
  PSSFormConfigSetupWithUrl
} from '../../types/form-configuration'
import { ProductSpecification, ProductSpecificationConfig } from '../../types'

export const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_CONTEXT = 'default'

export type UseConfigProps = {
  specification?: ProductSpecification
} & PSSFormConfigSetup

interface UseConfigReturnType {
  isLoading: boolean
  config: ProductSpecificationConfig
}

export const useConfig = ({
  specification,
  configSetup
}: UseConfigProps): UseConfigReturnType => {
  const {
    version: configVersion,
    context = DEFAULT_CONTEXT,
    url: configUrl,
    language = DEFAULT_LANGUAGE,
    subset: {
      name: passedSubsetName = undefined,
      version: passedSubsetVersion = undefined
    } = {}
  } = configSetup as PSSFormConfigSetupWithUrl

  const { callbacks } = useContext(RootContext)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [config, setConfig] = useState<ProductSpecificationConfig>(
    (configSetup as PSSFormConfigSetupWithObject).config
  )

  const [version, setVersion] = useState<undefined | string>(
    configVersion || specification?.metadata?.configuration_version
  )

  /**
   * for now - only passed subset can be used - do not take one written in specification
   */

  const [subsetName, setSubsetName] = useState<undefined | string>(
    passedSubsetName
  )

  const [subsetVersion, setSubsetVersion] = useState<undefined | string>(
    passedSubsetVersion
  )
  const {
    config: getConfig,
    versions: listVersions,
    subsetVersions: listSubsetVersions
  } = useMemo(() => new ConfigService(configUrl), [configUrl])
  const cacheService = useMemo(() => new CacheService({ maxAge: '1w' }), [])
  const cacheKey = useMemo(
    () =>
      `config${version ? `_${version}` : ''}${
        subsetName ? `_${subsetName}` : ''
      }${subsetVersion ? `_${subsetVersion}` : ''}${
        language ? `_${language}` : ''
      }${context ? `_${context}` : ''}`,
    [version, language, context, subsetName, subsetVersion]
  )

  useEffect(() => {
    setVersion(configVersion || specification?.metadata?.configuration_version)
  }, [configVersion, specification])

  useEffect(() => {
    setSubsetName(passedSubsetName)
  }, [passedSubsetName])

  useEffect(() => {
    setSubsetVersion(passedSubsetVersion)
  }, [passedSubsetVersion])

  useEffect(() => {
    let mounted = true

    if ((configSetup as PSSFormConfigSetupWithObject).config) {
      return
    }

    ;(async () => {
      setIsLoading(true)

      try {
        if (!version) {
          try {
            const versions = await listVersions()
            setVersion(versions[0])
            return
          } catch (err) {
            console.error(err)
          }
        }
        if (subsetName && !subsetVersion) {
          try {
            const subsetVersions = await listSubsetVersions(subsetName, version)
            setSubsetVersion(subsetVersions[0])
            return
          } catch (err) {
            console.error(err)
          }
        }

        const cachedConfig =
          version &&
          (await cacheService.read<ProductSpecificationConfig>(cacheKey))

        if (cachedConfig) {
          if (mounted) {
            setConfig(cachedConfig)
          }
        } else {
          const config = await getConfig({
            version,
            language,
            context,
            subsetName,
            subsetVersion
          })

          if (mounted) {
            cacheService.write(cacheKey, config)

            setConfig(config)
          }
        }
      } catch (error) {
        if (callbacks?.onError) {
          callbacks?.onError(error)
        } else {
          console.error('error :>> ', error)
        }
        Sentry.captureException(error)
      } finally {
        if (mounted) {
          setIsLoading(false)
        }
      }
    })()
    return () => {
      mounted = false
    }
  }, [
    JSON.stringify(configSetup),
    cacheKey,
    version,
    language,
    context,
    subsetName,
    subsetVersion
  ])

  return {
    isLoading,
    config
  }
}
