import React, { useMemo } from 'react'
import cxBinder from 'classnames/bind'
import { SortableElement } from 'react-sortable-hoc'
import { SortableItemProps } from '../common'
import { Switch } from '../../../atoms'
import { updateColumnVisibility } from '../helpers/update-columns-visibility'
import styles from '../ColumnsPopup.module.scss'
import { DragHandle } from './DragHandle'

const cx = cxBinder.bind(styles)

export const SortableItem = SortableElement((props: SortableItemProps) => {
  const { config, onChange, item } = props

  const columnConfig = useMemo(
    () => config.find(({ column }) => column === item),
    [config, item]
  )
  if (!columnConfig) return null

  const { label, isVisible, column } = columnConfig

  return (
    <div className={cx('row')} key={column}>
      <div className={cx('row__wrapper')}>
        <DragHandle />
        <label
          htmlFor={column}
          className={cx('row__wrapper__label')}
          e2e-target="column"
          e2e-target-name={column}
        >
          {label}
        </label>
      </div>
      <Switch
        id={column}
        name={column}
        checked={isVisible}
        onChange={(isVisible: boolean) => {
          onChange(updateColumnVisibility(config, column, isVisible))
        }}
        e2e-target="switch"
        e2e-target-name={column}
      />
    </div>
  )
})
