import React, { FC, ReactPortal, ReactElement } from 'react'
import { createPortal } from 'react-dom'
import cxBinder from 'classnames/bind'
import styles from './Drawer.module.scss'
import { DrawerProps } from './types'

const cx = cxBinder.bind(styles)

const DrawerPortal = ({
  bindTo,
  children
}: Pick<DrawerProps, 'bindTo' | 'children'>): ReactPortal | ReactElement => {
  if (bindTo) {
    return createPortal(children, bindTo)
  }
  return <>{children}</>
}

export const Drawer: FC<DrawerProps> = ({ isOpen, bindTo, children, size }) => {
  return (
    <DrawerPortal bindTo={bindTo}>
      <div
        className={cx('wrapper', {
          'wrapper--open': isOpen,
          'wrapper--fixed': !bindTo,
          [`wrapper__width--${size}`]: size
        })}
      >
        <div className={styles.content}>{children}</div>
      </div>
    </DrawerPortal>
  )
}
