import React, { ReactNode, RefObject } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import cxBinder from 'classnames/bind'

import styles from './Dropzone.module.scss'
import { DropzoneContent } from './DropzoneContent'
import { FileList } from './FileList'

const cx = cxBinder.bind(styles)

export type FileProps = {
  name: string
  size: number
  url?: string
  id: string | number
  isLoading?: boolean
  mimeType: string
}

export type RemoveFile = (file: FileProps) => void

export type DropzoneLabelProps = {
  accept?: ReactNode | string
  reject?: ReactNode | string
  content: ReactNode | string
  sizeError?: ReactNode | string
}

export interface DropzoneProps extends DropzoneOptions {
  innerRef?: RefObject<HTMLDivElement>
  labels: DropzoneLabelProps
  error?: boolean
  e2eTargetName?: string
  isLoading?: boolean
  files?: FileProps[]
  onRemove?: RemoveFile
  showFileList?: boolean
}

const Dropzone = (props: DropzoneProps) => {
  const {
    accept,
    error,
    e2eTargetName,
    isLoading = false,
    labels,
    maxSize = 5242880,
    minSize = 0,
    multiple = false,
    onDrop,
    disabled,
    onRemove,
    showFileList,
    files = []
  } = props

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: onDrop,
    disabled: isLoading || disabled,
    accept,
    minSize,
    maxSize,
    multiple
  })

  return (
    <>
      <div
        className={cx('dropzone', {
          'dropzone--accept': isDragAccept,
          'dropzone--error': isDragReject || error,
          'dropzone--disabled': disabled
        })}
        {...getRootProps()}
        e2e-target="dropzone"
        e2e-target-name={e2eTargetName}
        ref={props.innerRef}
      >
        <DropzoneContent
          isLoading={isLoading}
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
          labels={labels}
          disabled={disabled}
        />
        <input {...getInputProps()} e2e-target="input" e2e-target-name="file" />
      </div>
      <FileList
        files={files}
        onRemove={onRemove}
        maxSize={maxSize}
        minSize={minSize}
        labels={labels}
        showFileList={showFileList}
        disabled={disabled}
      />
    </>
  )
}

export { Dropzone, Dropzone as default }
