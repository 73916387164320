import { observable, computed, action, reaction } from 'mobx'
import { remove } from 'lodash'
import { TableStore } from './table-store'
import { SingleRowStore } from './rows'

export type BulkSelection = number[] & string[]

export type BulkSelectionFunction = (
  idList: BulkSelection,
  selectedRows?: SingleRowStore[],
  clearBulk?: () => void
) => void

export class BulkStore<T> {
  @observable public selection: BulkSelection = []
  private lastSelection?: number | string
  @observable private _onBulkFunction?: BulkSelectionFunction

  private _disposer

  constructor(
    private _rootStore: TableStore,
    onBulkFunction?: BulkSelectionFunction
  ) {
    this._onBulkFunction = onBulkFunction
    this._disposer = reaction(
      () => this.selection.join(', '),
      () => {
        this._onBulkFunction &&
          this._onBulkFunction(
            this.selection,
            this._rootStore.rows.selectedRows,
            this.clearBulk
          )
      }
    )
  }

  public update(onBulkFunction?: BulkSelectionFunction) {
    this._onBulkFunction = onBulkFunction
  }

  @computed get isEnabled() {
    return !this._rootStore.isLoading && !!this._onBulkFunction
  }

  @computed get areRowsSelected() {
    return this.isEnabled && this.selection.length > 0
  }

  @computed get isSelected() {
    return this._rootStore.rows.rows.reduce(
      (selections: { [key: string]: boolean }, row) => {
        return {
          ...selections,
          [row.id]: this.selection.includes(row.id)
        }
      },
      {}
    )
  }

  @computed get isIntermediate() {
    return (
      this.isEnabled &&
      this.selection.length > 0 &&
      this.selection.length !== this._rootStore.rows.rows.length
    )
  }

  @action.bound clearBulk() {
    this.selection.length = 0
  }

  @action.bound toggleSelection(id: string, withShift = false) {
    if (this.isEnabled) {
      if (this.selection.includes(id)) {
        remove(this.selection, (selection) => selection === id)
      } else {
        this.selection.push(id)
      }

      if (withShift && this.lastSelection) {
        const currentSelectionRowIndex = this._rootStore.rows.rows.findIndex(
          (row) => row.id === id
        )
        if (currentSelectionRowIndex && currentSelectionRowIndex > -1) {
          const lastSelectionRowIndex = this._rootStore.rows.rows.findIndex(
            (row) => row.id === this.lastSelection
          )

          const minIndex = Math.min(
            currentSelectionRowIndex,
            lastSelectionRowIndex
          )

          const maxIndex = Math.max(
            currentSelectionRowIndex,
            lastSelectionRowIndex
          )

          this._rootStore.rows.rows.forEach((row, index) => {
            if (minIndex <= index && index <= maxIndex) {
              const shouldAdd = this.selection.includes(id)
              if (shouldAdd) {
                if (!this.selection.includes(row.id)) {
                  this.selection.push(row.id)
                }
              } else {
                remove(this.selection, (selection) => selection === row.id)
              }
            }
          })
        }
      }

      this.lastSelection = id
    }
  }

  @action.bound toggleAllSelection() {
    if (this.isEnabled) {
      if (this.selection.length > 0) {
        this.deselectAll()
      } else {
        this.selectAll()
      }
    }
  }

  @action.bound selectAll() {
    if (this.isEnabled) {
      this.selection = this._rootStore.rows.rows.reduce(
        (selections: BulkSelection, row) => {
          return [...selections, row.id]
        },
        []
      )
    }
  }

  @action.bound deselectAll() {
    if (this.isEnabled) {
      this.clearBulk()
    }
  }
}
