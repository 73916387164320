import React, { FC, memo } from 'react'
import cxBinder from 'classnames/bind'
import style from '../../Table.module.scss'
import CellBase from './CellBase'
import CellContent from './CellContent'
import { ColumnAlign } from './../../stores/columns/single-column-store'

const cx = cxBinder.bind(style)

export const LoadingCell: FC = memo(() => {
  return (
    <CellBase e2e-target="loading">
      <div className={cx('table__row-cell')}>
        <CellContent
          align={ColumnAlign.center}
          e2e-target="cell-content-loading"
        >
          <div className={cx('table__row-cell--loading')} />
        </CellContent>
      </div>
    </CellBase>
  )
})

export default LoadingCell
