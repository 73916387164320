import React, { FC } from 'react'

import cxBinder from 'classnames/bind'

import { Popup, PopupProps } from '../../atoms/'
import Button, { ButtonSize, ButtonMode } from '../../atoms/Button/Button'
import style from './ConfirmPopup.module.scss'

const cx = cxBinder.bind(style)

interface ConfirmPopupProps extends PopupProps {
  buttonTextConfirm: string
  buttonTextAbort?: string
  buttonMode?: ButtonMode
  onConfirm: () => any
  onAbort: () => any
  e2eTarget?: string
  e2eTargetName?: string
}

const ConfirmPopup: FC<ConfirmPopupProps> = ({
  buttonTextConfirm,
  children,
  buttonTextAbort = 'Cancel',
  buttonMode = ButtonMode.destructive,
  onConfirm,
  onAbort,
  placement = 'bottom-start',
  width = '224px',
  e2eTarget = 'popup',
  e2eTargetName = 'confirm',
  ...props
}) => {
  const actionButton = (
    <>
      <div className={cx('button-item')}>
        <Button
          size={ButtonSize.large}
          mode={ButtonMode.secondary}
          onClick={onAbort}
          e2e-target="button"
          e2e-target-name="abort"
        >
          {buttonTextAbort}
        </Button>
      </div>
      <Button
        size={ButtonSize.large}
        mode={buttonMode}
        onClick={onConfirm}
        e2e-target="button"
        e2e-target-name="confirm"
      >
        {buttonTextConfirm}
      </Button>
    </>
  )

  return (
    <Popup
      footer={actionButton}
      placement={placement}
      width={width}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...props}
    >
      {children}
    </Popup>
  )
}

export { ConfirmPopup, ConfirmPopup as default }
