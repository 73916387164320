import { ReactNode } from 'react'
import { Modifier, usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'

export enum PopupSizeProp {
  auto = 'auto',
  s = 's',
  m = 'm',
  l = 'l'
}

export type Popper = ReturnType<typeof usePopper> | null

export type PopperRef = React.MutableRefObject<Popper>

export type Modifiers = Modifier<string>[]

export interface PopupContentProps {
  children?: ReactNode
  footer?: ReactNode
  header?: ReactNode
}

export interface PopperPlacementProps {
  placement?: Placement
}

export interface PopperStyleProps extends PopperPlacementProps {
  noPadding?: boolean
}

export interface PopupWrapperStyleProps {
  popupSize?: PopupSizeProp
}

export interface PopperModifiersProps {
  modifiers?: Modifiers
  parentWidth?: boolean
  width?: string
}

export interface PopperCommonProps
  extends PopperModifiersProps,
    PopperPlacementProps {}

export interface PopperProps extends PopperCommonProps {
  anchorEl: HTMLElement | null
  popperRef: PopperRef
}

export interface PopupCommonProps {
  anchorEl?: HTMLElement | null
  portalBindTo?: HTMLElement | null
  onClickOutside?: () => void
}

export interface UsePopupElementsProps extends PopupCommonProps, PopperProps {
  anchorEl: HTMLElement | null
}

export interface PopupProps
  extends PopupContentProps,
    Pick<PopperStyleProps, 'noPadding'>,
    PopupWrapperStyleProps,
    PopupCommonProps,
    PopperCommonProps {
  fixPosition?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

export interface _PopupProps extends PopupProps, PopperProps {
  anchorEl: HTMLElement | null
}

export type PopupContextValue =
  | { ref: React.MutableRefObject<HTMLDivElement | null> }
  | undefined
