import React, { ReactNode } from 'react'
import cxBinder from 'classnames/bind'

import styles from './Pill.module.scss'

const cx = cxBinder.bind(styles)

export enum RoundedPillType {
  primary = 'primary',
  secondary = 'secondary',
  destructive = 'destructive',
  secondaryNoHover = 'secondary__no-hover'
}

export enum RoundedPillSize {
  large = 'large',
  small = 'small'
}

export enum SquaredPillType {
  dark = 'dark',
  light = 'light'
}

export enum PillMode {
  rounded = 'rounded',
  squared = 'squared'
}

const PillType = {
  ...RoundedPillType,
  ...SquaredPillType
}

type PillType = RoundedPillType | SquaredPillType

interface PillBaseProps {
  size?: RoundedPillSize
  type: PillType
  mode: PillMode
  children: ReactNode
  e2eTarget?: string
  e2eTargetName?: string
}

const Pill = (props: PillBaseProps) => {
  const { children, size, type, mode, e2eTarget, e2eTargetName } = props

  return (
    <div
      className={cx(
        'pill',
        {
          [`pill--${mode}`]: mode,
          [`pill--${type}`]: type,
          [`pill--${size}`]: size
        },
        type === PillType.secondaryNoHover && 'pill--secondary'
      )}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div className={cx('pill__text')}>{children}</div>
    </div>
  )
}

export { Pill, Pill as default }
