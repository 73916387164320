import React from 'react'
import cxBinder from 'classnames/bind'
import { Icon, IconSize, Loading, LoadingSize, TIcon } from '../../atoms'

import { DropzoneLabelProps } from './Dropzone'
import styles from './DropzoneContent.module.scss'

const cx = cxBinder.bind(styles)

interface DropzoneContentProps {
  isLoading?: boolean
  isDragAccept?: boolean
  isDragReject?: boolean
  labels: DropzoneLabelProps
  disabled?: boolean
}

export const DropzoneContent = (props: DropzoneContentProps) => {
  const { isLoading, isDragAccept, isDragReject, labels, disabled } = props

  if (isLoading) {
    return <Loading size={LoadingSize.m} />
  }
  if (isDragAccept) {
    return <>{labels.accept}</>
  }
  if (isDragReject) {
    return <>{labels.reject}</>
  }

  return (
    <span className={styles.content}>
      <span
        className={cx('content__icon', {
          'content__icon--disabled': disabled
        })}
      >
        <Icon icon={TIcon.UploadFile} size={IconSize.m} />
      </span>
      <span
        className={cx('content__text', {
          'content__text--disabled': disabled
        })}
      >
        {labels.content}
      </span>
    </span>
  )
}
