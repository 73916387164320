import { useCallback, useEffect } from 'react'
import { PSSFormik } from '../../../../types'

export enum ProductFormEvents {
  submitForm = 'product_spec_form:formik.submitForm',
  validateForm = 'product_spec_form:formik.validateForm'
}

export const useFormEvents = (formik: PSSFormik) => {
  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  useEffect(() => {
    window.addEventListener(ProductFormEvents.submitForm, submitForm)
    return () => {
      window.removeEventListener(ProductFormEvents.submitForm, submitForm)
    }
  }, [])

  const validateForm = useCallback(() => {
    formik.validateForm()
  }, [formik])

  useEffect(() => {
    window.addEventListener(ProductFormEvents.validateForm, validateForm)
    return () => {
      window.removeEventListener(ProductFormEvents.validateForm, validateForm)
    }
  }, [])
}

export const productSpecEvents = {
  submitForm: () => {
    window.dispatchEvent(new CustomEvent(ProductFormEvents.submitForm))
  },
  validateForm: () => {
    window.dispatchEvent(new CustomEvent(ProductFormEvents.validateForm))
  }
}
