import React from 'react'
import cxBinder from 'classnames/bind'
import { Button } from '@packhelp/platform-dsl/atoms'
import { Preview } from '../preview/Preview'
import { RowsGroup, RowsGroupProps } from '../rows-group/RowsGroup'
import { useProductSpecForm } from '../../hooks/product-spec-form/use-product-spec-form'
import { FormContext } from '../../contexts/form'
import { PSSFormPropsWithConfigWithForwardRef } from '../../types'

import styles from './ProductSpecificationForm.module.scss'

const cx = cxBinder.bind(styles)

export const ProductSpecificationForm = ({
  labels,
  fRef,
  ...otherProps
}: PSSFormPropsWithConfigWithForwardRef) => {
  const {
    breakpointRef,
    breakpointReached,
    onSubmit,
    groups,
    submitEnabled,
    setValue,
    isSubmitting,
    currentAttributes,
    typeName
  } = useProductSpecForm(otherProps, fRef)

  return (
    <FormContext.Provider value={{ setValue, typeName }}>
      <form ref={breakpointRef} onSubmit={onSubmit} className={styles.form}>
        <div
          className={cx('groups-wrapper', {
            'groups-wrapper--grid': !breakpointReached
          })}
        >
          {groups.map((group: RowsGroupProps, index: number) => {
            if (index === 0) {
              return (
                <div
                  key={group.name}
                  className={cx('group--with-preview', {
                    'group--with-preview--grid': !breakpointReached
                  })}
                >
                  <Preview attributes={currentAttributes} />
                  <RowsGroup {...group} />
                </div>
              )
            }
            return <RowsGroup key={group.name} {...group} />
          })}
        </div>
        {onSubmit && (
          <div className={styles['submit']}>
            <Button
              type="submit"
              disabled={!submitEnabled}
              isLoading={isSubmitting}
            >
              {labels?.submit || 'Submit form'}
            </Button>
          </div>
        )}
      </form>
    </FormContext.Provider>
  )
}
