import React, { FC, useState, useMemo, useEffect } from 'react'
import { autorun } from 'mobx'
import cxBinder from 'classnames/bind'
import { kebabCase } from 'lodash'
import { useRowContext } from '../../hooks/row-store'
import { useTableContext } from '../../hooks/table-store'
import style from '../../Table.module.scss'
import CellBase from './CellBase'
import CellContent from './CellContent'
import { LoadingCell } from './LoadingCell'
import CellTextWrapper from './CellTextWrapper'

const cx = cxBinder.bind(style)

const useRowCellData = (configUUID) => {
  const table = useTableContext()
  const config = table.columns.configsObject[configUUID]
  const rowStore = useRowContext()

  const [cellData, setCellData] = useState({
    isSorted: rowStore.cells[configUUID] && rowStore.cells[configUUID].isSorted,
    isDimmed: rowStore.cells[configUUID] && rowStore.cells[configUUID].isDimmed,
    align: config.align,
    isHighlighted: config.highlight,
    paddingType: config.paddingType,
    isTableLoading: table.isLoading || rowStore.isFake,
    headline: rowStore.cells[configUUID] && rowStore.cells[configUUID].headline,
    shouldUseCellWrapper:
      rowStore.cells[configUUID] &&
      rowStore.cells[configUUID].shouldUseCellWrapper,
    cellValue: rowStore.cells[configUUID] && rowStore.cells[configUUID].value,
    isMark: config.isMark,
    widthStyle: config.widthStyle
  })

  useEffect(() => {
    let mounted = true
    let disposer = autorun(() => {
      if (mounted) {
        setCellData({
          isSorted:
            rowStore.cells[configUUID] && rowStore.cells[configUUID].isSorted,
          isDimmed:
            rowStore.cells[configUUID] && rowStore.cells[configUUID].isDimmed,
          align: config.align,
          paddingType: config.paddingType,
          isHighlighted: config.highlight,
          isTableLoading: table.isLoading || rowStore.isFake,
          headline:
            rowStore.cells[configUUID] && rowStore.cells[configUUID].headline,
          shouldUseCellWrapper:
            rowStore.cells[configUUID] &&
            rowStore.cells[configUUID].shouldUseCellWrapper,
          cellValue:
            rowStore.cells[configUUID] && rowStore.cells[configUUID].value,
          isMark: config.isMark,
          widthStyle: config.widthStyle
        })
      }
    })
    return () => {
      disposer()
      mounted = false
    }
  }, [configUUID])

  return cellData
}

export const RowCell: FC<{
  configUUID: string
}> = ({ configUUID }) => {
  const cell = useRowCellData(configUUID)

  const classes = useMemo(
    () =>
      cx('table__row-cell', `table__row-cell--${cell.paddingType}`, {
        'table__row-cell--highlight': cell.isHighlighted
      }),
    [cell.isHighlighted, cell.paddingType]
  )
  const configName = kebabCase(configUUID)

  if (cell.isTableLoading) return <LoadingCell />

  const value = cell.shouldUseCellWrapper ? (
    <CellTextWrapper headline={cell.headline}>{cell.cellValue}</CellTextWrapper>
  ) : (
    cell.cellValue
  )

  return (
    <CellBase
      isSorted={cell.isSorted}
      widthStyle={cell.widthStyle}
      e2e-target="cell-row"
      e2e-target-name={configName}
    >
      <div className={classes} data-mark={cell.isMark}>
        <CellContent
          align={cell.align}
          e2e-target="cell-row"
          e2e-target-name={configName}
        >
          {value}
        </CellContent>
      </div>
    </CellBase>
  )
}
