import { ColumnsPopupConfig } from '../common'

export const updateColumnVisibility = (
  config: ColumnsPopupConfig[],
  column: string,
  isVisible: boolean
): ColumnsPopupConfig[] =>
  config.map((popupColumn) =>
    popupColumn.column === column
      ? {
          ...popupColumn,
          isVisible
        }
      : {
          ...popupColumn
        }
  )
