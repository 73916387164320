import { pipe } from 'lodash/fp'
import {
  Attributes,
  ProductSpecificationAttributes,
  ProductSpecificationConfig,
  PSSFormik
} from '../types'
import { FormConstraintsHandler } from './form-constraints-handler'
import { FormFields } from './form-fields'
import { FormGroupsHandler } from './form-groups-handler'
import { FormRowsHandler } from './form-rows-handler'

export interface FormBuilderParams {
  config: ProductSpecificationConfig
  formik: PSSFormik
  disabledFields: string[]
  readonly?: boolean
  prefillRequired?: boolean
}

export interface FormBuilderParamsWithFields extends FormBuilderParams {
  fields: Attributes
}

export interface FormBuilderParamsWithValues
  extends FormBuilderParamsWithFields {
  formValues: ProductSpecificationAttributes
}

export class FormBuilder {
  private params: FormBuilderParams
  private formFields: FormFields
  private constraintHandler: FormConstraintsHandler
  private groupsHandler: FormGroupsHandler
  private rowsHandler: FormRowsHandler

  constructor(
    config: ProductSpecificationConfig,
    formik: PSSFormik,
    disabledFields: string[],
    prefillRequired = true,
    readonly = false
  ) {
    this.params = {
      formik,
      config,
      disabledFields,
      readonly,
      prefillRequired
    }
    this.formFields = new FormFields()
    this.constraintHandler = new FormConstraintsHandler()
    this.groupsHandler = new FormGroupsHandler()
    this.rowsHandler = new FormRowsHandler()
  }

  get groups() {
    const { groups } = pipe(
      this.constraintHandler.apply,
      this.constraintHandler.clearUnused,
      this.formFields.removeImproperValues,
      this.formFields.setInitials,
      this.constraintHandler.updateFormikValues,
      this.groupsHandler.array,
      this.groupsHandler.sort,
      this.rowsHandler.array,
      this.rowsHandler.filterRowValues,
      this.rowsHandler.addMissingRows,
      this.rowsHandler.array,
      this.rowsHandler.clean,
      this.groupsHandler.clean,
      this.formFields.createFields
    )(this.params)
    return groups
  }

  get fields() {
    const { fields } = pipe(this.constraintHandler.apply)(this.params)
    return fields
  }
}
