import React, { KeyboardEvent, MouseEvent, ReactNode } from 'react'
import cxBinder from 'classnames/bind'
import { Icon, Loading, LoadingSize, TIcon } from '../../../../atoms'
import { HandleRef } from '../../hooks/refs/refs'
import { SelectOption } from '../select-option/SelectOption'
import { SelectData, ValueProps } from '../../types'
import styles from './SelectList.module.scss'

const cx = cxBinder.bind(styles)

interface SelectListProps {
  data?: SelectData[]
  selectedValue?: ValueProps
  isLoading?: boolean
  onClick: (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => void
  onKeyDown: (event: KeyboardEvent<HTMLDivElement>, value: ValueProps) => void
  refFn: HandleRef
  optionRenderer?: (label: ReactNode, value: ValueProps) => ReactNode
  customValue?: SelectData | null
  onCustomKeyDown: (
    event: KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => void
  onCustomValueClick?: (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => void
}

export const SelectList = ({
  data,
  onClick,
  onKeyDown,
  optionRenderer,
  refFn,
  selectedValue,
  customValue,
  onCustomKeyDown,
  onCustomValueClick,
  isLoading
}: SelectListProps) => {
  if (!data) {
    return null
  }

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Loading size={LoadingSize.l} />
      </div>
    )
  }

  return (
    <div className={cx('select-list')} e2e-target="select-list-options">
      {customValue && (
        <SelectOption
          value={customValue.value}
          label={
            <div className={cx('custom-label')}>
              <Icon icon={TIcon.Plus} />
              {customValue.label}
            </div>
          }
          key={'custom-value'}
          onKeyDown={onCustomKeyDown}
          onClick={onCustomValueClick}
          optionRenderer={optionRenderer}
          refObj={refFn(customValue.value, 0)}
        />
      )}
      {data.map(({ value, label }, index) => (
        <SelectOption
          refObj={refFn(value, customValue ? index + 1 : index)}
          value={value}
          label={label}
          key={index}
          onKeyDown={onKeyDown}
          onClick={onClick}
          selected={selectedValue === value}
          optionRenderer={optionRenderer}
        />
      ))}
    </div>
  )
}
