import React, { FC, memo } from 'react'
import { useObserver } from 'mobx-react-lite'
import { useTableContext } from '../hooks/table-store'
import { RowProvider } from '../hooks/row-store'
import { Row } from './Row'
import { Group } from './Group'

export const Body: FC = memo(() => {
  const table = useTableContext()
  return useObserver(() => (
    <>
      {table.rows.displayRows.map((singleRow) => {
        return (
          <RowProvider key={singleRow.key} value={singleRow}>
            <Group />
            <Row />
          </RowProvider>
        )
      })}
    </>
  ))
})

export default Body
