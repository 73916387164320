import React, { useEffect, useMemo, useState, FocusEventHandler } from 'react'
import cxBinder from 'classnames/bind'
import uuid from 'uuid/v4'

import styles from './ToggleSelect.module.scss'
import { CustomOption } from './CustomOption'

const cx = cxBinder.bind(styles)

export type ToggleSelectData<T> = {
  label: string
  value: T
}

export enum MinWidth {
  s = 's',
  m = 'm'
}

export interface ToggleSelectProps<T> {
  data: ToggleSelectData<T>[]
  value?: T
  error?: boolean
  customOption?: boolean
  customOptionLabel?: string
  onChange: (value: T) => void
  onBlur?: FocusEventHandler<HTMLButtonElement>
  disabled?: boolean
  name?: string
  e2eTarget?: string
  minWidth?: MinWidth
}

const ToggleSelect = <T extends unknown>(props: ToggleSelectProps<T>) => {
  const {
    data,
    value,
    error,
    customOption,
    onChange,
    onBlur,
    customOptionLabel,
    disabled,
    name,
    e2eTarget = 'toggle-select',
    minWidth = MinWidth.m
  } = props

  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const salt = useMemo(() => uuid(), [JSON.stringify(data)])

  const customOptionValue = useMemo(() => {
    if (!selectedValue) {
      return undefined
    }
    if (data.map((item) => item.value).includes(selectedValue)) {
      return undefined
    }

    return selectedValue
  }, [selectedValue, data])

  const handleChange = (value: T) => {
    if (!disabled) {
      setSelectedValue(value)
      onChange(value)
    }
  }

  const isSelected = (value?: T) => {
    if (value === undefined) {
      return false
    }
    return value === selectedValue
  }

  return (
    <div
      className={cx('wrapper', { error, empty: selectedValue === undefined })}
      key={salt}
      e2e-target={e2eTarget}
    >
      {data.map((item, index) => (
        <button
          key={`${item.value as string}-${index}`}
          disabled={disabled}
          type="button"
          onClick={() => handleChange(item.value)}
          onBlur={onBlur}
          name={name}
          className={cx(
            'wrapper__item',
            `wrapper__item--min-width-${minWidth}`,
            {
              'wrapper__item--selected': isSelected(item.value),
              'wrapper__item--disabled': disabled,
              'wrapper__item--disabled-selected':
                disabled && isSelected(item.value)
            }
          )}
          e2e-target="toggle-quantity"
          e2e-data={item.label}
        >
          {item.label}
        </button>
      ))}
      <CustomOption
        key={`custom-option-${salt}`}
        salt={salt}
        customOption={customOption}
        handleChange={handleChange}
        isSelected={isSelected}
        value={customOptionValue}
        customOptionLabel={customOptionLabel}
        disabled={disabled}
      />
    </div>
  )
}

export { ToggleSelect, ToggleSelect as default }
