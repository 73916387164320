import React from 'react'
import { MappedField } from '../..'
import { Row } from '../row/Row'
import styles from './RowsGroup.module.scss'

export interface RowsGroupProps {
  label: string
  name: string
  rows: MappedField[][]
}

export const RowsGroup = ({ label, name, rows }: RowsGroupProps) => {
  return (
    <div className={styles['group']}>
      <label className={styles['label']}>{label}</label>
      {rows.map((row, i) => (
        <Row key={`${name}-${i}`} fields={row} />
      ))}
    </div>
  )
}
