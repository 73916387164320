import React from 'react'
import { DateObj, RenderProps } from 'dayzed'
import cxBinder from 'classnames/bind'

import { RangeDatePickerResult } from '../hooks/useRangeDatepicker'
import styles from './DatepickerCalendar.module.scss'

const cx = cxBinder.bind(styles)

export interface CalendarDayProps {
  dayzed: RenderProps
  dateObj: DateObj | ''
  rangeDatePicker: RangeDatePickerResult
}

export const CalendarDay = (props: CalendarDayProps) => {
  const {
    dayzed: { getDateProps },
    dateObj,
    rangeDatePicker: { isInRange, onMouseEnter, onMouseLeave }
  } = props

  if (dateObj === '') {
    return null
  }

  const {
    selected: isSelected,
    selectable,
    today,
    nextMonth,
    prevMonth,
    date
  } = dateObj

  return (
    <button
      className={cx('day-wrapper', {
        'day-wrapper--today': today,
        selected: isSelected || isInRange(date),
        'selected--highlighted': isSelected,
        'other-month': nextMonth || prevMonth,
        disabled: !selectable
      })}
      {...getDateProps({
        dateObj,
        onMouseEnter: () => onMouseEnter(date),
        onMouseLeave
      })}
      onClick={(e) => {
        const actions = getDateProps({ dateObj })
        actions.onClick(e)
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <span className={styles['day-number']}>{date.getDate()}</span>
    </button>
  )
}
