import React, { ReactNode, useCallback, useState, useMemo, useRef } from 'react'
import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'

import cxBinder from 'classnames/bind'

import { useScroll } from '../../hooks/scroll/scroll'
import styles from './Tooltip.module.scss'

const cx = cxBinder.bind(styles)

interface TriggerElementProps {
  triggerElement: any
  showPopup: Function
  hidePopup: Function
}

type TooltipType = 'normal' | 'error'

interface TooltipsProps {
  children: ReactNode
  type?: TooltipType
  placement?: Placement
  triggerElement: ReactNode
  headline?: string | ReactNode
  e2eTarget?: string
  e2eTargetName?: string
}

const DISTANCE_EDGE = 60

const TriggerElement = (props: TriggerElementProps) => {
  const { triggerElement, showPopup, hidePopup } = props
  const { onMouseOver, onMouseOut } = triggerElement.props

  const getHandlers = useCallback(
    () => ({
      onMouseOver: onMouseOver || showPopup,
      onMouseOut: onMouseOut || hidePopup
    }),
    [onMouseOver, onMouseOut, hidePopup, showPopup]
  )

  return React.cloneElement(triggerElement, {
    ...getHandlers()
  })
}

const setTooltipStyle = (element: HTMLElement, toolTipWidth: number) => {
  let styleInlineArrow: {} = {
    right: '50%',
    transform: 'translate(50%, 0)'
  }
  let styleInlineTooltip: {} = {
    right: '0',
    transform: 'translate(50%, 0)'
  }

  if (element) {
    const widthWindow = window.innerWidth
    const elementRect = element.getBoundingClientRect()

    const elementRectHalfWidth = Math.round(elementRect.width / 2)

    if (widthWindow < elementRect.right + DISTANCE_EDGE) {
      styleInlineTooltip = {
        right: `-${elementRectHalfWidth}px`
      }
      styleInlineArrow = {
        right: `${elementRectHalfWidth}px`,
        transform: 'translate(50%, 0)'
      }
    } else if (
      elementRect.left < DISTANCE_EDGE &&
      toolTipWidth > elementRect.width
    ) {
      styleInlineTooltip = {
        left: `-${elementRectHalfWidth}px`
      }
      styleInlineArrow = {
        left: `${elementRectHalfWidth}px`,
        transform: 'translate(-50%, 0)'
      }
    }
  }

  return { styleInlineArrow, styleInlineTooltip }
}

const Tooltip = (props: TooltipsProps) => {
  const {
    children,
    type,
    triggerElement,
    placement = 'top',
    headline,
    e2eTarget = 'tooltip',
    e2eTargetName
  } = props

  const [internalAnchorEl, setInternalAnchorEl] = useState<HTMLElement | null>(
    null
  )

  const { elementRef, elementWidth } = useScroll()

  const showPopup = (event: React.MouseEvent<HTMLElement>) => {
    setInternalAnchorEl(event.currentTarget)
  }

  const hidePopup = () => {
    setInternalAnchorEl(null)
  }

  const popperRef = useRef(null)

  const { styles, attributes } = usePopper(
    internalAnchorEl,
    popperRef.current,
    {
      placement
    }
  )

  const { styleInlineArrow, styleInlineTooltip } = setTooltipStyle(
    internalAnchorEl as HTMLElement,
    elementWidth
  )

  const className = useMemo(
    () =>
      cx('tooltip-wrapper__content', {
        [`tooltip-wrapper__content--open`]: Boolean(internalAnchorEl),
        [`tooltip-wrapper__content--${placement}`]: placement
      }),
    [internalAnchorEl, placement]
  )

  return (
    <>
      <TriggerElement
        showPopup={showPopup}
        hidePopup={hidePopup}
        triggerElement={triggerElement}
      />
      <div
        ref={popperRef}
        style={styles.popper}
        {...attributes.ppper}
        data-placement={placement}
        className={cx('tooltip-wrapper', {
          [`tooltip-wrapper--${type}`]: type
        })}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        <div ref={elementRef} className={className} style={styleInlineTooltip}>
          <div className={cx('arrow')} style={styleInlineArrow}></div>
          {headline && (
            <div className={cx('tooltip-wrapper__headline')}>{headline}</div>
          )}
          {children}
        </div>
      </div>
    </>
  )
}

export { Tooltip, Tooltip as default }
