import React, {
  FocusEvent,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import cxBinder from 'classnames/bind'
import { Icon, Switch, TIcon, Tooltip } from '@packhelp/platform-dsl/atoms'
import { ValueType } from '../../enums/'
import { FormContext } from '../../contexts/form'
import { classicSelectFields } from '../../config/classic-select-fields'
import { Option, FieldProps } from '../../types'
import styles from './SingleInput.module.scss'

const cx = cxBinder.bind(styles)

interface SingleInputProps extends FieldProps {
  label: string
  component: FunctionComponent
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
}

export const SingleInput = <T extends unknown>({
  label,
  component,
  fullWidth,
  isRequired,
  errorMessage,
  type,
  lowPerformanceField = false,
  ...props
}: SingleInputProps) => {
  const showError = useMemo(() => Boolean(errorMessage), [errorMessage])
  const [isOn, setIsOn] = useState(Boolean(props.value))
  const [localValue, setLocalValue] = useState(props.value || undefined)
  const [focus, setFocus] = useState<boolean>(false)

  const { setValue, typeName } = useContext(FormContext)

  const handleFocus = () => {
    setFocus(true)
  }

  useEffect(() => {
    setLocalValue(props.value)
  }, [props.value])

  useEffect(() => {
    if (focus === false && (localValue === undefined || localValue === '')) {
      setIsOn(false)
    } else {
      setIsOn(true)
    }
  }, [localValue, typeName, focus])

  const isSwitch = useMemo(() => type === ValueType.Boolean, [type])
  const lowPerformanceOnChange = useCallback(
    (e) => {
      setLocalValue(e.target.value)
    },
    [setLocalValue]
  )
  const lowPerformanceOnBlur = useCallback(
    (e) => {
      setValue(props.name, localValue)
    },
    [localValue, setValue, props.name]
  )
  const onToggle = useCallback(
    (value) => {
      if (isSwitch) {
        setValue(props.name, value)
      } else if (value === false) {
        setValue(props.name, lowPerformanceField ? '' : undefined)
      } else if (value === true) {
        if (
          type === ValueType.Select ||
          classicSelectFields.includes(props.name)
        ) {
          setValue(props.name, (props.data as Option[])[0]?.value)
        }

        if (type === ValueType.Multiselect) {
          setValue(props.name, [(props.data as Option[])[0]?.value])
        }
      }
      setIsOn(!!value)
    },
    [
      props.value,
      setValue,
      setIsOn,
      isRequired,
      isSwitch,
      type,
      props.data,
      props.name,
      lowPerformanceField
    ]
  )

  const componentProps = useMemo(() => {
    if (isSwitch) {
      return props
    }
    return {
      ...props,
      value: localValue,
      onChange: lowPerformanceField ? lowPerformanceOnChange : props.onChange,
      onBlur: (e: FocusEvent<HTMLInputElement>) => {
        if (lowPerformanceField) {
          lowPerformanceOnBlur(e)
        }
        props.onBlur && props.onBlur(e)
        setFocus(false)
      },
      onFocus: handleFocus,
      error: showError
    }
  }, [
    isSwitch,
    showError,
    JSON.stringify(props),
    localValue,
    lowPerformanceOnChange,
    lowPerformanceOnBlur
  ])

  const componentElement = React.createElement(
    component,
    componentProps as { [key: string]: unknown }
  )

  const withSwitch = useMemo(() => isSwitch || !isRequired, [
    isSwitch,
    isRequired
  ])

  const onLabelClick = useCallback(() => {
    if (!withSwitch) return

    onToggle(!isOn)
  }, [isOn, onToggle, withSwitch])

  return (
    <div
      data-has-error={showError ? true : undefined}
      className={cx('single-input', {
        'single-input--full-width': fullWidth
      })}
    >
      <div
        className={cx('label-container', {
          'label-container--switch': isSwitch
        })}
      >
        {withSwitch && (
          <div className={cx('toggle')}>
            {isSwitch && componentElement}
            {!isSwitch && (
              <Switch
                onChange={onToggle}
                name={`toggle-${props.name}`}
                checked={isOn}
                disabled={props.disabled}
              />
            )}
          </div>
        )}
        <label
          onClick={onLabelClick}
          className={cx('label', {
            'label--clickable': withSwitch,
            'label--required': isRequired
          })}
        >
          {label}
        </label>
        {showError && (
          <Tooltip
            placement="top"
            type="error"
            triggerElement={
              <div className={cx('error')}>
                <Icon icon={TIcon.Error} />
              </div>
            }
          >
            {errorMessage}
          </Tooltip>
        )}
      </div>
      {!isSwitch && (
        <div
          className={cx('component-holder', {
            'component-holder--off': !isOn && !isRequired
          })}
        >
          {componentElement}
        </div>
      )}
    </div>
  )
}
