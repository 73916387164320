import React, {
  FunctionComponent,
  useState,
  useEffect,
  SyntheticEvent
} from 'react'
import cxBinder from 'classnames/bind'
import style from './Avatar.module.scss'

import { getAbbreviation, getHSLAColorFromString } from './helpers'

const cx = cxBinder.bind(style)

export enum AvatarSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

interface AvatarProps {
  src?: string | null
  active?: boolean
  clickable?: boolean
  size?: AvatarSize
  alt?: string
  e2eTarget?: string
  e2eTargetName?: string
}

export const Avatar: FunctionComponent<AvatarProps> = (props) => {
  const {
    src = '',
    size,
    active,
    alt,
    clickable,
    e2eTarget = 'avatar',
    e2eTargetName,
    ...other
  } = props

  const [imgError, setImgError] = useState<SyntheticEvent<
    HTMLImageElement,
    Event
  > | null>(null)

  const [imgLoaded, setImgLoaded] = useState<boolean>(false)

  const caption = getAbbreviation(alt)
  const backgroundColor = getHSLAColorFromString(alt)

  useEffect(() => {
    setImgLoaded(false)
    setImgError(null)
  }, [src])

  return (
    <div
      className={cx('avatar-wrapper', {
        [`avatar-wrapper--${size}`]: size,
        'avatar-wrapper--active': active,
        'avatar-wrapper--clickable': clickable
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      <figure
        className={cx('avatar-wrapper__image-container', {
          'avatar-wrapper__image-container--alt': alt,
          'avatar-wrapper__image-container--img-loaded': imgLoaded
        })}
      >
        {src && !imgError && (
          <img
            className={cx('avatar-wrapper__image', {
              'avatar-wrapper__image--loaded': imgLoaded
            })}
            src={src}
            alt={alt}
            onLoad={() => {
              setImgLoaded(true)
            }}
            onError={(e) => {
              setImgError(e)
            }}
          />
        )}
        {!imgLoaded && (
          <figcaption
            className={cx('avatar-wrapper__caption')}
            style={{ backgroundColor }}
          >
            {caption}
          </figcaption>
        )}
      </figure>
    </div>
  )
}

export default Avatar
