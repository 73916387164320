import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cxBinder from 'classnames/bind'

import styles from './LinkELement.module.scss'

const cx = cxBinder.bind(styles)

export enum LinkType {
  tableLink = 'tableLink'
}

interface LinkProps {
  children: ReactNode
  to: string
  bold?: boolean
  type?: LinkType
  e2eTarget?: string
  e2eTargetName?: string
  external?: boolean
}

const LinkElement = (props: LinkProps) => {
  const {
    to,
    children,
    type = 'tableLink',
    bold = false,
    e2eTarget = 'link-element',
    e2eTargetName,
    external
  } = props

  const linkElement = external ? (
    <a href={to} target="_blank">
      {children}
    </a>
  ) : (
    <Link to={to}>{children}</Link>
  )

  return (
    <span
      className={cx('link', { [`link--bold`]: bold, [`link--${type}`]: type })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {linkElement}
    </span>
  )
}

export { LinkElement, LinkElement as default }
