import React, { ChangeEvent, CSSProperties, useEffect, useRef } from 'react'
import cxBinder from 'classnames/bind'
import { Icon } from '../Icon/Icon'

import styles from './ToggleSelect.module.scss'
import { TIcon } from '../Icon'

const cx = cxBinder.bind(styles)

interface CustomOptionProps<T> {
  customOption?: boolean
  handleChange: (value: T) => void
  isSelected: (value?: T) => boolean
  value?: T
  salt?: string
  customOptionLabel?: string
  disabled?: boolean
}

export const CustomOption = <T extends unknown>(
  props: CustomOptionProps<T>
) => {
  const {
    customOption,
    handleChange,
    isSelected,
    value,
    salt,
    disabled,
    customOptionLabel = ''
  } = props

  const inputRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.textContent = (value as string) || ''
    }
  }, [])

  useEffect(() => {
    if (inputRef.current && value !== inputRef.current?.textContent) {
      inputRef.current.textContent = ''
    }
  }, [salt, value])

  const handleInputChange = ({
    target: { textContent }
  }: ChangeEvent<HTMLSpanElement>) => {
    handleChange(textContent as T)
  }

  useEffect(() => {
    if (inputRef.current && value) {
      inputRef.current.textContent = value as string
    }
  }, [value])

  const handleKeyDown = ({ key }: KeyboardEvent) => {
    if (key === 'Enter') {
      inputRef.current?.blur()
    }
  }

  useEffect(() => {
    inputRef.current?.addEventListener('keydown', handleKeyDown)
    return () => {
      inputRef.current?.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  if (!customOption) {
    return null
  }
  return (
    <div
      className={cx('wrapper__item', {
        'wrapper__item--custom': true,
        'wrapper__item--selected': isSelected(value),
        'wrapper__item--disabled': disabled,
        'wrapper__item--disabled-selected': disabled && isSelected(value)
      })}
      onClick={() =>
        inputRef.current?.textContent !== '' &&
        handleChange(inputRef.current?.textContent as T)
      }
    >
      <div className={cx('wrapper__item--custom__icon')}>
        <Icon icon={TIcon.Edit} />
      </div>
      <span
        ref={inputRef}
        tabIndex={disabled ? -1 : 0}
        style={{ '--placeholder': `"${customOptionLabel}"` } as CSSProperties}
        className={cx('wrapper__item--custom__span')}
        onBlur={handleInputChange}
        role="textbox"
        contentEditable
      />
    </div>
  )
}
