import React, { memo, PropsWithChildren, useCallback } from 'react'
import usePortal from 'react-useportal'
import cxBinder from 'classnames/bind'
import { useModalContext } from '../../../molecules/Modal/context'
import {
  PopperStyleProps,
  PopupWrapperStyleProps,
  UsePopupElementsProps
} from '../types'
import styles from '../Popup.module.scss'
import { usePopupContext } from '../context'
import { usePopperProps } from './popper-props'
import { usePopupClickOutside } from './popup-click-outside'

const cx = cxBinder.bind(styles)

const Header = memo<PropsWithChildren<{}>>(({ children }) =>
  children ? <div className={cx('popup__header')}>{children}</div> : null
)

const Footer = memo<PropsWithChildren<{}>>(({ children }) =>
  children ? <div className={cx('popup__footer')}>{children}</div> : null
)

const Content = memo<PropsWithChildren<{}>>(({ children }) => (
  <div className={cx('popup__content')}>{children}</div>
))

const Wrapper = memo<PropsWithChildren<PopupWrapperStyleProps>>(
  ({ children, popupSize }) => (
    <div
      className={cx('popup__wrapper', {
        [`popup__wrapper--${popupSize}`]: popupSize
      })}
    >
      {children}
    </div>
  )
)

export const usePopupElements = ({
  width,
  parentWidth = false,
  anchorEl,
  placement,
  modifiers = [],
  onClickOutside,
  popperRef,
  portalBindTo
}: UsePopupElementsProps) => {
  const modalAncestor = useModalContext()?.ref.current
  const popupAncestor = usePopupContext()?.ref.current

  const bindTo = portalBindTo || popupAncestor || modalAncestor || undefined

  const { Portal } = usePortal({ bindTo })

  const { popperProps, popperEl } = usePopperProps({
    anchorEl,
    popperRef,
    width,
    parentWidth,
    placement,
    modifiers
  })

  usePopupClickOutside({ popperEl, anchorEl, onClickOutside })

  const Popper = useCallback(
    ({
      children,
      noPadding,
      placement,
      popperProps,
      ...props
    }: PropsWithChildren<
      PopperStyleProps & Pick<ReturnType<typeof usePopperProps>, 'popperProps'>
    >) => {
      const className = cx('popup', {
        [`popup--no-padding`]: noPadding,
        [`popup--on-popup`]: !!popupAncestor,
        [`popup--on-modal`]: !!modalAncestor
      })

      return (
        <div
          {...props}
          data-placement={placement}
          className={className}
          {...popperProps}
        >
          {children}
        </div>
      )
    },
    [modalAncestor, popupAncestor]
  )

  return {
    Portal,
    Popper,
    popperProps,
    Wrapper,
    Header,
    Content,
    Footer
  }
}
