import React, { useMemo } from 'react'
import cxBinder from 'classnames/bind'
import styles from './Loading.module.scss'

const cx = cxBinder.bind(styles)

export enum LoadingSize {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl'
}

const svgSize = {
  xs: {
    strokeWidth: 2,
    r: 8
  },
  s: {
    strokeWidth: 2,
    r: 16
  },
  m: {
    strokeWidth: 2,
    r: 24
  },
  l: {
    strokeWidth: 2,
    r: 32
  },
  xl: {
    strokeWidth: 4,
    r: 48
  }
}

export interface LoadingProps {
  size?: LoadingSize
  lightMode?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const Loading = ({
  size = LoadingSize.s,
  lightMode = false,
  e2eTarget = 'loading',
  e2eTargetName
}: LoadingProps) => {
  const r = useMemo(() => svgSize[size].r, [size])
  const strokeWidth = useMemo(() => svgSize[size].strokeWidth, [size])
  const viewBoxSize = useMemo(() => r * 2, [r])
  const viewBox = useMemo(() => `0 0 ${viewBoxSize} ${viewBoxSize}`, [
    viewBoxSize
  ])
  const strokeDasharray = useMemo(() => 2 * Math.PI * r * 0.75, [r])
  const circleRadius = useMemo(() => r - 0.5 * strokeWidth, [r, strokeWidth])
  return (
    <svg
      className={cx('spinner', {
        [`spinner--${size}`]: size,
        [`spinner--light`]: lightMode
      })}
      viewBox={viewBox}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <circle
        cx={r}
        cy={r}
        r={circleRadius}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
      />
    </svg>
  )
}

export { Loading, Loading as default }
