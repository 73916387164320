import { useCallback, useEffect, useState } from 'react'
import { ValidationErrorResponse } from '@packhelp/platform-pss-api'
import { PSSErrors } from '../../../../types/form-configuration'
import { PSSFormik } from '../../../../types'

export const useFormErrors = (formik: PSSFormik, errors?: PSSErrors) => {
  const [passedErrors, setPassedErrors] = useState({})

  useEffect(() => {
    if (errors && JSON.stringify(errors) !== JSON.stringify(passedErrors)) {
      const errorKeys = Object.keys(errors)
      if (errorKeys.length > 0) {
        const touchedFields = {
          ...formik.touched,
          ...errorKeys.reduce(
            (touched, errorKey) => ({ ...touched, [errorKey]: true }),
            {}
          )
        }
        formik.setTouched(touchedFields)
      }
      setPassedErrors(errors)
    }
  }, [errors, passedErrors])

  useEffect(() => {
    formik.setErrors({
      ...passedErrors,
      ...formik.errors
    })
  }, [passedErrors, formik.errors])

  const handleServerErrors = useCallback(
    (serverErrors: ValidationErrorResponse[]) => {
      const parsed = serverErrors.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.attributeName]: cur.message
        }),
        {}
      )
      setPassedErrors(parsed)
    },
    []
  )

  return handleServerErrors
}
