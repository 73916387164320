import { MouseEvent } from 'react'
import { computed, observable, autorun, action } from 'mobx'
import dot from 'dot-object'
import uuid from 'uuid/v4'
import { SingleColumnStore } from '../columns/single-column-store'
import { TableStore } from '../table-store'
import { RowCellStore } from './row-cell-store'
import { TRowContext, TableRowsConfig, SingleRowData } from './row-definitions'

interface RowCells {
  [key: string]: RowCellStore
}

export class SingleRowStore {
  public context?: TRowContext
  public cells: RowCells
  @observable private _rowData
  @observable private _isFake
  private _disposer

  constructor(
    private _rootStore: TableStore,
    context: TRowContext = 'data-row',
    _rowData: SingleRowData = {},
    private _rowsConfig: TableRowsConfig = {},
    public previousRow?: SingleRowStore,
    _isFake?: boolean
  ) {
    this.context = context
    this._rowData = _rowData
    this._isFake = _isFake
    this.cells = this._dataToCells()
    this._disposer = autorun(() => this._verifyCells())
  }

  public update(context: TRowContext = 'data-row'): SingleRowStore {
    if (context !== this.context) {
      this.context = context
    }

    return this
  }

  @computed get rowData() {
    return this._rowData
  }

  @computed get isFake() {
    return this._isFake
  }

  @computed get id() {
    return (
      (this._rowData &&
        this._rootStore.accessor &&
        dot.pick(this._rootStore.accessor, this._rowData)) ||
      uuid()
    )
  }

  @computed get key() {
    return `row-${this.id}`
  }

  @computed get showError() {
    return Boolean(
      this._rowsConfig.showError &&
        this._rowsConfig.showError(this._rowData, this._rootStore.sort.sortedBy)
    )
  }

  @computed get hoverable() {
    return Boolean(this._rowsConfig.hoverable)
  }

  @computed get clickable() {
    return Boolean(this._rowsConfig.onClick)
  }

  @computed get isDimmed() {
    return Boolean(
      !this.isSelected &&
        !this._rootStore.isLoading &&
        this._rowsConfig.showDimmed &&
        this._rowsConfig.showDimmed(
          this._rowData,
          this._rootStore.sort.sortedBy
        )
    )
  }

  @computed get isSelected() {
    if (this.context === 'header') {
      return this._rootStore.bulk.areRowsSelected
    }
    return this._rootStore.bulk.isSelected[this.id]
  }

  @computed get isBulkIntermediate() {
    if (this.context === 'header') {
      return this._rootStore.bulk.isIntermediate
    }
    return false
  }

  @computed get shouldShowGroup() {
    const sortedColumn = this._rootStore.columns.sortedColumn

    if (!sortedColumn || !sortedColumn.isGroupable) return false
    const currentValue = sortedColumn && this.cells[sortedColumn.uuid].rawValue
    const previousValue =
      sortedColumn &&
      this.previousRow &&
      this.previousRow.cells[sortedColumn.uuid].rawValue

    return sortedColumn.groupComparer(currentValue, previousValue)
  }

  @computed get groupValue() {
    const sortedColumn = this._rootStore.columns.sortedColumn

    if (!sortedColumn || !this.shouldShowGroup) return null

    const value = sortedColumn && this.cells[sortedColumn.uuid].rawValue

    return sortedColumn.groupRenderer
      ? sortedColumn.groupRenderer(value, this._rowData)
      : value
  }

  @action.bound toggleBulk(e?: MouseEvent<HTMLDivElement>) {
    if (this.context === 'header') {
      this._rootStore.bulk.toggleAllSelection()
    } else {
      this._rootStore.bulk.toggleSelection(this.id, e && e.shiftKey)
    }
  }

  @action.bound onClick(e?: MouseEvent<HTMLDivElement>) {
    if (this.context !== 'header' && this._rowsConfig.onClick) {
      this._rowsConfig.onClick(this._rowData)
    }
  }

  @action.bound public verifyCells = () => this._verifyCells()

  private _verifyCells = () => {
    this._rootStore.columns.columns.forEach(
      (columnConfig: SingleColumnStore) => {
        if (this.cells && !this.cells[columnConfig.uuid]) {
          this.cells = {
            ...this.cells,
            [columnConfig.uuid]: new RowCellStore(
              this._rootStore,
              this,
              columnConfig,
              this._rowData
            )
          }
        }
      }
    )
  }

  private _dataToCells() {
    return this._rootStore.columns.columns.reduce(
      (cells: RowCells, columnConfig: SingleColumnStore): RowCells => {
        return {
          ...cells,
          [columnConfig.uuid]: new RowCellStore(
            this._rootStore,
            this,
            columnConfig,
            this._rowData
          )
        }
      },
      {}
    )
  }
}
