import { useEffect } from 'react'
import { ProductSpecificationAttributes, PSSFormik } from '../../../../types'

export const useTouchedOnSubmit = (
  formik: PSSFormik,
  fields: ProductSpecificationAttributes
) => {
  useEffect(() => {
    if (formik?.submitCount > 0) {
      const touchedConfig = Object.keys(fields).reduce(
        (touched, fieldKey) => ({
          ...touched,
          [fieldKey]: true
        }),
        {}
      )
      formik.setTouched(touchedConfig)
    }
  }, [formik?.submitCount, fields])
}
