import React, { ReactNode, MutableRefObject } from 'react'
import cxBinder from 'classnames/bind'

import { useScroll } from '../../hooks/scroll/scroll'
import { ConditionalTooltip } from '../../atoms/Tooltip/ConditionalTooltip'

import styles from './SearchableList.module.scss'

const cx = cxBinder.bind(styles)

export type ListItemData<T> = {
  name: string
  value: T
}

interface ListItemProps<T> {
  listItem: ListItemData<T>
  selectedValue?: string | null
  itemRenderer?: (
    itemData: ListItemData<T>,
    scrollSize?: number,
    elementRef?: MutableRefObject<HTMLInputElement | null>,
    searchValue?: string
  ) => ReactNode
  separator?: (itemData: ListItemData<T>) => boolean
  handleItemClick: (itemData: ListItemData<T>) => void
  searchValue: string
  e2eTarget: string
  e2eTargetName: T
}

const ListItem = <T extends unknown>(props: ListItemProps<T>) => {
  const {
    itemRenderer,
    selectedValue = '',
    separator,
    listItem,
    handleItemClick,
    searchValue,
    e2eTarget = 'list-item',
    e2eTargetName
  } = props

  const { scrollSize, elementRef } = useScroll()

  return (
    <div
      className={styles.wrapper}
      key={`${listItem.name}-${listItem.value}`}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div
        className={cx('list-item', {
          'list-item-selected': listItem.value === selectedValue
        })}
        onClick={() => handleItemClick(listItem)}
        e2e-target="button"
        e2e-target-name="select-item"
      >
        {itemRenderer ? (
          itemRenderer(listItem, scrollSize, elementRef, searchValue)
        ) : (
          <ConditionalTooltip
            condition={!!scrollSize}
            tooltipText={listItem.name}
          >
            <span ref={elementRef} className={cx('list-item-content')}>
              {listItem.name}
            </span>
          </ConditionalTooltip>
        )}
      </div>
      {separator && separator(listItem) && (
        <div className={cx('separator')} e2e-target="separator" />
      )}
    </div>
  )
}

export { ListItem, ListItem as default }
