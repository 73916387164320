import React, {
  useRef,
  useEffect,
  MutableRefObject,
  useCallback,
  ReactNode,
  useState
} from 'react'
import cxBinder from 'classnames/bind'
import { Icon } from '../../atoms/Icon/Icon'
import { TIcon } from '../../atoms/Icon/IconTypes'

import styles from './Modal.module.scss'
import { ModalContextProvider } from './context'

const cx = cxBinder.bind(styles)

export enum ModalSize {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  full = 'full-width',
  auto = 'auto'
}

interface ModalProps {
  open: boolean
  onClose?: () => void
  closableWithOutsideClick?: boolean
  sizable?: boolean
  header?: ReactNode
  children: ReactNode
  leftFooter?: ReactNode
  rightFooter?: ReactNode
  width?: ModalSize
  height?: ModalSize
  borderFooter?: boolean
  noScroll?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const MODAL_OPEN_CLASS = 'body--modal-open'

const Modal = (props: ModalProps) => {
  const { open } = props
  if (!open) return null

  return <_Modal {...props} />
}

const _Modal = (props: ModalProps) => {
  const {
    onClose,
    closableWithOutsideClick = true,
    header,
    children,
    leftFooter,
    rightFooter,
    width = ModalSize.xs,
    height = ModalSize.xs,
    sizable = false,
    borderFooter = true,
    noScroll = false,
    e2eTarget = 'modal',
    e2eTargetName
  } = props

  const modalRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleClose = useCallback(() => {
    onClose && onClose()
  }, [onClose])

  const handleOutsideClick = useCallback(
    (e) => {
      if (!modalRef || !modalRef.current || modalRef.current.contains(e.target))
        return
      closableWithOutsideClick && handleClose && handleClose()
    },
    [handleClose, closableWithOutsideClick]
  )

  const handleEscClick = useCallback(
    (e) => {
      if (e.key === 'Escape') handleClose()
    },
    [handleClose]
  )
  const handleModalSizeChange = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    document.addEventListener('keydown', handleEscClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('keydown', handleEscClick)
    }
  }, [handleOutsideClick, handleEscClick])

  useEffect(() => {
    document.body.classList.add(cx(MODAL_OPEN_CLASS))

    return () => {
      document.body.classList.remove(cx(MODAL_OPEN_CLASS))
    }
  }, [])

  return (
    <ModalContextProvider value={{ ref: modalRef }}>
      <div
        className={cx('modal', { 'modal--open': true })}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        <div
          ref={modalRef}
          className={cx('modal__content', {
            [`modal__content-expanded`]: isExpanded,
            [`modal__content__width--${width}`]: width && !isExpanded,
            [`modal__content__height--${height}`]: height && !isExpanded
          })}
        >
          {header && (
            <div className={cx('modal__header')} e2e-target="header-group">
              <div e2e-target="header-title">{header}</div>
              <div
                className={cx('modal__header--icons-section')}
                e2e-target="header-icons"
              >
                {sizable &&
                  (isExpanded ? (
                    <div
                      className={cx('icon-wrapper')}
                      onClick={handleModalSizeChange}
                      e2e-target="icon"
                      e2e-target-name="collapse"
                    >
                      <Icon
                        icon={TIcon.Collaps}
                        className={cx('icon', 'icon-collapse')}
                      />
                    </div>
                  ) : (
                    <div
                      className={cx('icon-wrapper')}
                      onClick={handleModalSizeChange}
                      e2e-target="icon"
                      e2e-target-name="expand"
                    >
                      <Icon icon={TIcon.Expand} className={styles.icon} />
                    </div>
                  ))}
                {onClose && (
                  <div
                    className={cx('icon-wrapper')}
                    onClick={onClose}
                    e2e-target="icon"
                    e2e-target-name="close"
                  >
                    <Icon icon={TIcon.CloseBig} className={styles.icon} />
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className={cx('modal__body', {
              'modal__body--no-scroll': noScroll
            })}
            e2e-target="content"
          >
            {children}
          </div>
          {(leftFooter || rightFooter) && (
            <div
              className={cx('modal__footer', {
                'modal__footer--no-border': !borderFooter
              })}
              e2e-target="footer-group"
            >
              <div
                className={cx('modal__footer--left')}
                e2e-target="footer-left"
              >
                {leftFooter}
              </div>
              <div
                className={cx('modal__footer--right')}
                e2e-target="footer-right"
              >
                {rightFooter}
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalContextProvider>
  )
}

export { Modal, Modal as default }
