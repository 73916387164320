import React, { useState, MutableRefObject, useEffect } from 'react'

import cxBinder from 'classnames/bind'

import styles from './Thumb.module.scss'

const cx = cxBinder.bind(styles)

export enum ThumbSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge'
}

interface ThumbProps {
  size: ThumbSize
  src?: string
  alt?: string
  e2eTarget?: string
  e2eTargetName?: string
  thumbRef?: MutableRefObject<HTMLImageElement | null>
}

const Thumb = ({
  size,
  src,
  alt,
  e2eTarget = 'thumb',
  e2eTargetName,
  thumbRef
}: ThumbProps) => {
  const [imgSrc, setImgSrc] = useState(src)

  let mounted

  useEffect(() => {
    mounted = true
    return () => {
      mounted = false
    }
  })

  const imgError = () => {
    mounted && setImgSrc('')
  }

  return (
    <div
      className={cx('thumb', {
        [`thumb--${size}`]: size,
        'thumb--no-image': !imgSrc
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      ref={!imgSrc ? thumbRef : null}
    >
      {imgSrc && (
        <img
          className={cx('thumb__image')}
          src={imgSrc}
          alt={alt}
          onError={imgError}
          ref={imgSrc ? thumbRef : null}
        />
      )}
    </div>
  )
}

export { Thumb, Thumb as default }
