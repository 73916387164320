import { ReactNode } from 'react'

export type ValueProps = number | number[] | string[] | string

export type SelectData = {
  value: ValueProps
  label: ReactNode
}

export enum SelectSize {
  small = 'small',
  large = 'large',
  minimal = 'minimal'
}
