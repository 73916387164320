import React, { useMemo } from 'react'
import { DropzoneLabelProps, FileProps, RemoveFile } from './Dropzone'
import cxBinder from 'classnames/bind'

import { Icon, IconSize, TIcon } from '../../atoms'
import styles from './FileList.module.scss'

const cx = cxBinder.bind(styles)

interface FileListProps {
  files?: FileProps[]
  onRemove?: RemoveFile
  maxSize: number
  minSize: number
  labels: DropzoneLabelProps
  showFileList?: boolean
  disabled?: boolean
}

export const FileList = (props: FileListProps) => {
  const {
    files,
    onRemove,
    maxSize,
    minSize,
    labels,
    showFileList,
    disabled
  } = props

  const dataDisabled = useMemo(() => {
    return disabled === true || undefined
  }, [disabled])

  if (!files || !showFileList) {
    return null
  }

  const sizeWithUnit = (bytes: number) => {
    const megaBytes = (bytes / 1000000).toFixed(2)
    return `${megaBytes} MB`
  }

  const isSizeInvalid = (bytes: number) => bytes <= minSize || bytes >= maxSize

  const printLabel = (item: FileProps) => {
    if (isSizeInvalid(item.size)) {
      return `${labels.sizeError} - ${sizeWithUnit(item.size)}`
    }

    return sizeWithUnit(item.size)
  }

  const handleRemove = (item: FileProps) => {
    if (disabled) {
      return
    }
    onRemove && onRemove(item)
  }

  return (
    <>
      {files.map((item) => (
        <div
          className={cx('file', {
            file__disabled: disabled
          })}
          key={item.id}
        >
          <a
            className={cx('file__text', {
              'file__text--error': isSizeInvalid(item.size),
              'file__text--disabled': disabled
            })}
            target="_blank"
            href={item.url}
            rel="noopener noreferrer"
          >
            {item.name}
          </a>
          <span
            className={cx('file__info', {
              'file__info--disabled': disabled
            })}
          >
            <span>{printLabel(item)}</span>
            <span
              data-disabled={dataDisabled}
              onClick={() => handleRemove(item)}
            >
              <Icon icon={TIcon.Trash} size={IconSize.s} />
            </span>
          </span>
        </div>
      ))}
    </>
  )
}
