import { computed, action } from 'mobx'
import { ReactNode } from 'react'
import { SingleRowData } from '../rows'
import { TableStore } from '../table-store'

export type ColumnSide = 'left' | 'right'

export enum ColumnAlign {
  left = 'left',
  right = 'right',
  center = 'center'
}

type Accessor<RowData, ColumnKey> = ColumnKey extends string & keyof RowData
  ? ColumnKey
  : string & keyof RowData

type ColumnData<RowData, ColumnKey = undefined> = ColumnKey extends undefined
  ? RowData[Accessor<RowData, ColumnKey>] | undefined
  : RowData[Accessor<RowData, ColumnKey>]

export enum PaddingType {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l'
}
export interface SingleColumnBaseProps<
  RowData extends SingleRowData,
  ColumnKey extends (string & keyof RowData) | undefined = undefined
> {
  uuid?: string
  accessor?: Accessor<RowData, ColumnKey>
  side?: ColumnSide
  width?: number
  minWidth?: number
  maxWidth?: number
  label?: ReactNode
  sortable?: boolean
  align?: ColumnAlign
  highlight?: boolean
  paddingType?: PaddingType
  isGroupable?: boolean
  hideWithTooltip?: boolean
  isMark?: boolean
  groupComparer?: (
    currentRowValue?: ColumnData<RowData, ColumnKey>,
    previousRowValue?: ColumnData<RowData, ColumnKey>
  ) => boolean
  groupRenderer?: (
    value: ColumnData<RowData, ColumnKey>,
    row: RowData
  ) => ReactNode
  headerRenderer?: () => ReactNode
  cellRenderer?: (
    data: ColumnData<RowData, ColumnKey>,
    row: RowData
  ) => ReactNode
}

export type SingleColumnProps<
  R extends SingleRowData = SingleRowData,
  C = undefined
> = C extends string & keyof R
  ? { accessor: C } & SingleColumnBaseProps<R, C>
  : { uuid: string } & SingleColumnBaseProps<R>

export class SingleColumnStore<
  R extends SingleRowData = SingleRowData,
  C extends (string & keyof R) | undefined = undefined
> {
  public uuid: string
  public accessor?: Accessor<R, C>
  public side: ColumnSide = 'left'
  public align?: ColumnAlign = ColumnAlign.left
  public highlight?: boolean = false
  public paddingType?: PaddingType = PaddingType.m
  public hideWithTooltip?: boolean = false
  public width?: number
  public maxWidth?: number
  public minWidth?: number
  public isMark?: boolean = false
  public cellRenderer?: (data: ColumnData<R, C>, row: R) => ReactNode
  public groupRenderer?: (value: ColumnData<R, C>, row: R) => ReactNode

  private _sortable?: boolean
  private _label?: ReactNode
  private _labelRenderer?: (value: ReactNode) => ReactNode
  private _isGroupable?: boolean = false
  private _groupComparer?: (
    currentRowValue?: ColumnData<R, C>,
    previousRowValue?: ColumnData<R, C>
  ) => boolean

  constructor(
    private _rootStore: TableStore,
    columnConfig: SingleColumnBaseProps<R, C>
  ) {
    this.uuid = (columnConfig.uuid || columnConfig.accessor) as string
    this.update(columnConfig)
  }

  public update(
    columnConfig: SingleColumnBaseProps<R, C>
  ): SingleColumnStore<R, C> {
    if (this.accessor !== columnConfig.accessor) {
      this.accessor = columnConfig.accessor
    }
    if (this._isGroupable !== columnConfig.isGroupable) {
      this._isGroupable = columnConfig.isGroupable
    }
    if (this.side !== columnConfig.side) {
      this.side = columnConfig.side || 'left'
    }
    if (this.hideWithTooltip !== columnConfig.hideWithTooltip) {
      this.hideWithTooltip = columnConfig.hideWithTooltip === true
    }

    if (this.width !== columnConfig.width) {
      this.width = columnConfig.width
    }
    if (this.minWidth !== columnConfig.minWidth) {
      this.minWidth = columnConfig.minWidth
    }
    if (this.maxWidth !== columnConfig.maxWidth) {
      this.maxWidth = columnConfig.maxWidth
    }
    if (this.align !== columnConfig.align) {
      this.align = columnConfig.align || ColumnAlign.left
    }
    if (this.highlight !== columnConfig.highlight) {
      this.highlight = !!columnConfig.highlight
    }
    if (this.paddingType !== columnConfig.paddingType) {
      this.paddingType = columnConfig.paddingType || PaddingType.m
    }

    if (this._sortable !== columnConfig.sortable) {
      this._sortable =
        columnConfig.sortable === undefined || columnConfig.sortable
    }

    if (this._label !== columnConfig.label) {
      this._label = columnConfig.label
    }

    if (this.isMark !== columnConfig.isMark) {
      this.isMark = !!columnConfig.isMark
    }

    this._labelRenderer = columnConfig.headerRenderer
    this._groupComparer = columnConfig.groupComparer
    this.groupRenderer = columnConfig.groupRenderer
    this.cellRenderer = columnConfig.cellRenderer

    return this
  }

  public onSort() {
    const direction =
      !this.isSorted || this.sortDirection === 'DESC' ? 'ASC' : 'DESC'
    this.isSortable &&
      this.accessor &&
      this._rootStore.sort.onSort &&
      this._rootStore.sort.onSort(this.accessor, direction)
  }

  @computed get key() {
    return `column-${this.uuid}`
  }

  @computed get isSortable() {
    return Boolean(
      this._sortable !== false && this.accessor && !!this._rootStore.sort.onSort
    )
  }

  @computed get isSorted() {
    return Boolean(
      this.isSortable && this.uuid === this._rootStore.sort.sortField
    )
  }

  @computed get sortDirection() {
    return (this.isSorted && this._rootStore.sort.sortDirection) || 'ASC'
  }

  @computed get isGroupable() {
    return this.isSorted && this._isGroupable
  }

  @computed get shouldUseCellWrapper() {
    return Boolean(this._label && this.maxWidth)
  }

  @computed get label() {
    return (
      (this._labelRenderer && this._labelRenderer(this._label)) ||
      this._label ||
      ''
    )
  }

  @computed get widthStyle() {
    if (this.width) {
      return {
        width: `${this.width}px`
      }
    }
    if (this.minWidth || this.maxWidth) {
      return {
        minWidth: this.minWidth && `${this.minWidth}px`,
        maxWidth: this.maxWidth && `${this.maxWidth}px`
      }
    }
    return {}
  }

  @action groupComparer(
    currentValue?: ColumnData<R, C>,
    previousValue?: ColumnData<R, C>
  ): boolean {
    if (this._groupComparer) {
      return this._groupComparer(currentValue, previousValue)
    }
    return currentValue !== previousValue
  }
}
