import React, { FC } from 'react'
import cxBinder from 'classnames/bind'
import { useObserver } from 'mobx-react-lite'
import { useRowContext } from '../../hooks/row-store'
import style from '../../Table.module.scss'
import CellBase from './CellBase'

const cx = cxBinder.bind(style)

export const SeparatorCell: FC = () => {
  const rowStore = useRowContext()

  return useObserver(() => (
    <CellBase e2e-target="cell-separator" isSeparator={true}>
      {rowStore.context === 'header' && (
        <div className={cx('table__header-cell')} />
      )}
    </CellBase>
  ))
}
