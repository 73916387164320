
// ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
//        AUTOGENERATED FILE
// ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗

export enum TIcon {
	Box = 'Box',
	ImgPlaceholder = 'ImgPlaceholder',
	Actions = 'Actions',
	AddColumns = 'AddColumns',
	AddUser = 'AddUser',
	ArrowsType1Down = 'ArrowsType1Down',
	ArrowsType1Left = 'ArrowsType1Left',
	ArrowsType1Right = 'ArrowsType1Right',
	ArrowsType1Up = 'ArrowsType1Up',
	ArrowsType2Collapse = 'ArrowsType2Collapse',
	ArrowsType2Expand = 'ArrowsType2Expand',
	ArrowsType2Left = 'ArrowsType2Left',
	ArrowsType2Right = 'ArrowsType2Right',
	ArrowsType3Down = 'ArrowsType3Down',
	ArrowsType3Left = 'ArrowsType3Left',
	ArrowsType3Right = 'ArrowsType3Right',
	ArrowsType3Up = 'ArrowsType3Up',
	ArrowsType4Down = 'ArrowsType4Down',
	ArrowsType4Left = 'ArrowsType4Left',
	ArrowsType4Right = 'ArrowsType4Right',
	ArrowsType4Up = 'ArrowsType4Up',
	Attach = 'Attach',
	Bin = 'Bin',
	Blocks = 'Blocks',
	Calendar = 'Calendar',
	Check = 'Check',
	Clock = 'Clock',
	CloseBig = 'CloseBig',
	Close = 'Close',
	Collaps = 'Collaps',
	Columns = 'Columns',
	Comment = 'Comment',
	Disable = 'Disable',
	DockList = 'DockList',
	DockSettings = 'DockSettings',
	DockShare = 'DockShare',
	Dock = 'Dock',
	Dollar = 'Dollar',
	Download = 'Download',
	Drag = 'Drag',
	Duplicate = 'Duplicate',
	Edit = 'Edit',
	EmailNotSent = 'EmailNotSent',
	EmailSent = 'EmailSent',
	Error = 'Error',
	Expand = 'Expand',
	EyeHide = 'EyeHide',
	EyeShow = 'EyeShow',
	Filter = 'Filter',
	Flag = 'Flag',
	FlagsAd = 'FlagsAd',
	FlagsAe = 'FlagsAe',
	FlagsAf = 'FlagsAf',
	FlagsAg = 'FlagsAg',
	FlagsAi = 'FlagsAi',
	FlagsAl = 'FlagsAl',
	FlagsAm = 'FlagsAm',
	FlagsAo = 'FlagsAo',
	FlagsAq = 'FlagsAq',
	FlagsAr = 'FlagsAr',
	FlagsAs = 'FlagsAs',
	FlagsAt = 'FlagsAt',
	FlagsAu = 'FlagsAu',
	FlagsAw = 'FlagsAw',
	FlagsAx = 'FlagsAx',
	FlagsAz = 'FlagsAz',
	FlagsBa = 'FlagsBa',
	FlagsBb = 'FlagsBb',
	FlagsBd = 'FlagsBd',
	FlagsBe = 'FlagsBe',
	FlagsBf = 'FlagsBf',
	FlagsBg = 'FlagsBg',
	FlagsBh = 'FlagsBh',
	FlagsBi = 'FlagsBi',
	FlagsBj = 'FlagsBj',
	FlagsBl = 'FlagsBl',
	FlagsBm = 'FlagsBm',
	FlagsBn = 'FlagsBn',
	FlagsBo = 'FlagsBo',
	FlagsBq = 'FlagsBq',
	FlagsBr = 'FlagsBr',
	FlagsBs = 'FlagsBs',
	FlagsBt = 'FlagsBt',
	FlagsBv = 'FlagsBv',
	FlagsBw = 'FlagsBw',
	FlagsBy = 'FlagsBy',
	FlagsBz = 'FlagsBz',
	FlagsCa = 'FlagsCa',
	FlagsCc = 'FlagsCc',
	FlagsCd = 'FlagsCd',
	FlagsCf = 'FlagsCf',
	FlagsCg = 'FlagsCg',
	FlagsCh = 'FlagsCh',
	FlagsCi = 'FlagsCi',
	FlagsCk = 'FlagsCk',
	FlagsCl = 'FlagsCl',
	FlagsCm = 'FlagsCm',
	FlagsCn = 'FlagsCn',
	FlagsCo = 'FlagsCo',
	FlagsCr = 'FlagsCr',
	FlagsCu = 'FlagsCu',
	FlagsCv = 'FlagsCv',
	FlagsCw = 'FlagsCw',
	FlagsCx = 'FlagsCx',
	FlagsCy = 'FlagsCy',
	FlagsCz = 'FlagsCz',
	FlagsDe = 'FlagsDe',
	FlagsDj = 'FlagsDj',
	FlagsDk = 'FlagsDk',
	FlagsDm = 'FlagsDm',
	FlagsDo = 'FlagsDo',
	FlagsDz = 'FlagsDz',
	FlagsEc = 'FlagsEc',
	FlagsEe = 'FlagsEe',
	FlagsEg = 'FlagsEg',
	FlagsEh = 'FlagsEh',
	FlagsEr = 'FlagsEr',
	FlagsEsCa = 'FlagsEsCa',
	FlagsEs = 'FlagsEs',
	FlagsEt = 'FlagsEt',
	FlagsEu = 'FlagsEu',
	FlagsFi = 'FlagsFi',
	FlagsFj = 'FlagsFj',
	FlagsFk = 'FlagsFk',
	FlagsFm = 'FlagsFm',
	FlagsFo = 'FlagsFo',
	FlagsFr = 'FlagsFr',
	FlagsGa = 'FlagsGa',
	FlagsGbEng = 'FlagsGbEng',
	FlagsGbNir = 'FlagsGbNir',
	FlagsGbSct = 'FlagsGbSct',
	FlagsGbWls = 'FlagsGbWls',
	FlagsGb = 'FlagsGb',
	FlagsGd = 'FlagsGd',
	FlagsGe = 'FlagsGe',
	FlagsGf = 'FlagsGf',
	FlagsGg = 'FlagsGg',
	FlagsGh = 'FlagsGh',
	FlagsGi = 'FlagsGi',
	FlagsGl = 'FlagsGl',
	FlagsGm = 'FlagsGm',
	FlagsGn = 'FlagsGn',
	FlagsGp = 'FlagsGp',
	FlagsGq = 'FlagsGq',
	FlagsGr = 'FlagsGr',
	FlagsGs = 'FlagsGs',
	FlagsGt = 'FlagsGt',
	FlagsGu = 'FlagsGu',
	FlagsGw = 'FlagsGw',
	FlagsGy = 'FlagsGy',
	FlagsHk = 'FlagsHk',
	FlagsHm = 'FlagsHm',
	FlagsHn = 'FlagsHn',
	FlagsHr = 'FlagsHr',
	FlagsHt = 'FlagsHt',
	FlagsHu = 'FlagsHu',
	FlagsId = 'FlagsId',
	FlagsIe = 'FlagsIe',
	FlagsIl = 'FlagsIl',
	FlagsIm = 'FlagsIm',
	FlagsIn = 'FlagsIn',
	FlagsIo = 'FlagsIo',
	FlagsIq = 'FlagsIq',
	FlagsIr = 'FlagsIr',
	FlagsIs = 'FlagsIs',
	FlagsIt = 'FlagsIt',
	FlagsJe = 'FlagsJe',
	FlagsJm = 'FlagsJm',
	FlagsJo = 'FlagsJo',
	FlagsJp = 'FlagsJp',
	FlagsKe = 'FlagsKe',
	FlagsKg = 'FlagsKg',
	FlagsKh = 'FlagsKh',
	FlagsKi = 'FlagsKi',
	FlagsKm = 'FlagsKm',
	FlagsKn = 'FlagsKn',
	FlagsKp = 'FlagsKp',
	FlagsKr = 'FlagsKr',
	FlagsKw = 'FlagsKw',
	FlagsKy = 'FlagsKy',
	FlagsKz = 'FlagsKz',
	FlagsLa = 'FlagsLa',
	FlagsLb = 'FlagsLb',
	FlagsLc = 'FlagsLc',
	FlagsLi = 'FlagsLi',
	FlagsLk = 'FlagsLk',
	FlagsLr = 'FlagsLr',
	FlagsLs = 'FlagsLs',
	FlagsLt = 'FlagsLt',
	FlagsLu = 'FlagsLu',
	FlagsLv = 'FlagsLv',
	FlagsLy = 'FlagsLy',
	FlagsMa = 'FlagsMa',
	FlagsMc = 'FlagsMc',
	FlagsMd = 'FlagsMd',
	FlagsMe = 'FlagsMe',
	FlagsMf = 'FlagsMf',
	FlagsMg = 'FlagsMg',
	FlagsMh = 'FlagsMh',
	FlagsMk = 'FlagsMk',
	FlagsMl = 'FlagsMl',
	FlagsMm = 'FlagsMm',
	FlagsMn = 'FlagsMn',
	FlagsMo = 'FlagsMo',
	FlagsMp = 'FlagsMp',
	FlagsMq = 'FlagsMq',
	FlagsMr = 'FlagsMr',
	FlagsMs = 'FlagsMs',
	FlagsMt = 'FlagsMt',
	FlagsMu = 'FlagsMu',
	FlagsMv = 'FlagsMv',
	FlagsMw = 'FlagsMw',
	FlagsMx = 'FlagsMx',
	FlagsMy = 'FlagsMy',
	FlagsMz = 'FlagsMz',
	FlagsNa = 'FlagsNa',
	FlagsNc = 'FlagsNc',
	FlagsNe = 'FlagsNe',
	FlagsNf = 'FlagsNf',
	FlagsNg = 'FlagsNg',
	FlagsNi = 'FlagsNi',
	FlagsNl = 'FlagsNl',
	FlagsNo = 'FlagsNo',
	FlagsNp = 'FlagsNp',
	FlagsNr = 'FlagsNr',
	FlagsNu = 'FlagsNu',
	FlagsNz = 'FlagsNz',
	FlagsOm = 'FlagsOm',
	FlagsPa = 'FlagsPa',
	FlagsPe = 'FlagsPe',
	FlagsPf = 'FlagsPf',
	FlagsPg = 'FlagsPg',
	FlagsPh = 'FlagsPh',
	FlagsPk = 'FlagsPk',
	FlagsPl = 'FlagsPl',
	FlagsPm = 'FlagsPm',
	FlagsPn = 'FlagsPn',
	FlagsPr = 'FlagsPr',
	FlagsPs = 'FlagsPs',
	FlagsPt = 'FlagsPt',
	FlagsPw = 'FlagsPw',
	FlagsPy = 'FlagsPy',
	FlagsQa = 'FlagsQa',
	FlagsRe = 'FlagsRe',
	FlagsRo = 'FlagsRo',
	FlagsRs = 'FlagsRs',
	FlagsRu = 'FlagsRu',
	FlagsRw = 'FlagsRw',
	FlagsSa = 'FlagsSa',
	FlagsSb = 'FlagsSb',
	FlagsSc = 'FlagsSc',
	FlagsSd = 'FlagsSd',
	FlagsSe = 'FlagsSe',
	FlagsSg = 'FlagsSg',
	FlagsSh = 'FlagsSh',
	FlagsSi = 'FlagsSi',
	FlagsSj = 'FlagsSj',
	FlagsSk = 'FlagsSk',
	FlagsSl = 'FlagsSl',
	FlagsSm = 'FlagsSm',
	FlagsSn = 'FlagsSn',
	FlagsSo = 'FlagsSo',
	FlagsSr = 'FlagsSr',
	FlagsSs = 'FlagsSs',
	FlagsSt = 'FlagsSt',
	FlagsSv = 'FlagsSv',
	FlagsSx = 'FlagsSx',
	FlagsSy = 'FlagsSy',
	FlagsSz = 'FlagsSz',
	FlagsTc = 'FlagsTc',
	FlagsTd = 'FlagsTd',
	FlagsTf = 'FlagsTf',
	FlagsTg = 'FlagsTg',
	FlagsTh = 'FlagsTh',
	FlagsTj = 'FlagsTj',
	FlagsTk = 'FlagsTk',
	FlagsTl = 'FlagsTl',
	FlagsTm = 'FlagsTm',
	FlagsTn = 'FlagsTn',
	FlagsTo = 'FlagsTo',
	FlagsTr = 'FlagsTr',
	FlagsTt = 'FlagsTt',
	FlagsTv = 'FlagsTv',
	FlagsTw = 'FlagsTw',
	FlagsTz = 'FlagsTz',
	FlagsUa = 'FlagsUa',
	FlagsUg = 'FlagsUg',
	FlagsUm = 'FlagsUm',
	FlagsUn = 'FlagsUn',
	FlagsUs = 'FlagsUs',
	FlagsUy = 'FlagsUy',
	FlagsUz = 'FlagsUz',
	FlagsVa = 'FlagsVa',
	FlagsVc = 'FlagsVc',
	FlagsVe = 'FlagsVe',
	FlagsVg = 'FlagsVg',
	FlagsVi = 'FlagsVi',
	FlagsVn = 'FlagsVn',
	FlagsVu = 'FlagsVu',
	FlagsWf = 'FlagsWf',
	FlagsWs = 'FlagsWs',
	FlagsXk = 'FlagsXk',
	FlagsYe = 'FlagsYe',
	FlagsYt = 'FlagsYt',
	FlagsZa = 'FlagsZa',
	FlagsZm = 'FlagsZm',
	FlagsZw = 'FlagsZw',
	HeartSolid = 'HeartSolid',
	Help = 'Help',
	Imposition = 'Imposition',
	Infinity = 'Infinity',
	Info = 'Info',
	LargeCheck = 'LargeCheck',
	LargeClose = 'LargeClose',
	LargeMinus = 'LargeMinus',
	LargePlus = 'LargePlus',
	Lighting = 'Lighting',
	LineItem1 = 'LineItem1',
	Link = 'Link',
	List = 'List',
	Lock = 'Lock',
	LogOut = 'LogOut',
	Loupe = 'Loupe',
	Minus = 'Minus',
	Ok = 'Ok',
	OpenExtended = 'OpenExtended',
	Order1 = 'Order1',
	OrderShip = 'OrderShip',
	People = 'People',
	PieChart = 'PieChart',
	Pin = 'Pin',
	Plus = 'Plus',
	Promo = 'Promo',
	Ring = 'Ring',
	Settings = 'Settings',
	Share = 'Share',
	Ship = 'Ship',
	SmallExplosion = 'SmallExplosion',
	SmallHeart = 'SmallHeart',
	SmallOval = 'SmallOval',
	SmallSpeed = 'SmallSpeed',
	SmallWarning = 'SmallWarning',
	SortAZ = 'SortAZ',
	SortZA = 'SortZA',
	Star = 'Star',
	Trash = 'Trash',
	UploadFile = 'UploadFile',
	Upload = 'Upload',
	UsersList = 'UsersList',
	Users = 'Users',
	Warning = 'Warning',
	Workflow = 'Workflow',
	User = 'User'
}