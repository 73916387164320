import React, { useCallback } from 'react'
import cxBinder from 'classnames/bind'
import styles from '../ColumnsPopup.module.scss'
import { ColumnsPopupConfig, OnColumnsChange } from '../common'

const cx = cxBinder.bind(styles)

export const ColumnsPopupHeader = ({
  config = [],
  updateConfig,
  labels: { enableAll = 'Enable All', disableAll = 'DisableAll' } = {}
}: {
  config: ColumnsPopupConfig[]
  updateConfig: OnColumnsChange
  labels?: {
    enableAll?: string
    disableAll?: string
  }
}) => {
  const enableAllFn = useCallback(() => {
    const enabled = config.map((c) => ({ ...c, isVisible: true }))
    return updateConfig(enabled)
  }, [config, updateConfig])

  const disableAllFn = useCallback(() => {
    const disabled = config.map((c) => ({ ...c, isVisible: false }))
    return updateConfig(disabled)
  }, [config, updateConfig])

  return (
    <div className={cx('header')}>
      <div
        className={cx('header__action')}
        onClick={enableAllFn}
        e2e-target="button"
        e2e-target-name="enable-all-filters"
      >
        {enableAll}
      </div>
      <div
        className={cx('header__action')}
        onClick={disableAllFn}
        e2e-target="button"
        e2e-target-name="disable-all-filters"
      >
        {disableAll}
      </div>
    </div>
  )
}
