import {
  useState,
  useRef,
  useCallback,
  useMemo,
  MutableRefObject,
  useEffect
} from 'react'

interface UsePopupProsBase {
  onOpen?: Function
  onClose?: Function
  initiallyOpen?: boolean
}

interface UsePopupProsWithAnchorRef<T> extends UsePopupProsBase {
  anchorEl?: never
  anchorRef?: MutableRefObject<T>
}
interface UsePopupProsWithAnchorEl<T> extends UsePopupProsBase {
  anchorEl?: T
  anchorRef?: never
}

type UsePopupProps<T> =
  | UsePopupProsWithAnchorRef<T>
  | UsePopupProsWithAnchorEl<T>

export const usePopup = <T extends HTMLElement | null>({
  onOpen,
  onClose,
  anchorRef: passedAnchorRef,
  anchorEl: passedAnchorEl,
  initiallyOpen
}: UsePopupProps<T> = {}) => {
  const [referenceElement, setReferenceElement] = useState<T | undefined>(
    passedAnchorEl
  )
  useEffect(() => {
    setReferenceElement(passedAnchorEl)
  }, [passedAnchorEl])
  const anchorRef = useRef<T | null>(passedAnchorRef?.current || null)

  const open = useCallback(
    (event = undefined) => {
      if (anchorRef.current || event?.currentTarget) {
        event?.stopPropagation?.()
        setReferenceElement(anchorRef.current || event?.currentTarget)
        onOpen && onOpen()
      }
    },
    [onOpen]
  )

  const close = useCallback(() => {
    setReferenceElement(undefined)
    onClose && onClose()
  }, [onClose])

  const toggle = useMemo(() => (referenceElement ? close : open), [
    referenceElement,
    open,
    close
  ])

  const isOpen = useMemo(() => !!referenceElement, [referenceElement])

  useEffect(() => {
    if (initiallyOpen) open()
  }, [initiallyOpen])

  return {
    anchorEl: referenceElement,
    anchorRef,
    isOpen,
    open,
    close,
    toggle
  }
}
