import React, { FC } from 'react'
import cxBinder from 'classnames/bind'

import Modal, { ModalSize } from './../../molecules/Modal/Modal'
import Button, { ButtonSize, ButtonMode } from './../../atoms/Button/Button'

import styles from './ConfirmModal.module.scss'

const cx = cxBinder.bind(styles)

interface ConfirmModalProps {
  open: boolean
  isLoading?: boolean
  header?: string
  content: React.ReactNode
  onConfirm?: () => void
  onClose: () => void
  labels: { confirm: string; cancel: string }
  destructiveBtn?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  open,
  header,
  onConfirm,
  content,
  onClose,
  labels: { confirm, cancel },
  destructiveBtn = false,
  isLoading = false,
  e2eTarget = 'modal',
  e2eTargetName = 'confirm',
}) => {
  const confirmBtnMode = destructiveBtn
    ? ButtonMode.destructive
    : ButtonMode.primary
  return (
    <Modal
      open={open}
      header={header}
      onClose={onClose}
      width={ModalSize.xs}
      height={ModalSize.auto}
      borderFooter={false}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      rightFooter={
        <div className={cx('confirm-modal__btn-wrapper')}>
          <div className={cx('confirm-modal__btn-item')}>
            <Button
              onClick={onClose}
              size={ButtonSize.large}
              mode={ButtonMode.secondary}
              e2e-target="button"
              e2e-target-name="close"
            >
              {cancel}
            </Button>
          </div>
          {onConfirm && (
            <div className={cx('confirm-modal__btn-item')}>
              <Button
                isLoading={isLoading}
                onClick={onConfirm}
                size={ButtonSize.large}
                mode={confirmBtnMode}
                e2e-target="button"
                e2e-target-name="confirm"
              >
                {confirm}
              </Button>
            </div>
          )}
        </div>
      }
    >
      <div className={cx('confirm-modal__text-content')}>{content}</div>
    </Modal>
  )
}

export { ConfirmModal, ConfirmModal as default }
