import { createContext } from 'react'

type FormContextProps = {
  typeName: string | unknown
  setValue: (field: string, value: unknown) => void
}

export const FormContext = createContext<FormContextProps>({
  setValue: () => {
    return
  },
  typeName: 'corrugated'
})
