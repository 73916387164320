import cxBinder from 'classnames/bind'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import styles from './ValuesSwitch.module.scss'

const cx = cxBinder.bind(styles)

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type InputWitoutSize = Omit<
  React.HTMLProps<HTMLInputElement>,
  'size' | 'onChange'
>

export interface ValuesSwitchProps<T> {
  disabled?: boolean
  onChange?: (value: T) => void
  value: T
  data: Array<{ label: string; value: T }>
  e2eTarget?: string
  e2eTargetName?: string
  [key: string]: any
}

const ValuesSwitch = <T extends unknown>({
  onChange,
  e2eTarget = 'label-switch',
  e2eTargetName,
  data,
  value,
  disabled,
  ...other
}: ValuesSwitchProps<T>) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [bubbleSettings, setBubbleSettings] = useState({
    width: 0,
    left: 0
  })
  const ref = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setCurrentValue(value)
  }, [value, setCurrentValue])

  useEffect(() => {
    if (ref.current && wrapperRef.current) {
      const { width, left } = ref.current?.getBoundingClientRect()
      const { left: wrapperLeft } = wrapperRef.current?.getBoundingClientRect()
      setBubbleSettings({
        width,
        left: left - wrapperLeft - 1 // 1 == border size
      })
    }
  }, [currentValue])

  const handleChange = (value: T) => {
    let newValue = value
    if (currentValue === newValue) {
      let index = data.findIndex((element) => element.value === newValue) + 1
      if (index > data.length - 1) {
        index = 0
      }
      newValue = data[index].value
    }
    setCurrentValue(newValue)
    onChange && onChange(newValue)
  }

  return (
    <div
      className={cx('wrapper', {
        'wrapper--disabled': disabled
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      ref={wrapperRef}
    >
      <div className={cx('bubble')} style={bubbleSettings}></div>
      {data.map((element) => (
        <div
          className={cx('label', {
            'label--current': element.value === currentValue
          })}
          key={`option-${element.value}`}
          ref={element.value === currentValue ? ref : null}
          onClick={() => handleChange(element.value)}
        >
          {element.label}
        </div>
      ))}
    </div>
  )
}

export { ValuesSwitch, ValuesSwitch as default }
