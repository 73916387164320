import React, {
  ChangeEvent,
  MutableRefObject,
  KeyboardEvent,
  MouseEvent,
  HTMLProps
} from 'react'
import cxBinder from 'classnames/bind'

import Icon from '../../atoms/Icon/Icon'
import { TIcon } from '../../atoms/Icon/IconTypes'
import { Input, InputSize } from '../../atoms/Input/Input'

import styles from './SearchInput.module.scss'

const cx = cxBinder.bind(styles)

type InputWithoutSize = Omit<React.HTMLProps<HTMLInputElement>, 'size'>

export interface SearchInputProps extends InputWithoutSize {
  error?: boolean
  size?: InputSize
  value?: string
  onSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void
  inputRef?: MutableRefObject<HTMLInputElement | null>
  forwardRef?: React.ForwardedRef<HTMLDivElement>
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  onClick?: (e: MouseEvent<HTMLInputElement>) => void
  onClear?: () => void
  onFocus?: () => void
  onBlur?: () => void
  e2eTarget?: string
  e2eTargetName?: string
}

const SearchInput = ({
  error = false,
  disabled = false,
  size = InputSize.large,
  value = '',
  onSearchChange,
  inputRef,
  forwardRef,
  onKeyDown,
  onClick,
  e2eTarget = 'search',
  e2eTargetName,
  ...other
}: SearchInputProps) => {
  return (
    <div
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      ref={forwardRef}
    >
      <Input
        error={error}
        disabled={disabled}
        size={size}
        value={value}
        clearable={true}
        icon={<Icon icon={TIcon.Loupe} className={cx('loupe-icon')} />}
        onChange={onSearchChange}
        innerRef={inputRef}
        onKeyDown={onKeyDown}
        onClick={onClick}
        e2eTarget="input"
        e2eTargetName="search"
        {...other}
      />
    </div>
  )
}

export { SearchInput, SearchInput as default }
