import React, { lazy, Suspense, memo, MouseEvent, useMemo } from 'react'
import Loading, { LoadingSize } from './../Loading/Loading'

import { TIcon } from './IconTypes'

export enum IconSize {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l'
}

const svgSize = {
  xxs: 8,
  xs: 10,
  s: 16,
  m: 24,
  l: 32
}

interface IconProps {
  icon: TIcon
  size?: IconSize
  className?: string
  onClick?: (e: MouseEvent<SVGElement>) => void
  e2eTarget?: string
  e2eTargetName?: string
}

const loadedIcons = {}

const Icon = memo(
  ({
    icon,
    size = IconSize.s,
    e2eTarget = 'icon',
    e2eTargetName,
    ...other
  }: IconProps) => {
    if (!loadedIcons[icon]) {
      loadedIcons[icon] = useMemo(
        () => lazy(() => import(`./icons/${icon}Icon`)),
        [icon]
      )
    }
    const IconComponent = loadedIcons[icon]

    return (
      <Suspense fallback={<Loading size={LoadingSize[size]} />}>
        <IconComponent
          {...other}
          width={svgSize[size]}
          height={svgSize[size]}
          e2e-target={e2eTarget}
          e2e-target-name={e2eTargetName}
        />
      </Suspense>
    )
  }
)

export { Icon, Icon as default }
