import React from 'react'
import { useObserver } from 'mobx-react-lite'
import cxBinder from 'classnames/bind'
import { useTableContext } from '../../hooks/table-store'
import Checkbox from '../../../../atoms/Checkbox/Checkbox'

import style from '../../Table.module.scss'
import { useRowContext } from '../../hooks/row-store'
import CellBase from './CellBase'

const cx = cxBinder.bind(style)

export const BulkCell = () => {
  const table = useTableContext()
  const row = useRowContext()

  return useObserver(() => {
    if (!table.bulk.isEnabled) return null
    return (
      <CellBase isSticky={true} e2e-target="cell-bulk">
        <div
          className={cx('table__bulk-checkbox', {
            'table__bulk-checkbox--header': row.context === 'header',
            'background--selected': 'data-row' && row.isSelected,
            'background--error': 'data-row' && row.showError
          })}
          onClick={(e) => row.toggleBulk(e)}
          e2e-target="button"
          e2e-target-name="toggle-bulk"
        >
          {' '}
          <Checkbox
            checked={row.isSelected}
            indeterminate={row.isBulkIntermediate}
            readOnly
            e2e-target="checkbox"
            e2e-target-name="bulk"
          />
        </div>
      </CellBase>
    )
  })
}

export default BulkCell
