import React, { MutableRefObject, useCallback } from 'react'
import cxBinder from 'classnames/bind'
import styles from './Textarea.module.scss'
const cx = cxBinder.bind(styles)

export interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  error?: boolean
  disabled?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  innerRef?:
    | MutableRefObject<HTMLTextAreaElement | null>
    | ((node: HTMLTextAreaElement) => void)
}

const Textarea = (props: TextareaProps) => {
  const {
    disabled = false,
    error = false,
    innerRef,
    e2eTarget = 'textarea',
    e2eTargetName,
    ...other
  } = props
  const classNames = cx('default', {
    error,
    disabled
  })

  return (
    <textarea
      ref={innerRef}
      className={classNames}
      disabled={disabled}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    />
  )
}

export { Textarea, Textarea as default }
