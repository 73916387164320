import React, { useCallback, useMemo, useEffect } from 'react'
import uuid from 'uuid/v4'
import cxBinder from 'classnames/bind'
import hotkeys from 'hotkeys-js'

import Icon from '../../atoms/Icon/Icon'
import { TIcon } from '../../atoms/Icon/IconTypes'

import styles from './Pagination.module.scss'
import { generatePagination } from './helpers'

const cx = cxBinder.bind(styles)

export interface PaginationProps {
  page?: number
  count?: number
  totalVisible?: number
  displayQuickNavigation?: boolean
  onPageChange?: Function
  isHotkeys?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const Pagination = (props: PaginationProps) => {
  const {
    page = 1,
    count = 1,
    onPageChange,
    displayQuickNavigation = true,
    totalVisible = 7,
    isHotkeys = false,
    e2eTarget = 'pagination',
    e2eTargetName
  } = props

  const goToFirstPage = useCallback(() => {
    onPageChange && onPageChange(1)
  }, [onPageChange])
  const goToLastPage = useCallback(() => {
    onPageChange && onPageChange(count)
  }, [onPageChange, count])
  const goToNextPage = useCallback(() => {
    page < count && onPageChange && onPageChange(page + 1)
  }, [onPageChange, page, count])
  const goToPrevPage = useCallback(() => {
    page > 1 && onPageChange && onPageChange(page - 1)
  }, [onPageChange, page])
  const goToPage = useCallback(
    (item) => {
      typeof item === 'number' && onPageChange && onPageChange(item)
    },
    [onPageChange]
  )

  const firstDisabled = useMemo(() => page === 1, [page])
  const lastDisabled = useMemo(() => page === count, [page, count])

  const pagination = useMemo(
    () => generatePagination(page, count, totalVisible),
    [page, count, totalVisible]
  )

  const keyName = useMemo(() => 'left,right', [])

  const handleKeyDown = useCallback(
    (event, handler) => {
      if (isHotkeys) {
        handler.key === 'left' ? goToPrevPage() : goToNextPage()
      }
    },
    [goToPrevPage, goToNextPage, isHotkeys]
  )

  useEffect(() => {
    hotkeys(keyName, (event, handler) => {
      handleKeyDown(event, handler)
    })

    return () => {
      hotkeys.unbind(keyName)
    }
  }, [keyName, handleKeyDown])

  return (
    <div
      className={cx('pagination')}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {displayQuickNavigation && (
        <>
          <button
            key="page-first"
            className={cx('icon-button')}
            disabled={firstDisabled}
            onClick={goToFirstPage}
          >
            <Icon icon={TIcon.ArrowsType2Left} className={cx('icon')} />
          </button>
          <button
            key="page-previous"
            className={cx('icon-button')}
            disabled={firstDisabled}
            onClick={goToPrevPage}
          >
            <Icon icon={TIcon.ArrowsType1Left} className={cx('icon')} />
          </button>
        </>
      )}

      {pagination.map((item) => (
        <span
          key={`page-${typeof item === 'number' ? item : uuid()}`}
          onClick={() => goToPage(item)}
          className={cx('page', {
            'page--highlight': item === page,
            'page--ellipsis': typeof item === 'string'
          })}
        >
          {item}
        </span>
      ))}
      {displayQuickNavigation && (
        <>
          <button
            key="page-next"
            className={cx('icon-button')}
            disabled={lastDisabled}
            onClick={goToNextPage}
          >
            <Icon icon={TIcon.ArrowsType1Right} className={cx('icon')} />
          </button>
          <button
            key="page-last"
            className={cx('icon-button')}
            disabled={lastDisabled}
            onClick={goToLastPage}
          >
            <Icon icon={TIcon.ArrowsType2Right} className={cx('icon')} />
          </button>
        </>
      )}
    </div>
  )
}

export { Pagination, Pagination as default }
