import React from 'react'
import cxBinder from 'classnames/bind'

import { Loading, LoadingSize } from '../../atoms/Loading/Loading'

import styles from './Button.module.scss'
import { NavLink, NavLinkProps } from 'react-router-dom'

const cx = cxBinder.bind(styles)

export enum ButtonSize {
  large = 'large',
  small = 'small'
}

export enum ButtonMode {
  primary = 'primary',
  secondary = 'secondary',
  secondaryAlternative = 'secondary-alternative',
  destructive = 'destructive'
}

interface ButtonBaseProps {
  size?: ButtonSize
  fullWidth?: boolean
  mode?: ButtonMode
  isLoading?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

export interface ButtonPlainProps
  extends ButtonBaseProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Button = (props: ButtonPlainProps) => {
  const {
    children,
    size = ButtonSize.small,
    fullWidth,
    mode = ButtonMode.primary,
    isLoading = false,
    e2eTarget = 'button',
    e2eTargetName,
    ...other
  } = props

  return (
    <button
      className={cx('button', {
        [`button--${size}`]: size,
        [`button--${mode}`]: mode,
        [`button--full-width`]: fullWidth
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...(isLoading ? null : other)}
    >
      <span
        className={cx('button__text', { 'button__text--loading': isLoading })}
      >
        {children}
      </span>
      <span className={cx({ 'button--loading': isLoading })}>
        {isLoading ? (
          <Loading size={size === 'small' ? LoadingSize.s : LoadingSize.m} />
        ) : null}
      </span>
    </button>
  )
}

export interface ButtonLinkProps
  extends ButtonBaseProps,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  disabled?: boolean
}

const LinkAsButton = (props: ButtonLinkProps) => {
  const {
    children,
    disabled,
    size = ButtonSize.small,
    mode = ButtonMode.primary,
    e2eTarget = 'button-link',
    e2eTargetName,
    ...other
  } = props

  return (
    <a
      className={cx('button', {
        [`button--${size}`]: size,
        [`button--${mode}`]: mode,
        [`button--disabled`]: disabled
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      {children}
    </a>
  )
}
export interface ButtonNavLinkProps extends ButtonBaseProps, NavLinkProps {
  to: string
  disabled?: boolean
}

const NavLinkAsButton = (props: ButtonNavLinkProps) => {
  const {
    children,
    to,
    disabled,
    size = ButtonSize.small,
    mode = ButtonMode.primary,
    e2eTarget = 'button-link',
    e2eTargetName,
    ...other
  } = props

  return (
    <NavLink
      to={to}
      className={cx('button', {
        [`button--${size}`]: size,
        [`button--${mode}`]: mode,
        [`button--disabled`]: disabled
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      {children}
    </NavLink>
  )
}

export { Button, NavLinkAsButton, LinkAsButton, Button as default }
