import { ChangeEvent } from 'react'

export const simulateInputChange = (
  input: HTMLInputElement,
  value: string | string[] | number | undefined
) => {
  const inputValueDescriptor = Object.getOwnPropertyDescriptor(
    HTMLInputElement.prototype,
    'value'
  )
  const nativeInputValueSetter =
    inputValueDescriptor && inputValueDescriptor.set
  nativeInputValueSetter && nativeInputValueSetter.call(input, value)

  const inputEvent = new Event('input', { bubbles: true })
  input.dispatchEvent(inputEvent)
  return (inputEvent as unknown) as ChangeEvent<HTMLInputElement>
}
