
// ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
//        AUTOGENERATED FILE
// ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗

export enum CountryFlags {
	'ad' = 'FlagsAd',
	'ae' = 'FlagsAe',
	'af' = 'FlagsAf',
	'ag' = 'FlagsAg',
	'ai' = 'FlagsAi',
	'al' = 'FlagsAl',
	'am' = 'FlagsAm',
	'ao' = 'FlagsAo',
	'aq' = 'FlagsAq',
	'ar' = 'FlagsAr',
	'as' = 'FlagsAs',
	'at' = 'FlagsAt',
	'au' = 'FlagsAu',
	'aw' = 'FlagsAw',
	'ax' = 'FlagsAx',
	'az' = 'FlagsAz',
	'ba' = 'FlagsBa',
	'bb' = 'FlagsBb',
	'bd' = 'FlagsBd',
	'be' = 'FlagsBe',
	'bf' = 'FlagsBf',
	'bg' = 'FlagsBg',
	'bh' = 'FlagsBh',
	'bi' = 'FlagsBi',
	'bj' = 'FlagsBj',
	'bl' = 'FlagsBl',
	'bm' = 'FlagsBm',
	'bn' = 'FlagsBn',
	'bo' = 'FlagsBo',
	'bq' = 'FlagsBq',
	'br' = 'FlagsBr',
	'bs' = 'FlagsBs',
	'bt' = 'FlagsBt',
	'bv' = 'FlagsBv',
	'bw' = 'FlagsBw',
	'by' = 'FlagsBy',
	'bz' = 'FlagsBz',
	'ca' = 'FlagsCa',
	'cc' = 'FlagsCc',
	'cd' = 'FlagsCd',
	'cf' = 'FlagsCf',
	'cg' = 'FlagsCg',
	'ch' = 'FlagsCh',
	'ci' = 'FlagsCi',
	'ck' = 'FlagsCk',
	'cl' = 'FlagsCl',
	'cm' = 'FlagsCm',
	'cn' = 'FlagsCn',
	'co' = 'FlagsCo',
	'cr' = 'FlagsCr',
	'cu' = 'FlagsCu',
	'cv' = 'FlagsCv',
	'cw' = 'FlagsCw',
	'cx' = 'FlagsCx',
	'cy' = 'FlagsCy',
	'cz' = 'FlagsCz',
	'de' = 'FlagsDe',
	'dj' = 'FlagsDj',
	'dk' = 'FlagsDk',
	'dm' = 'FlagsDm',
	'do' = 'FlagsDo',
	'dz' = 'FlagsDz',
	'ec' = 'FlagsEc',
	'ee' = 'FlagsEe',
	'eg' = 'FlagsEg',
	'eh' = 'FlagsEh',
	'er' = 'FlagsEr',
	'es-ca' = 'FlagsEsCa',
	'es' = 'FlagsEs',
	'et' = 'FlagsEt',
	'eu' = 'FlagsEu',
	'fi' = 'FlagsFi',
	'fj' = 'FlagsFj',
	'fk' = 'FlagsFk',
	'fm' = 'FlagsFm',
	'fo' = 'FlagsFo',
	'fr' = 'FlagsFr',
	'ga' = 'FlagsGa',
	'gb-eng' = 'FlagsGbEng',
	'gb-nir' = 'FlagsGbNir',
	'gb-sct' = 'FlagsGbSct',
	'gb-wls' = 'FlagsGbWls',
	'gb' = 'FlagsGb',
	'gd' = 'FlagsGd',
	'ge' = 'FlagsGe',
	'gf' = 'FlagsGf',
	'gg' = 'FlagsGg',
	'gh' = 'FlagsGh',
	'gi' = 'FlagsGi',
	'gl' = 'FlagsGl',
	'gm' = 'FlagsGm',
	'gn' = 'FlagsGn',
	'gp' = 'FlagsGp',
	'gq' = 'FlagsGq',
	'gr' = 'FlagsGr',
	'gs' = 'FlagsGs',
	'gt' = 'FlagsGt',
	'gu' = 'FlagsGu',
	'gw' = 'FlagsGw',
	'gy' = 'FlagsGy',
	'hk' = 'FlagsHk',
	'hm' = 'FlagsHm',
	'hn' = 'FlagsHn',
	'hr' = 'FlagsHr',
	'ht' = 'FlagsHt',
	'hu' = 'FlagsHu',
	'id' = 'FlagsId',
	'ie' = 'FlagsIe',
	'il' = 'FlagsIl',
	'im' = 'FlagsIm',
	'in' = 'FlagsIn',
	'io' = 'FlagsIo',
	'iq' = 'FlagsIq',
	'ir' = 'FlagsIr',
	'is' = 'FlagsIs',
	'it' = 'FlagsIt',
	'je' = 'FlagsJe',
	'jm' = 'FlagsJm',
	'jo' = 'FlagsJo',
	'jp' = 'FlagsJp',
	'ke' = 'FlagsKe',
	'kg' = 'FlagsKg',
	'kh' = 'FlagsKh',
	'ki' = 'FlagsKi',
	'km' = 'FlagsKm',
	'kn' = 'FlagsKn',
	'kp' = 'FlagsKp',
	'kr' = 'FlagsKr',
	'kw' = 'FlagsKw',
	'ky' = 'FlagsKy',
	'kz' = 'FlagsKz',
	'la' = 'FlagsLa',
	'lb' = 'FlagsLb',
	'lc' = 'FlagsLc',
	'li' = 'FlagsLi',
	'lk' = 'FlagsLk',
	'lr' = 'FlagsLr',
	'ls' = 'FlagsLs',
	'lt' = 'FlagsLt',
	'lu' = 'FlagsLu',
	'lv' = 'FlagsLv',
	'ly' = 'FlagsLy',
	'ma' = 'FlagsMa',
	'mc' = 'FlagsMc',
	'md' = 'FlagsMd',
	'me' = 'FlagsMe',
	'mf' = 'FlagsMf',
	'mg' = 'FlagsMg',
	'mh' = 'FlagsMh',
	'mk' = 'FlagsMk',
	'ml' = 'FlagsMl',
	'mm' = 'FlagsMm',
	'mn' = 'FlagsMn',
	'mo' = 'FlagsMo',
	'mp' = 'FlagsMp',
	'mq' = 'FlagsMq',
	'mr' = 'FlagsMr',
	'ms' = 'FlagsMs',
	'mt' = 'FlagsMt',
	'mu' = 'FlagsMu',
	'mv' = 'FlagsMv',
	'mw' = 'FlagsMw',
	'mx' = 'FlagsMx',
	'my' = 'FlagsMy',
	'mz' = 'FlagsMz',
	'na' = 'FlagsNa',
	'nc' = 'FlagsNc',
	'ne' = 'FlagsNe',
	'nf' = 'FlagsNf',
	'ng' = 'FlagsNg',
	'ni' = 'FlagsNi',
	'nl' = 'FlagsNl',
	'no' = 'FlagsNo',
	'np' = 'FlagsNp',
	'nr' = 'FlagsNr',
	'nu' = 'FlagsNu',
	'nz' = 'FlagsNz',
	'om' = 'FlagsOm',
	'pa' = 'FlagsPa',
	'pe' = 'FlagsPe',
	'pf' = 'FlagsPf',
	'pg' = 'FlagsPg',
	'ph' = 'FlagsPh',
	'pk' = 'FlagsPk',
	'pl' = 'FlagsPl',
	'pm' = 'FlagsPm',
	'pn' = 'FlagsPn',
	'pr' = 'FlagsPr',
	'ps' = 'FlagsPs',
	'pt' = 'FlagsPt',
	'pw' = 'FlagsPw',
	'py' = 'FlagsPy',
	'qa' = 'FlagsQa',
	're' = 'FlagsRe',
	'ro' = 'FlagsRo',
	'rs' = 'FlagsRs',
	'ru' = 'FlagsRu',
	'rw' = 'FlagsRw',
	'sa' = 'FlagsSa',
	'sb' = 'FlagsSb',
	'sc' = 'FlagsSc',
	'sd' = 'FlagsSd',
	'se' = 'FlagsSe',
	'sg' = 'FlagsSg',
	'sh' = 'FlagsSh',
	'si' = 'FlagsSi',
	'sj' = 'FlagsSj',
	'sk' = 'FlagsSk',
	'sl' = 'FlagsSl',
	'sm' = 'FlagsSm',
	'sn' = 'FlagsSn',
	'so' = 'FlagsSo',
	'sr' = 'FlagsSr',
	'ss' = 'FlagsSs',
	'st' = 'FlagsSt',
	'sv' = 'FlagsSv',
	'sx' = 'FlagsSx',
	'sy' = 'FlagsSy',
	'sz' = 'FlagsSz',
	'tc' = 'FlagsTc',
	'td' = 'FlagsTd',
	'tf' = 'FlagsTf',
	'tg' = 'FlagsTg',
	'th' = 'FlagsTh',
	'tj' = 'FlagsTj',
	'tk' = 'FlagsTk',
	'tl' = 'FlagsTl',
	'tm' = 'FlagsTm',
	'tn' = 'FlagsTn',
	'to' = 'FlagsTo',
	'tr' = 'FlagsTr',
	'tt' = 'FlagsTt',
	'tv' = 'FlagsTv',
	'tw' = 'FlagsTw',
	'tz' = 'FlagsTz',
	'ua' = 'FlagsUa',
	'ug' = 'FlagsUg',
	'um' = 'FlagsUm',
	'un' = 'FlagsUn',
	'us' = 'FlagsUs',
	'uy' = 'FlagsUy',
	'uz' = 'FlagsUz',
	'va' = 'FlagsVa',
	'vc' = 'FlagsVc',
	've' = 'FlagsVe',
	'vg' = 'FlagsVg',
	'vi' = 'FlagsVi',
	'vn' = 'FlagsVn',
	'vu' = 'FlagsVu',
	'wf' = 'FlagsWf',
	'ws' = 'FlagsWs',
	'xk' = 'FlagsXk',
	'ye' = 'FlagsYe',
	'yt' = 'FlagsYt',
	'za' = 'FlagsZa',
	'zm' = 'FlagsZm',
	'zw' = 'FlagsZw'
}