import React, { FC, useCallback } from 'react'
import cxBinder from 'classnames/bind'
import { useObserver } from 'mobx-react-lite'
import { kebabCase } from 'lodash'

import { Icon, TIcon } from '../../../../atoms'

import style from '../../Table.module.scss'
import { useTableContext } from '../../hooks/table-store'

import CellBase from './CellBase'
import CellContent from './CellContent'
import CellTextWrapper from './CellTextWrapper'

const cx = cxBinder.bind(style)

export const HeaderCell: FC<{ configUUID: string }> = ({ configUUID }) => {
  const table = useTableContext()
  const config = table.columns.configsObject[configUUID]
  const onSort = useCallback(() => config.onSort(), [config])

  const configName = kebabCase(configUUID)

  return useObserver(() => {
    const classes = cx('table__header-cell', {
      'table__header-cell--sorted': config.isSorted,
      'table__header-cell--sortable': config.isSortable
    })

    const label = config.shouldUseCellWrapper ? (
      <CellTextWrapper>{config.label}</CellTextWrapper>
    ) : (
      config.label
    )

    return (
      <CellBase
        isSorted={config.isSorted}
        e2e-target="cell-header"
        e2e-target-name={configName}
        widthStyle={config.widthStyle}
      >
        <div
          className={classes}
          onClick={onSort}
          e2e-target="button"
          e2e-target-name="sort-columns"
        >
          <CellContent
            e2e-target="cell-content-header"
            e2e-target-name={configName}
            align={config.align}
          >
            {label}
          </CellContent>
          {config.isSorted && config.sortDirection !== 'DESC' && (
            <span className={cx('table__header-cell--sorted__icon')}>
              <Icon
                icon={TIcon.SortAZ}
                e2e-target="icon"
                e2e-target-name="sort-asc"
              />
            </span>
          )}
          {config.isSorted && config.sortDirection === 'DESC' && (
            <span className={cx('table__header-cell--sorted__icon')}>
              <Icon
                icon={TIcon.SortZA}
                e2e-target="icon"
                e2e-target-name="sort-desc"
              />
            </span>
          )}
        </div>
      </CellBase>
    )
  })
}
