import React, {
  Context,
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect
} from 'react'
import { SingleRowStore, TRowContext } from '../stores'
import { useTableContext } from './table-store'

export interface RowContextConfig {
  context: TRowContext
}

export const tableRowContext: Context<SingleRowStore | null> = createContext<SingleRowStore | null>(
  null
)

export const RowProvider = ({
  children,
  rowConfig,
  value: initialStore = null
}: {
  children: ReactNode
  rowConfig?: RowContextConfig
  value?: SingleRowStore | null
}) => {
  const [store, setStore] = useState<SingleRowStore | null>(initialStore)

  const tableStore = useTableContext()

  useEffect(() => {
    if (rowConfig) {
      if (store) {
        store.update(rowConfig.context)
      } else {
        setStore(new SingleRowStore(tableStore, rowConfig.context))
      }
    }
  }, [tableStore, store, rowConfig])

  if (!store) return null

  return (
    <tableRowContext.Provider value={store}>
      {children}
    </tableRowContext.Provider>
  )
}

export const useRowContext = () => {
  const context = useContext(tableRowContext)
  if (!context) {
    throw new Error('useRowContext must be used within a RowProvider.')
  }
  return context
}
