import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cxBinder from 'classnames/bind'
import { Popup, PopupProps } from '../../atoms/'
import styles from './MenuPopup.module.scss'

const cx = cxBinder.bind(styles)

export type MenuProps = {
  label: ReactNode | string
  id: string
  icon?: ReactNode
  onClick?: () => void
  link?: string
  newTabLink?: boolean
  state?: { [key: string]: string }
  expand?: boolean
  isDisabled?: boolean
  isCurrent?: boolean
  logoMono?: ReactNode
  logoColor?: ReactNode
}

interface MenuPopupProps extends PopupProps {
  menu: MenuProps[][]
}

const MenuPopup = (props: MenuPopupProps) => {
  const {
    menu,
    parentWidth = false,
    e2eTarget = 'popup',
    e2eTargetName = 'menu-popup',
    ...popupProps
  } = props

  const icon = (item) => (
    <span
      className={cx({
        ['icon']: item.icon
      })}
      e2e-target="icon"
      e2e-target-name={item.id}
    >
      {item.icon}
    </span>
  )

  const currentLogo = (item) => (
    <span
      className={cx({ ['logo--multi']: item.logoColor })}
      e2e-target="logo-element"
      e2e-target-name="active"
    >
      {item.logoColor}
    </span>
  )

  const disabledLogo = (item) => (
    <span
      className={cx({ ['logo--enabled']: item.logoMono })}
      e2e-target="logo-element"
      e2e-target-name="disabled"
    >
      {item.logoMono}
    </span>
  )

  const defaultLogo = (item) => (
    <React.Fragment>
      <span
        className={cx({
          ['logo--mono']: item.logoMono
        })}
        e2e-target="logo-element"
        e2e-target-name="mono"
      >
        {item.logoMono}
      </span>
      <span
        className={cx({
          ['logo--multi']: item.logoColor,
          ['logo--multi--hover']: item.logoColor
        })}
        e2e-target="logo-element"
        e2e-target-name="multi"
      >
        {item.logoColor}
      </span>
    </React.Fragment>
  )

  const logo = (item) => (
    <span
      className={cx({
        ['logo']: item.logoMono
      })}
      e2e-target="logo-group"
    >
      {item.isCurrent
        ? currentLogo(item)
        : item.isDisabled
        ? disabledLogo(item)
        : defaultLogo(item)}
    </span>
  )

  const itemElement = (item) => (
    <li
      className={cx('item', { ['item--disabled']: item.isDisabled })}
      key={item.id}
      onClick={item.onClick}
      e2e-target="option-group"
    >
      {item.icon ? icon(item) : null}
      {item.logoColor ? logo(item) : null}
      <span
        className={cx('label', {
          ['label--with-icon']: Boolean(item.icon || item.logoColor),
          ['label--expand']: item.expand
        })}
        e2e-target="text"
      >
        {item.label}
      </span>
    </li>
  )

  return (
    <Popup
      parentWidth={parentWidth}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...popupProps}
    >
      <ul className={styles.list}>
        {menu.map((section, i) => (
          <div
            className={cx('section')}
            key={`section-${i}`}
            e2e-target="menu-list"
          >
            {section.map((item) => {
              return (
                <div
                  key={`${item.id}`}
                  e2e-target="menu-option"
                  e2e-target-name={item.id}
                >
                  {item.link &&
                  !item.isDisabled &&
                  !item.onClick &&
                  !item.isCurrent ? (
                    <div e2e-target="label" e2e-target-name="link">
                      <Link
                        to={{ pathname: `${item.link}`, state: item.state }}
                        target={item.newTabLink ? '_blank' : ''}
                        className={cx('link')}
                        onClick={popupProps.onClickOutside}
                      >
                        {itemElement(item)}
                      </Link>
                    </div>
                  ) : (
                    <div e2e-target="label" e2e-target-name="text">
                      {itemElement(item)}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        ))}
      </ul>
    </Popup>
  )
}

export { MenuPopup, MenuPopup as default }
