import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
  ReactNode,
  FC,
  Children,
  ReactChild
} from 'react'
import cxBinder from 'classnames/bind'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

import { useWindowWidth } from '@react-hook/window-size'

import Icon from '../../atoms/Icon/Icon'
import { TIcon } from '../../atoms/Icon/IconTypes'

import { TabProps } from './Tab'
import styles from './Tabs.module.scss'
import { useLongPress } from './hooks/long-press/long-press'
const cx = cxBinder.bind(styles)

enum TabsMode {
  DARK = 'dark',
  LIGHT = 'light',
  VARIANT = 'variant',
  UNDERLINED = 'underlined'
}

interface TabsProps {
  children: TabProps | TabProps[]
  navItem?: ReactNode
  mode?: TabsMode
  onSort?: (sortedList: string[]) => void
  onSortStart?: () => void
  autoScroll?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

interface SortableListProps extends TabsProps {
  sortableList: string[]
  tabsRef: any
  isDragging: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const SortableItem = SortableElement(
  ({ children }: { children: ReactChild[] }) => <div>{children}</div>
)

const SortableList = SortableContainer((props: SortableListProps) => {
  const {
    children,
    navItem,
    mode = TabsMode.LIGHT,
    sortableList,
    onSort,
    tabsRef,
    isDragging,
    autoScroll,
    e2eTarget,
    e2eTargetName
  } = props

  const activeChild = useMemo(
    () =>
      children &&
      Array.isArray(children) &&
      children.find((child) => child && child.props && child.props.active),
    [children]
  )

  const scrollShift = useMemo(() => 150, [])
  const longPressTime = useMemo(() => 300, [])

  const [scroll, setScroll] = useState<number>(0)
  const [scrollSize, setScrollSize] = useState<number>(0)
  const windowWidth = useWindowWidth()

  const handleScroll = useCallback(
    (shift) => {
      if (!tabsRef || !tabsRef.current) {
        return null
      }
      tabsRef.current.scrollBy({
        left: shift,
        behavior: 'smooth'
      })
    },
    [tabsRef]
  )

  const handleScrollRight = useCallback(() => {
    handleScroll(scrollShift)
  }, [handleScroll, scrollShift])

  const handleScrollLeft = useCallback(() => {
    handleScroll(-scrollShift)
  }, [handleScroll, scrollShift])

  const onScrollChange = useCallback(
    (e) => {
      setScroll(e.target.scrollLeft)
      if (tabsRef && tabsRef.current) {
        setScrollSize(
          tabsRef.current.clientWidth === 0
            ? 0
            : Math.max(
                0,
                tabsRef.current.scrollWidth - tabsRef.current.clientWidth
              )
        )
      }
    },
    [setScroll, tabsRef]
  )

  useEffect(() => {
    if (
      autoScroll &&
      activeChild &&
      activeChild.ref &&
      activeChild.ref.current
    ) {
      activeChild.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center'
      })
    }
  }, [activeChild, autoScroll, scrollSize])

  useEffect(() => {
    onScrollChange({
      target: tabsRef.current
    })
  }, [children, onScrollChange, tabsRef, windowWidth, sortableList])

  const hiddenLeftContent = useMemo(() => scroll === 0, [scroll])
  const isLeftDisabled = useMemo(() => hiddenLeftContent || isDragging, [
    hiddenLeftContent,
    isDragging
  ])
  const hiddenRightContent = useMemo(() => scroll >= scrollSize, [
    scroll,
    scrollSize
  ])
  const isRightDisabled = useMemo(() => hiddenRightContent || isDragging, [
    hiddenRightContent,
    isDragging
  ])

  const leftLongPress = useLongPress(handleScrollLeft, longPressTime)
  const rightLongPress = useLongPress(handleScrollRight, longPressTime)

  const renderTabs = useCallback(
    (children) => {
      let items = sortableList
      return Children.map(children, (child, index) => {
        if (child && child.props && child.props.sortid) {
          const sortid = items[0]
          items = items.slice(1, items.length)
          const sortedChildren = children.find(
            (child) => child.props.sortid === sortid
          )
          return <SortableItem index={index}>{sortedChildren}</SortableItem>
        }
        return React.cloneElement(child, {
          ref: (node) => {
            if (child.ref) child.ref.current = node
          }
        })
      })
    },
    [sortableList]
  )

  return (
    <div
      className={cx('wrapper', {
        [`mode-${mode}`]: mode
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div
        ref={tabsRef}
        className={cx('list', {
          'list--overflow-left': !hiddenLeftContent,
          'list--overflow-right': !hiddenRightContent
        })}
        onScroll={onScrollChange}
      >
        {!hiddenLeftContent && (
          <div
            className={cx('list__shadow-wrapper', {
              'list__shadow-wrapper--left': true
            })}
          ></div>
        )}
        {onSort ? renderTabs(children) : children}
        {!hiddenRightContent && (
          <div
            className={cx('list__shadow-wrapper', {
              'list__shadow-wrapper--right': true
            })}
          ></div>
        )}
      </div>

      <div
        className={cx('button-wrapper', {
          'button-wrapper--none': !scrollSize
        })}
      >
        <button
          className={cx('icon-button', {
            'icon-button--disabled': isLeftDisabled
          })}
          {...leftLongPress}
          onClick={handleScrollLeft}
          type="button"
        >
          <Icon icon={TIcon.ArrowsType2Left} />
        </button>

        <button
          className={cx('icon-button', {
            'icon-button--disabled': isRightDisabled
          })}
          {...rightLongPress}
          onClick={handleScrollRight}
          type="button"
        >
          <Icon icon={TIcon.ArrowsType2Right} />
        </button>
      </div>

      {navItem}
    </div>
  )
})

const Tabs: FC<TabsProps> = ({
  onSort,
  children,
  onSortStart,
  autoScroll = false,
  e2eTarget = 'tabs',
  e2eTargetName,
  ...props
}) => {
  const childrenArray = useMemo(
    () => Children.toArray(children) as TabProps[],
    [children]
  )

  const getSortableElements = useCallback((children) => {
    return Children.map(children, (child) => {
      return child && child.props && child.props.sortid
    })
  }, [])

  const [sortableList, setSortableList] = useState<string[]>([])
  const [isDragging, setIsDragging] = useState<boolean>(false)

  useEffect(() => {
    setSortableList(getSortableElements(childrenArray))
  }, [childrenArray, getSortableElements])

  const tabsRef = useRef<any>()

  const handleSortStart = useCallback(() => {
    setIsDragging(true)
    onSortStart && onSortStart()
  }, [onSortStart])

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const sorted = arrayMove(sortableList, oldIndex, newIndex)
      setSortableList(sorted)
      onSort && onSort(sorted)
      setIsDragging(false)
    },
    [setSortableList, sortableList, onSort]
  )

  return (
    <SortableList
      onSortStart={handleSortStart}
      axis="x"
      lockAxis="x"
      distance={10}
      getContainer={() => tabsRef && tabsRef.current}
      onSortEnd={handleSortEnd}
      sortableList={sortableList}
      onSort={onSort}
      tabsRef={tabsRef}
      helperClass={cx('dragging-item')}
      isDragging={isDragging}
      autoScroll={autoScroll}
      e2eTarget={e2eTarget}
      e2eTargetName={e2eTargetName}
      {...props}
    >
      {childrenArray}
    </SortableList>
  )
}

export { TabsMode, Tabs, Tabs as default }
