import { observable, computed } from 'mobx'

export type SortDirection = 'ASC' | 'DESC'

export interface SortByConfig {
  field?: string
  direction?: SortDirection
}

export type SortFn = (field: string, direction: SortDirection) => void

export class SortStore {
  @observable public sortField?: string
  @observable public sortDirection?: SortDirection
  public onSort?: SortFn

  constructor(sortBy?: SortByConfig, onSort?: SortFn) {
    this.update(sortBy, onSort)
  }

  public update(sortBy: SortByConfig = {}, onSort?: SortFn): SortStore {
    if (this.sortField !== sortBy.field) {
      this.sortField = sortBy.field
    }
    if (this.sortDirection !== sortBy.direction) {
      this.sortDirection = sortBy.direction
    }

    this.onSort = onSort

    return this
  }

  @computed get sortedBy() {
    return {
      field: this.sortField,
      direction: this.sortDirection
    }
  }
}
