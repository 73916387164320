import React, { FC, ReactNode, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Loading, LoadingSize } from '../../atoms/Loading/Loading'

import cxBinder from 'classnames/bind'
import styles from './FileUpload.module.scss'

const cx = cxBinder.bind(styles)
interface FileUploadProps {
  uploadProgress?: boolean
  typeAcceptFile?: string
  uploadAcceptFile: (acceptFile: FormData) => void
  minSize?: number
  maxSize?: number
  multiple?: boolean
  noClick?: boolean
  labels: {
    accept: ReactNode | string
    reject: ReactNode | string
  }
  e2eTarget?: string
  e2eTargetName?: string
}

const FileUpload: FC<FileUploadProps> = ({
  typeAcceptFile,
  minSize = 0,
  maxSize = 5242880,
  uploadAcceptFile,
  children,
  multiple = false,
  noClick = true,
  labels: { reject, accept },
  uploadProgress = false,
  e2eTarget = 'upload-file',
  e2eTargetName
}) => {
  const onUploadAction = (acceptFile: File[], rejectFile: File[]) => {
    if (rejectFile.length) return false

    const dataFile = new FormData()

    acceptFile.map((file) => {
      dataFile.append('file', file)
    })

    uploadAcceptFile(dataFile)
  }

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
    open,
    acceptedFiles
  } = useDropzone({
    onDrop: onUploadAction,
    accept: typeAcceptFile,
    minSize,
    maxSize,
    multiple,
    noClick
  })

  useEffect(() => {
    document.addEventListener('uploadFile', open)
    return () => {
      document.removeEventListener('uploadFile', open)
    }
  }, [acceptedFiles, open])

  return (
    <div
      className={cx('file-upload-wrapper')}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div className={cx('file-upload-container')} {...getRootProps()}>
        <div
          className={cx('file-upload-layer', {
            'file-upload-layer--show': isDragActive || uploadProgress,
            'file-upload-layer--error': isDragReject
          })}
          e2e-target="file-upload-info"
          e2e-target-name={
            isDragActive || uploadProgress ? 'active' : 'rejected'
          }
        >
          <div
            className={cx('file-upload-text', {
              'file-upload-text--error': isDragReject
            })}
            e2e-target="file-upload-content"
          >
            {isDragAccept && !isDragReject && <>{accept}</>}
            {isDragReject && <>{reject}</>}
            {uploadProgress && <Loading size={LoadingSize.xl} />}
          </div>
        </div>
        <input {...getInputProps()} e2e-target="input" e2e-target-name="file" />
        {children}
      </div>
    </div>
  )
}

export { FileUpload, FileUpload as default }
