import React, { FC, memo } from 'react'
import cxBinder from 'classnames/bind'
import { useObserver } from 'mobx-react-lite'

import style from '../Table.module.scss'
import { useTableContext } from '../hooks/table-store'
import { useRowContext } from '../hooks/row-store'
import { CellBase } from './Cell'

const cx = cxBinder.bind(style)

export const Group: FC = memo(() => {
  const table = useTableContext()
  const row = useRowContext()

  return useObserver(() => {
    if (table.isLoading || row.isFake) return null

    return (
      <>
        {row.shouldShowGroup && (
          <>
            {table.bulk.isEnabled && <CellBase isSticky={true} />}
            <div
              className={cx('table__row-group', {
                'table__row-group--with-bulk': table.bulk.isEnabled
              })}
            >
              <div
                className={cx('table__row-group__inner', {
                  'table__row-group__inner--with-bulk': table.bulk.isEnabled
                })}
              >
                {row.groupValue}
              </div>
            </div>
          </>
        )}
      </>
    )
  })
})
