
// ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
//        AUTOGENERATED FILE
// ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗


export enum TType {
	Bold24_28 = "typography--24-28-bold",
	Bold16_24 = "typography--16-24-bold",
	Bold16_22 = "typography--16-22-bold",
	Bold14_20 = "typography--14-20-bold",
	Bold12_18 = "typography--12-18-bold",
	BoldCaps12_18 = "typography--12-18-bold--caps",
	Bold10_16 = "typography--10-16-bold",
	BoldCaps10_16 = "typography--10-16-bold--caps",
	Medium24_24 = "typography--24-24-medium",
	Medium16_24 = "typography--16-24-medium",
	Medium16_22 = "typography--16-22-medium",
	Medium14_20 = "typography--14-20-medium",
	Medium12_18 = "typography--12-18-medium",
	Medium10_16 = "typography--10-16-medium",
	Medium18_21 = "typography--18-21-medium",
	Regular16_24 = "typography--16-24",
	Regular16_22 = "typography--16-22",
	Regular14_16 = "typography--14-16",
	Regular14_20 = "typography--14-20",
	Regular12_18 = "typography--12-18",
	Regular12_16 = "typography--12-16",
	Regular10_16 = "typography--10-16"
}