import React, { ReactNode } from 'react'

import cxBinder from 'classnames/bind'

import style from './Card.module.scss'

const cx = cxBinder.bind(style)

export enum ShadowSize {
  small = 'small',
  large = 'large'
}

export enum TypeCard {
  primary = 'primary',
  secondary = 'secondary'
}

export enum CardPadding {
  none = 'none',
  small = 'small',
  medium = 'medium',
  big = 'big'
}

export enum CardHeight {
  auto = 'auto'
}

interface CardProps {
  children: ReactNode
  type?: TypeCard
  height?: CardHeight
  rounded?: boolean
  border?: boolean
  padding?: CardPadding
  shadowSize?: ShadowSize
  e2eTarget?: string
  e2eTargetName?: string
}

const Card = ({
  children,
  type = TypeCard.primary,
  rounded = false,
  border = false,
  height,
  padding,
  shadowSize,
  e2eTarget = 'card',
  e2eTargetName
}: CardProps) => {
  return (
    <div
      className={cx('card', {
        'card--rounded': rounded,
        'card--border': border,
        [`card--shadow-${shadowSize}`]: shadowSize,
        [`card--${type}`]: type,
        [`card--padding-${padding}`]: padding,
        [`card--height-${height}`]: height
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {children}
    </div>
  )
}

export { Card, Card as default }
