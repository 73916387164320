import React, { ReactNode } from 'react'
import cxBinder from 'classnames/bind'
import styles from './OptionRenderer.module.scss'
import { ValueProps } from '../../types'
const cx = cxBinder.bind(styles)

interface OptionRendererProps {
  label: ReactNode
  value: ValueProps
  optionRenderer?: (label: ReactNode, value: ValueProps) => ReactNode
}
export const OptionRenderer = ({
  label,
  optionRenderer,
  value
}: OptionRendererProps) => {
  if (optionRenderer) {
    return <>{optionRenderer(label, value)} </>
  }
  return <div className={cx('label')}>{label}</div>
}
