import { useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import { PSSFormik } from '../../../../types'

export const useClearEmptyValues = (formik: PSSFormik) => {
  useEffect(() => {
    const reducedValues = Object.keys(formik.values).reduce(
      (values, valueKey) => {
        const value = formik.values[valueKey]
        if (
          (Array.isArray(value) && value.length <= 0) ||
          `${value}`.trim() === ''
        ) {
          return values
        }

        return {
          ...values,
          [valueKey]: value
        }
      },
      {}
    )
    if (!isEqual(reducedValues, formik.values)) {
      formik.setValues(reducedValues)
    }
  }, [formik.values])
}
