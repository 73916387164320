import React, {
  FocusEvent,
  CSSProperties,
  useEffect,
  useMemo,
  useRef
} from 'react'
import cxBinder from 'classnames/bind'

import { Checkbox, Icon, TIcon } from '../../atoms'
import styles from './MultiToggleSelect.module.scss'

const cx = cxBinder.bind(styles)

interface MultiToggleSelectCustomOptionProps {
  disabled?: boolean
  customOption?: boolean
  customOptionLabel?: string
  customValue: string
  isChecked?: boolean
  setCustomValue: (value: string) => void
  toggleCheck: () => void
}

export const MultiToggleSelectCustomOption = (
  props: MultiToggleSelectCustomOptionProps
) => {
  const {
    disabled,
    customOption,
    customOptionLabel = '',
    customValue,
    isChecked,
    setCustomValue,
    toggleCheck
  } = props

  const inputRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (inputRef.current && (customValue === undefined || customValue === '')) {
      inputRef.current.textContent = ''
    }
  }, [customValue])

  useEffect(() => {
    if (inputRef.current && customValue) {
      inputRef.current.textContent = customValue
    }
  }, [customValue])

  const handleInputChange = ({
    target: { textContent }
  }: FocusEvent<HTMLSpanElement>) => {
    if (textContent === null || textContent === '') {
      if (isChecked) toggleCheck()
      return
    }
    setCustomValue(textContent)
  }

  const handleKeyDown = ({ key }: KeyboardEvent) => {
    if (key === 'Enter') {
      inputRef.current?.blur()
    }
  }

  useEffect(() => {
    inputRef.current?.addEventListener('keydown', handleKeyDown)
    return () => {
      inputRef.current?.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const dataDisabled = useMemo(() => {
    return disabled === true || undefined
  }, [disabled])

  if (!customOption) {
    return null
  }

  return (
    <div
      className={cx('wrapper__item', {
        'wrapper__item--custom': true,
        'wrapper__item--selected': isChecked
      })}
      data-disabled={dataDisabled}
    >
      <div className={cx('wrapper__item--custom__checkbox')}>
        <Checkbox
          checked={isChecked}
          value={customValue}
          disabled={disabled}
          onChange={toggleCheck}
        />
      </div>
      <Icon className={cx('wrapper__item--custom__icon')} icon={TIcon.Edit} />
      <span
        ref={inputRef}
        tabIndex={disabled ? -1 : 0}
        style={{ '--placeholder': `"${customOptionLabel}"` } as CSSProperties}
        className={cx('wrapper__item--custom__span')}
        onBlur={handleInputChange}
        role="textbox"
        contentEditable
        data-disabled={dataDisabled}
      />
    </div>
  )
}
