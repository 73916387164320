import React, { ReactNode } from 'react'
import cxBinder from 'classnames/bind'

import styles from './Chip.module.scss'

const cx = cxBinder.bind(styles)

export enum ChipSize {
  large = 'large',
  small = 'small'
}

export enum ChipMode {
  navigation = 'navigation',
  info = 'info',
  navigationDark = 'navigation-dark'
}

interface ChipBaseProps {
  children: ReactNode
  size: ChipSize
  mode: ChipMode
  e2eTarget?: string
  e2eTargetName?: string
}

const Chip = (props: ChipBaseProps) => {
  const {
    children,
    size,
    mode,
    e2eTarget = 'chip',
    e2eTargetName,
    ...other
  } = props

  return (
    <button
      className={cx(
        'chip',
        mode === ChipMode.navigationDark && `chip--navigation`,
        {
          [`chip--${size}`]: size,
          [`chip--${mode}`]: mode
        }
      )}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      {children}
    </button>
  )
}

export { Chip, Chip as default }
