import React from 'react'
import cxBinder from 'classnames/bind'

import style from '../Table.module.scss'
import { useTableContext } from '../hooks'

const cx = cxBinder.bind(style)

export const Shadow = ({
  shadowLeft,
  shadowRight
}: {
  shadowLeft: boolean
  shadowRight: boolean
}) => {
  const table = useTableContext()
  return (
    <>
      {shadowLeft && (
        <div
          className={cx('content__shadow', {
            'content__shadow--left': true,
            'content__shadow--left--bulk': table.bulk.isEnabled,
            'content__shadow--left--with-margin': table.withSpacing
          })}
          e2e-target="table-shadow"
          e2e-target-name="left"
        />
      )}
      {shadowRight && (
        <div
          className={cx('content__shadow', {
            'content__shadow--right': true
          })}
          e2e-target="table-shadow"
          e2e-target-name="right"
        />
      )}
    </>
  )
}

export default Shadow
