import { beforeWrite } from '@popperjs/core'
import { useMemo } from 'react'
import { StrictModifier, Modifier } from 'react-popper'
import { useModalContext } from '../../../molecules/Modal/context'
import { Modifiers, PopperModifiersProps } from '../types'

const offsetModifier: StrictModifier = {
  name: 'offset',
  options: {
    offset: [0, 4]
  }
}

const parentWidthModifier: Modifier<'parentWidth'> = {
  name: 'parentWidth',
  enabled: true,
  phase: beforeWrite,
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    if (state.elements.reference instanceof HTMLElement)
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
  }
}

const preventOverflowModifier: StrictModifier = {
  name: 'preventOverflow',
  options: {
    altAxis: true,
    padding: 4
  }
}

const flipModifier: StrictModifier = {
  name: 'flip',
  options: {
    flipVariations: false
  }
}

const popupInModalModifiers: StrictModifier[] = [
  {
    name: 'flip',
    enabled: false
  },
  {
    name: 'preventOverflow',
    enabled: false
  }
]

const createStaticWidthModifier = (width) => ({
  name: 'parentWidth',
  enabled: true,
  phase: beforeWrite,
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = width
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = width
  }
})

export const usePopperModifiers = ({
  modifiers,
  width,
  parentWidth
}: PopperModifiersProps): Modifiers => {
  const isModalDescendant = !!useModalContext()?.ref.current
  const defaultModifiers = useMemo<Modifiers>(() => {
    const result: Modifiers = [offsetModifier]
    if (!isModalDescendant) {
      result.push(preventOverflowModifier, flipModifier)
    } else {
      result.push(...popupInModalModifiers)
    }
    if (parentWidth && !width) result.push(parentWidthModifier)
    if (width) result.push(createStaticWidthModifier(width))

    return result
  }, [parentWidth, width])

  const popperModifiers = useMemo(
    () => (modifiers ? defaultModifiers.concat(modifiers) : defaultModifiers),
    [defaultModifiers, modifiers]
  )

  return popperModifiers
}
