import React, { ReactNode } from 'react'

import { Pill, PillMode, RoundedPillType, RoundedPillSize } from './Pill'

interface RoundedPillProps {
  size?: RoundedPillSize
  type?: RoundedPillType
  children: ReactNode
  e2eTarget?: string
  e2eTargetName?: string
}

const RoundedPill = (props: RoundedPillProps) => {
  const {
    children,
    size = RoundedPillSize.small,
    type = RoundedPillType.primary,
    e2eTarget = 'pill-rounded',
    e2eTargetName
  } = props

  return (
    <Pill
      size={size}
      type={type}
      mode={PillMode.rounded}
      e2eTarget={e2eTarget}
      e2eTargetName={e2eTargetName}
    >
      {children}
    </Pill>
  )
}

export { RoundedPill, RoundedPill as default }
