import React, { FC, memo } from 'react'
import cxBinder from 'classnames/bind'
import { useRowHandler } from '../hooks/row-handler'
import style from '../Table.module.scss'
import { SeparatorCell } from './Cell'
import BulkCell from './Cell/BulkCell'

const cx = cxBinder.bind(style)

export const Row: FC = memo(() => {
  const {
    isSelected,
    showError,
    isDimmed,
    e2eTarget,
    leftCells,
    rightCells,
    isTableLoading,
    rowKey,
    hoverable,
    clickable,
    onClick
  } = useRowHandler()

  return (
    <div
      className={cx('table__row', {
        'background--selected': isSelected,
        'background--error': showError,
        'table__row--dimmed': isDimmed,
        'background--hoverable': hoverable,
        'background--clickable': clickable
      })}
      onClick={onClick}
      e2e-target={e2eTarget}
      e2e-target-name={rowKey}
    >
      <BulkCell />
      {leftCells}
      {!isTableLoading && <SeparatorCell />}
      {rightCells}
    </div>
  )
})
