import React from 'react'
import cxBinder from 'classnames/bind'
import styles from '../../theme/_typography.scss'
import { TType } from './typography-types'

const cx = cxBinder.bind(styles)

export interface TypographyProps extends React.HTMLProps<HTMLElement> {
  type: TType
  e2eTarget?: string
  e2eTargetName?: string
}

const Typography = (props: TypographyProps) => {
  const {
    children,
    type,
    className,
    e2eTarget = 'typography',
    e2eTargetName,
    ...other
  } = props

  const childProps = {
    className: cx({ [type]: true }),
    ['e2e-target']: e2eTarget,
    ['e2e-target-name']: e2eTargetName,
    ...other
  }
  if (className) {
    childProps.className += ` ${className}`
  }

  return <span {...childProps}>{children}</span>
}

export { Typography, Typography as default }
