import cxBinder from 'classnames/bind'
import React, { ChangeEvent, useEffect, useState } from 'react'
import styles from './Switch.module.scss'

const cx = cxBinder.bind(styles)

export enum SwitchSize {
  small = 'small',
  large = 'large'
}
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type InputWitoutSize = Omit<
  React.HTMLProps<HTMLInputElement>,
  'size' | 'onChange'
>

export interface SwitchProps extends InputWitoutSize {
  disabled?: boolean
  size?: SwitchSize
  checked?: boolean
  onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void
  name: string
  e2eTarget?: string
  e2eTargetName?: string
}

const Switch = ({
  checked = false,
  size = SwitchSize.small,
  onChange,
  e2eTarget = 'switch',
  e2eTargetName,
  value,
  ...other
}: SwitchProps) => {
  const [switchChecked, setSwitchChecked] = useState(checked)

  useEffect(() => {
    setSwitchChecked(checked)
  }, [checked, setSwitchChecked])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSwitchChecked(event.target.checked)
    onChange && onChange(event.target.checked, event)
  }

  return (
    <div
      className={cx('wrapper', {
        [`wrapper--${size}`]: size
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <input
        type="checkbox"
        className={styles.input}
        checked={switchChecked}
        onChange={handleChange}
        value={value}
        {...other}
      />
      <span
        className={cx('slider', {
          [`slider--${size}`]: size
        })}
      ></span>
    </div>
  )
}

export { Switch, Switch as default }
