// we can mix by setting fields from other group into other group
// if something is in order, but does not exist in context - it will be ommited
export const groupsOrder: { [key: string]: (string | string[])[] } = {
  type: [
    'type__name',
    'structural-design__code',
    'structural-design__fefco-code',
    'structural-design__ecma-code',
    'material__lid'
  ],
  size: [
    [
      'size__diameter',
      'size__width',
      'size__depth',
      'size__height',
      'size__length',
      'size__internalexternal'
    ],
    'type__packaging-function'
  ],
  material: [
    'material__type',
    'material__liner-outer',
    'material__liner-inner',
    'material__flute',
    'material__outside',
    'material__inside',
    'material__grammage',
    'material__thickness'
  ],
  print: [
    'print__technique',
    ['print__number-of-colors-outside', 'print__number-of-colors-inside'],
    ['print__colors-outside', 'print__colors-inside'],
    'files__artwork'
  ],
  finish: [
    'finish__lamination-outside',
    'finish__lamination-inside',
    'finish__varnish-outside',
    'finish__varnish-inside',
    'finish__uv-varnish-outside',
    'finish__uv-varnish-inside',
    'finish__uv-varnish-spott-placement',
    'files__uv-varnish-mask',
    'finish__hotstamping',
    'finish__hotstamping-placement',
    'files__hotstamping-mask',
    'finish__coldstamping',
    'finish__coldstamping-placement',
    'files__coldstamping-mask',
    'finish__embossing',
    'finish__embossing-placement',
    'files__embossing-mask',
    'finish__debossing',
    'finish__debossing-placement',
    'files__debossing-mask'
  ],
  addons: ['addons__window', 'addons__window-size', 'addons__window-placement']
}
