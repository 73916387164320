import React, {
  FC,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  RefObject
} from 'react'
import cxBinder from 'classnames/bind'
import { Popup, PopupProps } from '../../../../atoms'
import { SelectData, ValueProps } from '../../types'
import { HandleRef } from '../../hooks'
import { SelectList } from '../select-list/SelectList'
import styles from './SelectPopup.module.scss'

const cx = cxBinder.bind(styles)

interface SelectPopupProps extends Partial<PopupProps> {
  customValue?: SelectData | null
  data?: SelectData[]
  selectedValue?: ValueProps
  onClick: (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => void
  onCustomKeyDown: (
    event: KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => void
  onKeyDown: (event: KeyboardEvent<HTMLDivElement>, value: ValueProps) => void
  refFn: HandleRef
  onCustomValueClick?: (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
    value: ValueProps
  ) => void
  optionRenderer?: (label: ReactNode, value: ValueProps) => ReactNode
  isLoading?: boolean
}

export const SelectPopup: FC<SelectPopupProps> = ({
  children,
  customValue,
  data,
  onClick,
  onCustomKeyDown,
  onCustomValueClick,
  onKeyDown,
  optionRenderer,
  refFn,
  selectedValue,
  isLoading,
  ...popupProps
}) => {
  if ((!data || data.length <= 0) && !customValue && !children) {
    return null
  }

  return (
    <Popup
      placement="bottom-start"
      e2e-target="popup"
      e2e-target-name="select-list-group"
      {...popupProps}
    >
      <div className={cx('select-wrapper')}>
        {children || (
          <SelectList
            data={data}
            selectedValue={selectedValue}
            refFn={refFn}
            customValue={customValue}
            onCustomKeyDown={onCustomKeyDown}
            onCustomValueClick={onCustomValueClick}
            onKeyDown={onKeyDown}
            onClick={onClick}
            optionRenderer={optionRenderer}
            isLoading={isLoading}
          />
        )}
      </div>
    </Popup>
  )
}
