import { useMemo } from 'react'
import { useResizeDetector } from 'react-resize-detector'

export const BREAKPOINT = 720

export const useMobileBreakPoint = () => {
  const { width, ref } = useResizeDetector()

  const isMobile = useMemo(() => width && width <= BREAKPOINT, [width])

  return {
    ref,
    isMobile
  }
}
