import React from 'react'
import cxBinder from 'classnames/bind'

import Icon from '../../atoms/Icon/Icon'
import { TIcon } from '../../atoms/Icon/IconTypes'

import styles from './Checkbox.module.scss'

const cx = cxBinder.bind(styles)

export enum CheckboxSize {
  small = 'small',
  large = 'large'
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type InputWitoutSize = Omit<React.HTMLProps<HTMLInputElement>, 'size'>

export interface CheckboxProps extends InputWitoutSize {
  disabled?: boolean
  error?: boolean
  checked?: boolean
  indeterminate?: boolean
  size?: CheckboxSize
  highlight?: boolean
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  e2eTarget?: string
  e2eTargetName?: string
}

const Checkbox = ({
  checked = false,
  disabled = false,
  indeterminate = false,
  error = false,
  size = CheckboxSize.small,
  highlight = false,
  onChange,
  e2eTarget = 'checkbox',
  e2eTargetName,
  ...other
}: CheckboxProps) => {
  return (
    <div
      className={cx('wrapper', {
        [`wrapper--${size}`]: size
      })}
      e2e-target-name={e2eTargetName}
    >
      <input
        e2e-target={e2eTarget}
        type="checkbox"
        className={styles.input}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        {...other}
      />
      <div
        className={cx('mark', {
          [`mark--highlight`]: highlight,
          checked,
          disabled,
          error
        })}
      >
        {indeterminate ? (
          <Icon icon={TIcon.Minus} className={styles.tick} />
        ) : (
          <Icon icon={TIcon.Check} className={styles.tick} />
        )}
      </div>
    </div>
  )
}

export { Checkbox, Checkbox as default }
