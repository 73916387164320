import { useEffect } from 'react'
import { ValueProps } from '../..'
import { ActiveListItemRef, SearchRef } from '../refs/refs'

interface UseFocusProps {
  searchRef: SearchRef
  activeListItemRef: ActiveListItemRef
  popupOpened: boolean
  withSearch: boolean
  selectedValue: ValueProps
}

export const useFocus = ({
  withSearch,
  popupOpened,
  searchRef,
  activeListItemRef,
  selectedValue
}: UseFocusProps) => {
  useEffect(() => {
    if (withSearch && popupOpened && activeListItemRef?.current) {
      setTimeout(() => activeListItemRef?.current?.focus())
      setTimeout(() => searchRef.current?.focus())
    }
  }, [withSearch, popupOpened, searchRef, activeListItemRef])

  useEffect(() => {
    if (activeListItemRef && activeListItemRef.current && !withSearch) {
      setTimeout(() => activeListItemRef.current?.focus())
    }
  }, [activeListItemRef, selectedValue, popupOpened, withSearch])
}
