import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import styles from '../ColumnsPopup.module.scss'
import { Icon, TIcon } from '../../../atoms'

export const DragHandle = SortableHandle(() => (
  <span className={styles.icon} e2e-target="drag-handle">
    <Icon icon={TIcon.Drag} />
  </span>
))
