import { observable, action, computed } from 'mobx'
import { TableStore } from './table-store'

export class WindowStore {
  @observable private _positionY
  @observable private _width

  constructor(
    private _rootStore: TableStore,
    private _rootElement: Window | HTMLElement = window
  ) {
    this._positionY =
      (<Window>this._rootElement).scrollY ||
      (<HTMLElement>this._rootElement).scrollTop
    this._width =
      (<Window>this._rootElement).innerWidth ||
      (<HTMLElement>this._rootElement).clientWidth

    this.updateWindowPosition()
    this.updateWindowWidth()
    this._rootElement.addEventListener(
      'scroll',
      this.updateWindowPosition,
      true
    )
    this._rootElement.addEventListener('resize', this.updateWindowWidth, true)
  }

  public destructor = () => {
    this._rootElement.removeEventListener(
      'scroll',
      this.updateWindowPosition,
      true
    )
    this._rootElement.removeEventListener(
      'resize',
      this.updateWindowWidth,
      true
    )
  }

  public updateWindowPosition = () => {
    this._rootStore.timeoutsRegistry.setTimeout(
      this.updatePositionY,
      100,
      'window-scroll'
    )
  }

  public updateWindowWidth = () => {
    this._rootStore.timeoutsRegistry.setTimeout(
      this.updateWidth,
      100,
      'window-resize'
    )
  }

  @computed get positionY() {
    return this._positionY
  }

  @computed get width() {
    return this._width
  }

  @action.bound updatePositionY() {
    this._rootStore.timeoutsRegistry.clearStartingWith('window-params-reactor')
    this._positionY =
      (<Window>this._rootElement).scrollY ||
      (<HTMLElement>this._rootElement).scrollTop ||
      0
  }

  @action.bound updateWidth() {
    this._rootStore.timeoutsRegistry.clearStartingWith('window-params-reactor')
    this._width =
      (<Window>this._rootElement).innerWidth ||
      (<HTMLElement>this._rootElement).clientWidth ||
      0
  }
}

export default WindowStore
