import React, { FC, ReactNode, useMemo } from 'react'
import cxBinder from 'classnames/bind'
import { ColumnAlign } from '../../stores/columns'

import style from '../../Table.module.scss'

const cx = cxBinder.bind(style)

export const CellContent: FC<{
  children: ReactNode
  align?: ColumnAlign
  e2eTarget?: string
  e2eTargetName?: string
}> = ({
  children,
  align = 'left',
  e2eTarget = 'cell-content',
  e2eTargetName
}) => {
  const classes = useMemo(
    () =>
      cx('table__cell-content', {
        [`table__cell-content--align-${align}`]: align
      }),
    [align]
  )

  return (
    <span
      className={classes}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {children}
    </span>
  )
}

export default CellContent
