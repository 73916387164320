import { range } from 'lodash'

const calcOdd = (
  page: number,
  count: number,
  totalVisible: number,
  divider: number
) => {
  if (page < divider) {
    const chunk1 = range(1, divider + 1)
    const chunk2 = range(count - divider + 1, count + 1)
    return [...chunk1, '...', ...chunk2]
  }

  if (page === divider || totalVisible === 3) {
    const chunk = range(1, totalVisible - 1)
    return [...chunk, '...', count]
  }

  if (page <= count - divider) {
    const chunk = range(page - divider + 2, page + divider - 1)
    return [1, '...', ...chunk, '...', count]
  }

  if (page === count + 1 - divider) {
    const chunk = range(page - divider + 1, count + 1)
    return [1, '...', ...chunk]
  }

  const chunk1 = range(1, divider + 1)
  const chunk2 = range(count - divider + 1, count + 1)
  return [...chunk1, '...', ...chunk2]
}

const calcEven = (
  page: number,
  count: number,
  totalVisible: number,
  divider: number
) => {
  if (page < divider) {
    const chunk1 = range(1, divider + 1)
    const chunk2 = range(count - divider + 2, count + 1)
    return [...chunk1, '...', ...chunk2]
  }

  if (page === divider) {
    const chunk = range(1, totalVisible - 1)
    return [...chunk, '...', count]
  }

  if (page <= count - divider + 1) {
    const chunk = range(page - divider + 2, page + divider - 2)
    return [1, '...', ...chunk, '...', count]
  }

  if (page === count + 2 - divider) {
    const chunk = range(page - divider + 1, count + 1)
    return [1, '...', ...chunk]
  }

  const chunk1 = range(1, divider + 1)
  const chunk2 = range(count - divider + 2, count + 1)
  return [...chunk1, '...', ...chunk2]
}

export const generatePagination = (
  page: number,
  count: number,
  totalVisible: number
) => {
  if (totalVisible >= count || totalVisible === 0) {
    return range(1, count + 1)
  }

  const divider = (totalVisible - (totalVisible % 2 ? 1 : 0)) / 2

  if (totalVisible % 2 === 1) {
    return calcOdd(page, count, totalVisible, divider)
  }

  return calcEven(page, count, totalVisible, divider)
}
