import React, { useMemo } from 'react'
import logoUrl from '@packhelp/platform-dsl/assets/logo/logo-mono.svg'
import {
  getDielineUrl,
  getInfographicsUrl,
  getPhotoUrl
} from '@packhelp/platform-pss-images'

import { ProductSpecificationAttributes } from '../../types/'
import styles from './Preview.module.scss'

export const Preview = ({
  attributes
}: {
  attributes: ProductSpecificationAttributes
}) => {
  const dieline = useMemo(() => getDielineUrl(attributes), [attributes])
  const photo = useMemo(() => getPhotoUrl(attributes), [attributes])
  const infographic = useMemo(() => getInfographicsUrl(attributes), [
    attributes
  ])
  if (!dieline && !infographic && !photo) {
    return (
      <div className={styles['preview-not-available']}>
        <img src={logoUrl} alt="Preview not available" />
      </div>
    )
  }
  return (
    <div className={styles['preview-container']}>
      {(dieline || infographic) && (
        <div className={styles['preview']}>
          <img src={infographic || dieline} />
        </div>
      )}
      {(photo || (dieline && infographic)) && (
        <div className={styles['preview']}>
          <img src={photo || dieline} />
        </div>
      )}
    </div>
  )
}
