import { useState, useCallback, useLayoutEffect, useRef } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [scrollSize, setScrollSize] = useState<number>(0)
  const [elementWidth, setElementWidth] = useState<number>(0)
  const elementRef = useRef<any>()

  const onScrollChange = useCallback((e) => {
    setScrollPosition(e.target.scrollLeft)
    setElementWidth(e.target.clientWidth)
    setScrollSize(
      e.target.clientWidth === 0
        ? 0
        : e.target.scrollWidth - e.target.clientWidth
    )
  }, [])

  const handleResize = useCallback(
    (entries) => {
      if (!Array.isArray(entries)) {
        return
      }

      for (const entry of entries) {
        onScrollChange(entry)
      }
    },
    [onScrollChange]
  )

  const resizeObserver = new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return
      }
      handleResize(entries)
    })
  })

  useLayoutEffect(() => {
    if (!elementRef.current) {
      return
    }

    resizeObserver.observe(elementRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [onScrollChange, resizeObserver])

  return {
    scrollSize,
    elementRef,
    scrollPosition,
    onScrollChange,
    elementWidth
  }
}
