import React from 'react'
import cxBinder from 'classnames/bind'
import styles from './RadioButton.module.scss'

const cx = cxBinder.bind(styles)

export enum RadioButtonSize {
  small = 'small',
  large = 'large'
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type InputWitoutSize = Omit<React.HTMLProps<HTMLInputElement>, 'size'>

export interface RadioButtonProps extends InputWitoutSize {
  disabled?: boolean
  error?: boolean
  checked?: boolean
  size?: RadioButtonSize
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  e2eTarget?: string
  e2eTargetName?: string
}

const RadioButton = ({
  checked = false,
  disabled = false,
  error = false,
  size = RadioButtonSize.small,
  onChange,
  e2eTarget = 'radio-button',
  e2eTargetName,
  ...other
}: RadioButtonProps) => {
  return (
    <div
      className={cx('wrapper', {
        [`wrapper--${size}`]: size
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <input
        type="radio"
        className={styles.input}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        {...other}
      />
      <div
        className={cx('mark', {
          checked,
          disabled,
          error,
          [`mark--${size}`]: size
        })}
      ></div>
    </div>
  )
}

export { RadioButton, RadioButton as default }
