import React, { useRef } from 'react'
import { Popper, PopupProps, PopupSizeProp, _PopupProps } from './types'
import { usePopupElements } from './hooks/popup-elements'
import { PopupContextProvider } from './context'

const Popup = (props: PopupProps): JSX.Element | null => {
  const { anchorEl, fixPosition } = props
  const popperRef = useRef<Popper>(null)

  if (!anchorEl || !document.contains(anchorEl)) return null

  if (fixPosition && popperRef.current?.update) popperRef.current.update()

  return <_Popup anchorEl={anchorEl} popperRef={popperRef} {...props} />
}

const _Popup = (props: _PopupProps): JSX.Element | null => {
  const {
    header,
    footer,
    noPadding,
    children,
    placement,
    popupSize = PopupSizeProp.auto,
    e2eTarget = 'popup',
    e2eTargetName
  } = props

  const popupRef = useRef<HTMLDivElement>(null)

  const {
    Portal,
    Popper,
    popperProps,
    Wrapper,
    Header,
    Content,
    Footer
  } = usePopupElements(props)

  return (
    <PopupContextProvider value={{ ref: popupRef }}>
      <Portal>
        <div ref={popupRef}>
          <Popper
            noPadding={noPadding}
            data-placement={placement}
            e2e-target={e2eTarget}
            e2e-target-name={e2eTargetName}
            popperProps={popperProps}
          >
            <Wrapper popupSize={popupSize}>
              <Header>{header}</Header>
              <Content>{children}</Content>
              <Footer>{footer}</Footer>
            </Wrapper>
          </Popper>
        </div>
      </Portal>
    </PopupContextProvider>
  )
}

export { Popup, Popup as default }
