import React, { FC } from 'react'
import cxBinder from 'classnames/bind'

import Icon from '../../atoms/Icon/Icon'
import { TIcon } from '../../atoms/Icon/IconTypes'
import { Button, ButtonMode } from '../../atoms/Button/Button'

import styles from './NotificationBar.module.scss'

const cx = cxBinder.bind(styles)

export enum TypeNotification {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error'
}

export const typeIcons = {
  [TypeNotification.info]: (
    <Icon
      icon={TIcon.Info}
      e2eTarget="icon"
      e2eTargetName={TypeNotification.info}
    />
  ),
  [TypeNotification.success]: (
    <Icon
      icon={TIcon.Ok}
      e2eTarget="icon"
      e2eTargetName={TypeNotification.success}
    />
  ),
  [TypeNotification.warning]: (
    <Icon
      icon={TIcon.Warning}
      e2eTarget="icon"
      e2eTargetName={TypeNotification.warning}
    />
  ),
  [TypeNotification.error]: (
    <Icon
      icon={TIcon.Error}
      e2eTarget="icon"
      e2eTargetName={TypeNotification.error}
    />
  )
}

interface ActionsNotificationBar {
  name: string | React.ReactNode
  buttonMode: ButtonMode
  action: () => void
  id: string
}

interface NotificationBarProps {
  type: TypeNotification
  textContent: string | React.ReactNode
  actions?: ActionsNotificationBar[]
  onClose?: () => void
  isVisible: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const NotificationBar: FC<NotificationBarProps> = ({
  type,
  textContent,
  actions,
  onClose,
  isVisible,
  e2eTarget = 'notification-bar',
  e2eTargetName = type
}) => {
  return isVisible ? (
    <div
      className={cx('notification-bar__wrapper')}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div
        className={cx('notification-bar', `notification-bar--${type}`)}
        e2e-target="content"
        e2e-target-name={type}
      >
        <div
          className={cx('notification-bar__content')}
          e2e-target="text-group"
        >
          {typeIcons[type]}
          <p className={cx('notification-bar__text')} e2e-target="text">
            {textContent}
          </p>
        </div>
        <div
          className={cx('notification-bar__content')}
          e2e-target="buttons-group"
        >
          {actions &&
            actions.map(({ name, action, buttonMode, id }) => (
              <div key={id} className={cx('notification-bar__button')}>
                <Button
                  onClick={action}
                  mode={buttonMode}
                  e2e-target="button"
                  e2e-target-name={id}
                >
                  {name}
                </Button>
              </div>
            ))}
          {onClose && (
            <Icon
              icon={TIcon.Close}
              onClick={onClose}
              e2eTarget="icon"
              e2eTargetName="close"
            />
          )}
        </div>
      </div>
    </div>
  ) : null
}

export { NotificationBar, NotificationBar as default }
