import React, { ReactNode } from 'react'
import cxBinder from 'classnames/bind'

import { TIcon, Icon, IconSize } from '../Icon'

import styles from './Badge.module.scss'

const cx = cxBinder.bind(styles)

export enum BadgeColor {
  gray = 'badge-wrapper--gray',
  black = 'badge-wrapper--black',
  paleRed = 'badge-wrapper--pale-red',
  purpleBlue = 'badge-wrapper--purple-blue',
  lightishBlue = 'badge-wrapper--lightish-blue',
  squash = 'badge-wrapper--secondary-squash',
  borderPaleRed = 'badge-wrapper--border-pale-red',
  green = 'badge-wrapper--green',
  kellyGreen = 'badge-wrapper--kelly-green',
  loblolly = 'badge-wrapper--loblolly',
  burningOrange = 'badge-wrapper--burning-orange',
  cerulean = 'badge-wrapper--cerulean',
  cranberry = 'badge-wrapper--cranberry'
}

export enum BadgeSize {
  large = 'large',
  small = 'small'
}

interface BadgeProps {
  badgeColor?: BadgeColor
  size?: BadgeSize
  icon?: TIcon
  children: ReactNode
  onClick?: () => void
  noPointer?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const Badge = (props: BadgeProps) => {
  const {
    badgeColor = BadgeColor.black,
    size = BadgeSize.large,
    icon,
    children,
    onClick = () => null,
    noPointer = false,
    e2eTarget = 'badge',
    e2eTargetName
  } = props
  return (
    <span
      onClick={onClick}
      className={cx('badge-wrapper', {
        [badgeColor]: badgeColor,
        [size]: size,
        'badge-wrapper--no-pointer': noPointer
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {icon && (
        <Icon
          className={styles['badge-wrapper_icon']}
          icon={icon}
          size={IconSize.xxs}
        />
      )}
      {children}
    </span>
  )
}

export { Badge, Badge as default }
