import uuid from 'uuid/v4'

export class TableTimeoutsRegistry {
  private _timeouts = {}

  public setTimeout = (func: Function, delay = 0, signature = uuid()) => {
    this.clearTimeout(signature)
    const timeout = setTimeout(func, delay)
    this._timeouts[signature] = timeout
  }

  public clearAll = () => {
    Object.keys(this._timeouts).forEach((timeoutKey) => {
      this.clearTimeout(timeoutKey)
    })
  }

  public clearStartingWith = (signaturePrefix) => {
    Object.keys(this._timeouts).forEach((timeoutKey) => {
      if (timeoutKey.startsWith(signaturePrefix)) this.clearTimeout(timeoutKey)
    })
  }

  public clearTimeout = (signature) => {
    if (this._timeouts[signature]) {
      clearTimeout(this._timeouts[signature])
      delete this._timeouts[signature]
    }
  }
}

export default TableTimeoutsRegistry
