import { useCallback, useEffect } from 'react'

export const usePopupClickOutside = ({
  onClickOutside,
  anchorEl,
  popperEl
}: {
  onClickOutside?: () => void
  anchorEl?: HTMLElement | null
  popperEl?: HTMLElement | null
}): void => {
  const handleClickOutside = useCallback(
    (event) => {
      if (
        !onClickOutside ||
        !anchorEl ||
        !popperEl ||
        !document.contains(event.target) ||
        anchorEl.contains(event.target) ||
        popperEl.contains(event.target) ||
        event.defaultPrevented
      )
        return

      onClickOutside()
      document.removeEventListener('click', handleClickOutside)
    },
    [anchorEl, popperEl, onClickOutside]
  )

  useEffect(() => {
    if (!onClickOutside) return

    setTimeout(() => document.addEventListener('click', handleClickOutside), 0)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside, onClickOutside])
}
