import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  DropzoneLabelProps,
  FileProps
} from '@packhelp/platform-dsl/components'
import { SpecificationService } from '@packhelp/platform-pss-api'

import { RootContext } from '../../contexts'
import { PSSFormConfigSetupWithUrl } from '../../types/form-configuration'
import { ProductSpecUploaderProps } from './ProductSpecUploader'

export const useProductSpecUploader = (props: ProductSpecUploaderProps) => {
  const { configSetup, callbacks } = useContext(RootContext)
  const firstUpdate = useRef(true)
  const [files, setFiles] = useState<FileProps[] | undefined>(props.value)
  const [rejectedFiles, setRejectedFiles] = useState<FileProps[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { saveFile } = useMemo(
    () =>
      new SpecificationService((configSetup as PSSFormConfigSetupWithUrl)?.url),
    [configSetup]
  )

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    } else {
      props.onChange(files)
    }
  }, [files])

  useEffect(() => {
    setFiles(props.value)
  }, [props.value])

  const onDrop = async (
    acceptedFiles: File[],
    rejectedFilesDropzone: File[]
  ) => {
    setIsLoading(true)
    handleRejectedFiles(rejectedFilesDropzone)
    try {
      const data = await Promise.all(
        acceptedFiles.map((file) => saveFile(file))
      )
      setFiles([...data, ...(files || [])])
    } catch (error) {
      if (callbacks?.onError) {
        callbacks?.onError(error)
      } else {
        console.error('error :>> ', error)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleRejectedFiles = (rejectedFilesDropzone: File[]) => {
    const rejectedFilesMapped: FileProps[] = rejectedFilesDropzone.map(
      (file) => ({
        id: file.name,
        mimeType: file.type,
        name: file.name,
        size: file.size
      })
    )
    setRejectedFiles([...rejectedFiles, ...rejectedFilesMapped])
  }

  const onRemove = (fileToRemove: FileProps) => {
    const filteredFiles = files?.filter((file) => file.id !== fileToRemove.id)
    setFiles(filteredFiles?.length === 0 ? undefined : filteredFiles)
    setRejectedFiles(
      rejectedFiles.filter((file) => file.id !== fileToRemove.id)
    )
  }

  const labels: DropzoneLabelProps = {
    content: 'drop',
    accept: 'good files',
    reject: 'reject',
    sizeError: 'file too big'
  }

  return {
    ...props,
    onDrop,
    labels,
    onRemove,
    files: [...(files || []), ...rejectedFiles],
    isLoading,
    maxSize: 128000000 // 128MB
  }
}
