import React, { ChangeEvent, ReactNode, useMemo, useEffect } from 'react'
import cxBinder from 'classnames/bind'
import { PopupSizeProp, InputSize, usePopup } from '../../atoms/'
import { SearchInput } from '../SearchInput/SearchInput'
import { SelectLabel } from './components/select-label/SelectLabel'
import { SelectPopup } from './components/select-popup/SelectPopup'

import { useAccessibillity } from './hooks/accessibility/accessibility'
import { useFocus } from './hooks/focus/focus'
import { useRefs } from './hooks/refs/refs'
import { useSearchHandler } from './hooks/search-handler/search-handler'

import { useValueHandler } from './hooks/value-handler/value-handler'
import { useCustomValues } from './hooks/custom-values/custom-values'

import { SelectData, SelectSize, ValueProps } from './types'
import styles from './Select.module.scss'

const cx = cxBinder.bind(styles)

export interface SelectProps {
  name: string
  autoWidth?: boolean
  children?: ReactNode
  data?: SelectData[]
  disabled?: boolean
  clearable?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  enableCustom?: boolean
  error?: boolean
  id?: string
  open?: boolean
  placeholder?: ReactNode | string
  popupSize?: PopupSizeProp
  searchPlaceholder?: string
  size?: SelectSize
  value?: ValueProps
  withSearch?: boolean
  searchControlled?: boolean
  isLoading?: boolean
  itemRenderer?: (label: ReactNode, value: ValueProps) => ReactNode
  listItemRenderer?: (label: ReactNode, value: ValueProps) => ReactNode
  onClose?: () => void
  onOpen?: (event: HTMLElement) => void
  onSelect?: (value, event: ChangeEvent<HTMLInputElement>) => void
  onSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const Select = ({
  autoWidth = false,
  searchPlaceholder,
  children,
  data,
  disabled = false,
  clearable = false,
  e2eTarget = 'select',
  e2eTargetName,
  enableCustom = false,
  error = false,
  id,
  itemRenderer,
  listItemRenderer,
  name,
  onClose,
  onOpen,
  onSelect,
  open = false,
  placeholder,
  popupSize = PopupSizeProp.s,
  size = SelectSize.small,
  value,
  withSearch = false,
  searchControlled = false,
  isLoading = false,
  onSearchChange,
  ...other
}: SelectProps) => {
  const enableSearchInput = useMemo(() => withSearch || enableCustom, [
    withSearch,
    enableCustom
  ])
  const {
    activeListItemRef,
    searchRef,
    inputRef,
    wrapperRef,
    handleRef,
    setValueForRef
  } = useRefs()

  const {
    open: openPopup,
    close: closePopup,
    toggle: togglePopup,
    anchorEl: popupAnchorEl,
    isOpen: popupOpened
  } = usePopup({ anchorRef: wrapperRef, onOpen, onClose })

  useEffect(() => (open ? openPopup() : closePopup()), [
    open,
    openPopup,
    closePopup
  ])

  const { selectedValue, handleValueChange, handleClear } = useValueHandler({
    inputRef,
    onSelect,
    closePopup,
    value,
    wrapperRef,
    onValueChange: setValueForRef
  })

  const { handleKeyDown } = useAccessibillity({
    popupOpened,
    closePopup,
    handleValueChange,
    activeListItemRef
  })

  const {
    dataWithCustoms,
    addCustomValue,
    customProposition,
    setCustomProposition,
    keyDownCustomValue
  } = useCustomValues({
    enableCustom,
    data,
    handleValueChange,
    handleKeyDown,
    selectedValue,
    activeListItemRef
  })

  const { handleSearch, filteredData, searchValue } = useSearchHandler({
    data: dataWithCustoms,
    onChange: setCustomProposition,
    popupOpened,
    onSearchChange,
    searchControlled
  })

  const showSearch = useMemo(() => popupOpened && enableSearchInput, [
    popupOpened,
    enableSearchInput
  ])

  useFocus({
    withSearch: enableSearchInput,
    popupOpened,
    searchRef,
    activeListItemRef,
    selectedValue
  })

  return (
    <>
      <input
        ref={inputRef}
        type="hidden"
        className={styles.input}
        disabled={disabled}
        value={(selectedValue as string) || ''}
        name={name}
        id={id}
        e2e-target="input"
        e2e-target-name="select"
      />
      <div
        ref={wrapperRef}
        className={cx('wrapper', {
          'wrapper--disabled': disabled
        })}
        onClick={togglePopup}
        tabIndex={0}
      >
        {showSearch ? (
          <SearchInput
            size={(size as unknown) as InputSize}
            inputRef={searchRef}
            placeholder={searchPlaceholder || 'Search...'}
            value={searchValue}
            onKeyDown={(e) => keyDownCustomValue(e, searchValue)}
            onSearchChange={handleSearch}
            e2eTarget="input"
            e2eTargetName="searchable-select"
          />
        ) : (
          <SelectLabel
            children={children}
            data={dataWithCustoms}
            disabled={disabled}
            onClear={clearable ? handleClear : undefined}
            error={error}
            itemRenderer={itemRenderer}
            placeholder={placeholder}
            popupOpened={popupOpened}
            selectedValue={selectedValue}
            size={size}
            {...other}
          />
        )}
      </div>
      <SelectPopup
        anchorEl={popupAnchorEl}
        customValue={customProposition}
        data={filteredData}
        onClick={handleValueChange}
        onClickOutside={closePopup}
        onCustomValueClick={addCustomValue}
        onKeyDown={handleKeyDown}
        onCustomKeyDown={keyDownCustomValue}
        optionRenderer={listItemRenderer}
        parentWidth={!autoWidth}
        popupSize={popupSize}
        refFn={handleRef}
        selectedValue={selectedValue}
        isLoading={isLoading}
      >
        {children}
      </SelectPopup>
    </>
  )
}

export { Select, Select as default }
