import React, {
  ReactNode,
  useState,
  useCallback,
  useEffect,
  useMemo,
  MutableRefObject
} from 'react'
import cxBinder from 'classnames/bind'
import { includes } from 'lodash'

import { Loading, LoadingSize } from '../../atoms/Loading/Loading'
import SearchInput from '../../molecules/SearchInput/SearchInput'
import { ListItem } from './ListItem'

import styles from './SearchableList.module.scss'

const cx = cxBinder.bind(styles)

export type SearchableListData<T> = {
  name: string
  value: T
}

interface SearchableListProps<T> {
  data: SearchableListData<T>[]
  selectedValue?: string | null
  searchPlaceholder?: string
  noResultInfo?: ReactNode
  isLoading?: boolean
  itemRenderer?: (
    itemData: SearchableListData<T>,
    scrollSize?: number,
    elementRef?: any,
    searchValue?: string
  ) => ReactNode
  onClick?: <T>(itemData: SearchableListData<T>) => void
  onSearchChange?: (searchValue: string) => void
  separator?: (itemData: SearchableListData<T>) => boolean
  inputRef?: MutableRefObject<HTMLInputElement | null>
  noMaxHeight?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const SearchableList = <T extends unknown>(props: SearchableListProps<T>) => {
  const {
    data,
    itemRenderer,
    onClick,
    onSearchChange,
    searchPlaceholder,
    noResultInfo,
    isLoading = false,
    selectedValue = '',
    separator,
    inputRef,
    noMaxHeight = false,
    e2eTarget = 'searchable-list',
    e2eTargetName
  } = props

  const [searchValue, setSearchValue] = useState('')
  const [showResultInfo, setShowResultInfo] = useState(false)

  const filteredData = useMemo(
    () =>
      searchValue
        ? data.filter((listItem) =>
            includes(listItem.name.toLowerCase(), searchValue.toLowerCase())
          )
        : data,
    [data, searchValue]
  )

  useEffect(() => {
    filteredData.length === 0 && !isLoading
      ? setShowResultInfo(true)
      : setShowResultInfo(false)
  }, [filteredData, isLoading])

  const handleItemClick = (listItem: SearchableListData<T>) => {
    onClick && onClick(listItem)
  }

  const onChangeSearch = useCallback(
    ({ target: { value } }) => {
      onSearchChange && onSearchChange(value)
      setSearchValue(value)
    },
    [onSearchChange]
  )

  return (
    <div
      className={cx('searchable-list', {
        'searchable-list--no-max-height': noMaxHeight
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div className={cx('search-wrapper')}>
        <SearchInput
          placeholder={searchPlaceholder}
          onSearchChange={onChangeSearch}
          value={searchValue}
          inputRef={inputRef}
          e2eTarget="input"
          e2eTargetName="searchable-list"
        />
      </div>
      <div className={cx('list-wrapper')} e2e-target="items-group">
        {isLoading ? (
          <div className={cx('search-info')}>
            <Loading size={LoadingSize.m} />
          </div>
        ) : (
          filteredData.map((listItem, i) => (
            <ListItem
              key={i}
              listItem={listItem}
              handleItemClick={handleItemClick}
              searchValue={searchValue}
              selectedValue={selectedValue}
              itemRenderer={itemRenderer}
              separator={separator}
              e2eTarget="list-item"
              e2eTargetName={listItem.value}
            />
          ))
        )}
        {showResultInfo && (
          <div className={cx('search-info')}>{noResultInfo}</div>
        )}
      </div>
    </div>
  )
}

export { SearchableList, SearchableList as default }
