import React from 'react'
import cxBinder from 'classnames/bind'
import Icon from '../Icon/Icon'
import { TIcon as TButtonIcon } from '../Icon/IconTypes'
import style from './ButtonIcon.module.scss'
const cx = cxBinder.bind(style)

export interface ButtonIconProps {
  icon: TButtonIcon
  onClick?: (event: React.MouseEvent) => void
  e2eTarget?: string
  e2eTargetName?: string
}

const ButtonIcon = (props: ButtonIconProps): JSX.Element => {
  const { icon, e2eTarget = 'button-icon', e2eTargetName, ...other } = props
  return (
    <button
      className={cx('buttonIcon')}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      <Icon icon={icon} />
    </button>
  )
}

export { TButtonIcon, ButtonIcon, ButtonIcon as default }
