import React, { ReactNode, useMemo } from 'react'
import { Link } from 'react-router-dom'

import cxBinder from 'classnames/bind'
import styles from './Tabs.module.scss'
const cx = cxBinder.bind(styles)

export interface TabProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  active?: boolean
  highlight?: boolean
  children?: ReactNode
  link?: string
  draggableLink?: boolean
  visible?: boolean
  sortid?: string | number
  e2eTarget?: string
  e2eTargetName?: string
  ref?: React.MutableRefObject<any>
  props?: { [key: string]: any }
}

const Tab = React.forwardRef((props: TabProps, ref: any) => {
  const {
    active,
    highlight,
    children,
    disabled,
    onClick,
    link,
    draggableLink = true,
    visible = true,
    e2eTarget = 'tab',
    e2eTargetName,
    ...other
  } = props

  const elementProps = useMemo(
    () => ({
      tabIndex: disabled ? -1 : 0,
      className: cx('item', {
        'item--active': active,
        'item--highlight': highlight,
        'item--disabled': disabled,
        'item--hidden': !visible
      }),
      onClick,
      ...other
    }),
    [active, disabled, onClick, other, visible, highlight]
  )

  return link ? (
    <Link ref={ref} draggable={draggableLink} to={link} {...elementProps}>
      {children}
    </Link>
  ) : (
    <div
      ref={ref}
      {...elementProps}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {children}
    </div>
  )
})

export { Tab, Tab as default }
