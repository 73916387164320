import React, {
  ReactNode,
  useState,
  ChangeEvent,
  useEffect,
  useRef,
  MutableRefObject
} from 'react'
import cxBinder from 'classnames/bind'

import Icon from '../../atoms/Icon/Icon'
import { TIcon } from '../../atoms/Icon/IconTypes'

import styles from './Input.module.scss'
import { simulateInputChange } from './helpers'

const cx = cxBinder.bind(styles)

export enum InputSize {
  small = 'small',
  large = 'large'
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type InputWithoutSize = Omit<React.HTMLProps<HTMLInputElement>, 'size'>

export interface InputProps extends InputWithoutSize {
  disabled?: boolean
  error?: boolean
  warning?: boolean
  controlled?: boolean
  size?: InputSize
  innerRef?: MutableRefObject<HTMLInputElement | null>
  icon?: ReactNode
  readOnly?: boolean
  clearable?: boolean
  value?: string | number
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  type?: string
  onClear?: () => void
  e2eTarget?: string
  e2eTargetName?: string
  unit?: string
}

const Input = ({
  error = false,
  warning = false,
  disabled = false,
  readOnly = false,
  clearable = false,
  controlled = false,
  size = InputSize.large,
  innerRef,
  icon,
  value = '',
  onChange,
  onClear,
  type = 'text',
  e2eTarget = 'input',
  e2eTargetName,
  unit,
  ...other
}: InputProps) => {
  const [inputValue, setInputValue] = useState<string | number>('')

  const ref = useRef<HTMLInputElement>(null)
  const inputRef = innerRef || ref

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e)
    if (!controlled) {
      const value = e.target.value
      setInputValue(value)
    }
  }

  const handleValueClear = () => {
    setInputValue('')
    onClear && onClear()
    if (inputRef.current) {
      simulateInputChange(inputRef.current, '')
    }
  }

  return (
    <div
      className={cx(`base`, `base--${size}`)}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {icon && (
        <div
          className={cx('base__icon', {
            [`base__icon--${size}`]: size,
            'base__icon--disabled': disabled,
            error,
            warning
          })}
        >
          {icon}
        </div>
      )}

      <input
        className={cx('base__input', {
          [`base__input--${size}`]: size,
          'base__input--icon': icon,
          'base__input--clearable': clearable,
          'base__input--unit': unit,
          error,
          warning
        })}
        ref={inputRef}
        disabled={disabled}
        value={inputValue}
        onChange={handleValueChange}
        readOnly={readOnly}
        type={type}
        {...other}
      />

      <span
        onClick={handleValueClear}
        className={cx('base__clear-icon', {
          [`base__clear-icon--${size}`]: size,
          'base__clear-icon--disabled': disabled,
          'base__clear-icon--hidden': !clearable || !inputValue,
          error,
          warning
        })}
      >
        <Icon icon={TIcon.Close} />
      </span>

      <span
        className={cx('base__unit', {
          'base__unit--hidden': !unit
        })}
      >
        {unit}
      </span>
    </div>
  )
}

export { Input, Input as default }
