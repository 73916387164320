import { useCallback, useRef, useState, MutableRefObject } from 'react'
import { ValueProps } from '../../types'

export type ActiveListItemRef = MutableRefObject<HTMLDivElement | null>
export type InputRef = MutableRefObject<HTMLInputElement | null>
export type SearchRef = MutableRefObject<HTMLInputElement | null>
export type WrapperRef = MutableRefObject<HTMLDivElement | null>
export type HandleRef = (
  value: ValueProps,
  index: number
) => ActiveListItemRef | null
export type SetValueForRef = (value: ValueProps, index: number) => void

export type UseRefs = {
  activeListItemRef: ActiveListItemRef
  inputRef: InputRef
  searchRef: SearchRef
  wrapperRef: WrapperRef
  handleRef: HandleRef
  setValueForRef: SetValueForRef
}

export const useRefs = (): UseRefs => {
  const [valueForRef, setValueForRef] = useState<ValueProps | null>(null)

  const activeListItemRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const searchRef = useRef<HTMLInputElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const handleRef = useCallback<HandleRef>(
    (value: ValueProps, index: number) => {
      if (valueForRef && value === valueForRef) {
        return activeListItemRef
      }
      if (index === 0) {
        return activeListItemRef
      }
      return null
    },
    [valueForRef, activeListItemRef]
  )

  return {
    activeListItemRef,
    inputRef,
    searchRef,
    wrapperRef,
    handleRef,
    setValueForRef
  }
}
