import { useState, useRef, useEffect } from 'react'
import { autorun, reaction } from 'mobx'
import { useTableContext } from './table-store'

export const useShouldTrim = () => {
  const table = useTableContext()

  const [shouldTrim, setShouldTrim] = useState(false)

  const trimRef = useRef<HTMLElement>()

  const offset = 150

  useEffect(
    () =>
      reaction(
        () => {
          const node = trimRef && trimRef.current
          if (node) {
            const nodePos = node.getBoundingClientRect()
            // this condition is here only for autorun to be called while linter "no-unused-expressions" rule is matched
            if (
              table.windowParams.positionY === undefined ||
              table.windowParams.width === undefined
            )
              return shouldTrim
            if (
              (nodePos.top >= -offset &&
                nodePos.top <= window.innerHeight + offset) ||
              (nodePos.bottom >= -offset &&
                nodePos.bottom <= window.innerHeight + offset)
            ) {
              const trimBase = node.clientWidth < node.scrollWidth
              if (trimBase !== shouldTrim) {
                return trimBase
              }
            }
          }
          return shouldTrim
        },
        (trim) => {
          setShouldTrim(trim)
        }
      ),
    []
  )

  return {
    ref: trimRef,
    shouldTrim
  }
}
