import React, { ReactNode } from 'react'
import { Tooltip } from './Tooltip'

export interface ConditionalTooltipProps {
  condition: boolean
  children: ReactNode
  tooltipText?: string | ReactNode
}

export const ConditionalTooltip = (props: ConditionalTooltipProps) => {
  const { condition, children, tooltipText } = props
  return condition ? (
    <Tooltip triggerElement={children} placement={'top'}>
      {tooltipText}
    </Tooltip>
  ) : (
    <>{children}</>
  )
}

export default ConditionalTooltip
