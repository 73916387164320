import React, { useState, useCallback } from 'react'
import cxBinder from 'classnames/bind'
import { Checkbox, CheckboxSize } from '../../atoms/Checkbox/Checkbox'

import Icon from '../../atoms/Icon/Icon'
import { TIcon } from '../../atoms/Icon/IconTypes'

import styles from './Accordion.module.scss'

const cx = cxBinder.bind(styles)

export enum AccordionMode {
  default = 'default',
  select = 'select'
}

export interface AccordionProps {
  open?: boolean
  isChecked?: boolean
  header: React.ReactNode
  mode?: AccordionMode
  summary?: React.ReactNode
  children: React.ReactNode
  onOpen?: () => void
  e2eTarget?: string
  e2eTargetName?: string
}

const Accordion = (props: AccordionProps) => {
  const {
    isChecked = false,
    open,
    header,
    children,
    summary,
    mode = AccordionMode.default,
    onOpen,
    e2eTarget = 'accordion',
    e2eTargetName
  } = props
  const [isOpen, setIsOpen] = useState(open)
  const [isCheck, setIsCheck] = useState(isChecked)

  const handleOpenClick = useCallback(() => {
    setIsOpen(!isOpen)
    onOpen && onOpen()
  }, [isOpen, onOpen])

  return (
    <div
      className={cx('accordion-wrapper', {
        'accordion-wrapper__selected': isCheck
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div
        className={cx('accordion-wrapper__header', {
          'accordion-wrapper__header__selected': isCheck
        })}
      >
        {mode === 'select' && (
          <div className={cx('accordion-wrapper__action-element')}>
            <Checkbox
              size={CheckboxSize.large}
              checked={isCheck}
              highlight={true}
              onChange={(e) => {
                e.stopPropagation()
                setIsCheck(!isCheck)
              }}
            />
          </div>
        )}
        <div
          className={cx('accordion-wrapper__header-content')}
          onClick={() => handleOpenClick()}
        >
          {header}
          <div
            className={cx('accordion-wrapper__icon-element', {
              open: isOpen
            })}
          >
            <Icon icon={TIcon.ArrowsType1Down} />
          </div>
        </div>
      </div>
      {isOpen ? (
        <div
          className={cx('accordion-wrapper__content', {
            open: isOpen
          })}
        >
          <div className={cx('accordion-wrapper__content-inner')}>
            {children}
          </div>
        </div>
      ) : (
        <div
          className={cx('accordion-wrapper__content-inner', {
            open: summary
          })}
        >
          {summary}
        </div>
      )}
    </div>
  )
}

export { Accordion, Accordion as default }
